import { set } from 'lodash'

// Auto store data from ApiData to instantly return documents while updating via API in the background
const initialState = () => {
  return {
    // Cache keys are the URL + query params
    cache: {}
  }
}

export const state = () => initialState()

export const getters = {
  // Get cached data by key
  getCachedData: state => urlKey => {
    return state.cache[urlKey] || []
  }
}

export const mutations = {
  addToCache(state, { urlKey, data }) {
    set(state.cache, urlKey, data)
  },

  reset(state) {
    this.$log.debug('Resetting apiDataCache module')

    Object.assign(state, initialState())
  }
}
