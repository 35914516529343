import { buildSaveManifest } from 'exchange-common/journal/manifestBuilder'
import { set } from 'vue'

import { uploadFiles } from '~/lib/entities/journal/fileUploadManager'
import { JournalStoreManagement } from '~/lib/entities/journal/journalStoreManagement'
import { ApiModel } from '~/plugins/api/model'

// journalRepository shape:
//
// journalRepository: {
//       journalPairs: [
//         {
//           id: 'id1',
//           initialJournal: {
//             journal: { id: 'journalId1' },
//             journalEntries: []
//           },
//           currentJournal: {
//             journal: { id: 'journalId1' },
//             journalEntries: []
//           }
//         },
//         {
//           id: 'id2',
//           initialJournal: {
//             journal: { id: 'journalId2' },
//             journalEntries: []
//           },
//           currentJournal: {
//             journal: { id: 'journalId2' },
//             journalEntries: []
//           }
//         }
//       ]
//     }

function createNewStateForFarm() {
  return {
    journalApi: new ApiModel(),
    journalRepository: {
      hasUnsavedChanges: false,
      journalPairs: []
    }
  }
}

const initialState = () => {
  return {
    farms: {}
  }
}

export const state = () => initialState()

export const getters = {
  /**
   * Retrieves the current farm identifier.
   *
   * This getter function utilizes the global state and its respective getters
   * to access the current farm's data and retrieve its unique identifier.
   *
   * @returns {?string|number} The identification of the current farm, or undefined if it is not found.
   */
  currentFarmId: (state, getters, rootState, rootGetters) => {
    return rootGetters['farm/farm']?.id
  },

  /**
   * Retrieves the journal API for the current farm.
   */
  journalApi: (state, getters) => {
    return state.farms[getters.currentFarmId]?.journalApi
  },

  isLoading: (state, getters) => {
    return state.farms[getters.currentFarmId]?.journalApi?.isLoading
  },

  /**
   * Determines if there are unsaved changes in the current farm's journal repository.
   */
  hasUnsavedChanges: (state, getters) => {
    if (!state.farms[getters.currentFarmId] || state.farms[getters.currentFarmId].journalApi?.isLoading) {
      return false
    }

    return state.farms[getters.currentFarmId]?.journalRepository.hasUnsavedChanges
  },

  /**
   * Retrieves journal IDs associated with the current farm
   *
   * @returns {Array} An array of journal IDs.
   */
  getJournalIds: (state, getters) => {
    return new JournalStoreManagement(state.farms[getters.currentFarmId]).getJournalIds()
  },

  /**
   * Retrieves a pair related to a journal ID using the current farm context.
   * NOTE: This getter returns a function that takes a journalId as a parameter
   *
   * @returns {Object} The journal container pair corresponding to the provided journal ID within the current farm context.
   */
  getJournalContainerPairFromId: (state, getters) => journalId => {
    return new JournalStoreManagement(state.farms[getters.currentFarmId]).getJournalContainerPairFromId(journalId)
  },

  /**
   * Retrieves journal entries for a specified journal ID within the current farm context.
   * NOTE: This getter returns a function that takes a journalId as a parameter
   *
   * @returns {Array} An array of journal entries associated with the specified journal ID.
   */
  getJournalEntriesForJournalId: (state, getters) => journalId => {
    return new JournalStoreManagement(state.farms[getters.currentFarmId]).getJournalEntries(journalId)
  }
}

export const actions = {
  /**
   * Loads the journals for a specific farm by interacting with the API and commits the data to the store.
   * This operation only proceeds if there are no unsaved changes present.
   *
   * @return {Promise<void>} A promise that resolves when the operation is complete.
   */
  async loadJournalsForFarm({ commit, getters, rootGetters, state }, farmId) {
    const isOnline = rootGetters['device/isOnline']

    commit('insertFarmIfRequired', getters.currentFarmId)

    // If not online, then don't try to load anything into the store
    if (!isOnline) {
      return
    }

    // Can only get farm journals if we have no unsaved changes
    // TODO: Create a merge and conflict resolution function that can load from the api and 'merge' our unsaved changes into the currentJournal
    if (!getters.hasUnsavedChanges) {
      const { response } = await this.$api
        .journal(state.farms[getters.currentFarmId]?.journalApi)
        .useStorePath(`journal.farms.${farmId}.journalApi`)
        .loadJournalsForFarm(farmId)

      const farmJournalData = response.data

      commit('setJournalsForFarm', { journalDataList: farmJournalData, farmId })
    }
  },

  /**
   * Synchronizes local journal data to the server for the specified journal IDs.
   * Attempts to upload any pending file attachments associated with the journals,
   * and if successful, updates the local store with references to the remote file URLs.
   * A save manifest is constructed and sent to the server to ensure local and remote
   * journal states are aligned.
   *
   * @param {Array} journalIds - List of journal IDs to synchronize - if null, then check all journals in the store
   * @param {Object} fileController - File controller used to manage file upload operations.
   *
   * @return {Promise<void>} Returns a promise that resolves when the synchronization
   * process is completed, indicating that local data and server data are synchronized.
   */
  async syncLocalToServer({ commit, getters, rootGetters, state }, { journalIds, fileController }) {
    const isOnline = rootGetters['device/isOnline']

    // If not online, then don't try to sync
    // Changes will not be synced to the api, and the store will remain unchanged
    if (!isOnline) {
      return
    }

    // If journalIds is null then check all the journals in the store
    if (journalIds === null) {
      journalIds = getters.getJournalIds
    }

    for (const journalId of journalIds) {
      console.log('***Journal Sync*** JournalDataProvider.buildSaveManifest')
      const journalContainerPair = getters.getJournalContainerPairFromId(journalId)

      console.log('***Journal Sync*** journalContainerPair', journalContainerPair)
      const pendingFileUploads = journalContainerPair.currentJournalContainer.getPendingFileUploads()

      console.log('***Journal Sync*** pendingFileUploads', pendingFileUploads)

      const { uploadLog, didGoOffline } = await uploadFiles(pendingFileUploads, fileController, {
        maxDimension: 4000
      })

      console.log('uploadLog', uploadLog)
      // Tidy up the store and reference files that have been uploaded
      for (const entry of uploadLog) {
        if (entry.isSuccessful) {
          commit('attachFileReferenceToJournalEntry', {
            journalId: entry.journalId,
            journalEntryId: entry.journalEntryId,
            publicUrl: entry.publicUrl,
            fileId: entry.fileId,
            farmId: getters.currentFarmId
          })
        }
      }

      // We should probably check if there are any pendingFiles left in the list, but are not because we are unsure if this.pendingFiles
      // is updated as we update the store. Probably would be useful to add a formal test for this once we have test framework and vue 3
      if (!didGoOffline) {
        console.log('***Journal Sync*** Now generate the save manifest')
        // Now generate the update manifest
        // Build a new journalContainer pair since it is a copy of the state
        const saveManifestJournalContainerPair = getters.getJournalContainerPairFromId(journalId)
        const saveManifest = buildSaveManifest([saveManifestJournalContainerPair])

        console.dir(saveManifest, { depth: null })

        if (saveManifest.journalIdsToDelete.length > 0 || saveManifest.journalsToSave.length > 0) {
          const { response } = await this.$api
            .journal(state.farms[getters.currentFarmId]?.journalApi)
            .useStorePath(`journal.farms.${getters.currentFarmId}.journalApi`)
            .saveJournalManifest(saveManifest)

          console.log('***Journal Sync*** response', response)
          const dbJournals = response.data

          if (dbJournals && dbJournals.length > 0) {
            for (const dbJournal of dbJournals) {
              commit('refreshJournalInStore', {
                journal: dbJournal.journal,
                journalEntries: dbJournal.journalEntries,
                farmId: getters.currentFarmId
              })
            }
          }
        }
      }
    }
  },

  /**
   * Adds a journal entry to a specified journal within the current farm context.
   *
   * @param {Object} journalEntry - The journal entry to be added.
   * @param {string} journalId - The ID of the journal to which the entry will be added.
   * @return {Promise} A promise that resolves once the journal entry is successfully added.
   */
  addJournalEntryToJournal({ commit, getters }, { journalEntry, journalId }) {
    commit('insertFarmIfRequired', getters.currentFarmId)
    return new Promise(resolve => {
      commit('addJournalEntryToJournal', {
        journalData: { journalEntry, journalId, farmId: getters.currentFarmId },
        resolve
      })
    })
  },

  /**
   * Removes a specific journal entry from a journal.
   *
   * @param {string} journalEntryId - The unique identifier of the journal entry to be removed.
   * @param {string} journalId - The unique identifier of the journal from which the entry should be removed.
   * @return {void}
   */
  removeJournalEntryFromJournal({ commit, getters }, { journalEntryId, journalId }) {
    commit('insertFarmIfRequired', getters.currentFarmId)
    commit('removeJournalEntryFromJournal', { journalEntryId, journalId, farmId: getters.currentFarmId })
  },

  /**
   * Updates a journal entry within a specific journal.
   *
   * @param {Object} journalEntry - The journal entry that needs to be updated.
   * @param {string} journalId - The ID of the journal where the entry will be updated.
   *
   * @return {void}
   */
  updateJournalEntryInJournal({ commit, getters }, { journalEntry, journalId }) {
    commit('insertFarmIfRequired', getters.currentFarmId)
    commit('updateJournalEntryInJournal', { journalEntry, journalId, farmId: getters.currentFarmId })
  },

  /**
   * Attaches a file reference to a specified journal entry.

   * @param {string} journalEntryId - The unique identifier of the journal entry to which the file will be attached.
   * @param {string} journalId - The identifier of the journal containing the entry.
   * @param {string} publicUrl - The public URL of the file to be attached.
   * @param {string} fileId - The unique identifier of the file to be attached.
   * @return {void}
   */
  attachFileReferenceToJournalEntry({ commit, getters }, { journalEntryId, journalId, publicUrl, fileId }) {
    commit('insertFarmIfRequired', getters.currentFarmId)
    commit('attachFileReferenceToJournalEntry', {
      journalEntryId,
      journalId,
      publicUrl,
      fileId,
      farmId: getters.currentFarmId
    })
  }
}

export const mutations = {
  /**
   * Checks if a farm with the given `farmId` exists in the `state`.
   * If it does not exist, a new state for the farm is created and added to the `state`.
   *
   * @param {Object} state - The state object containing farms information.
   * @param {string} farmId - The unique identifier for the farm to be checked and potentially added.
   * @return {void} This method returns nothing.
   */
  insertFarmIfRequired(state, farmId) {
    if (farmId && !state.farms[farmId]) {
      set(state.farms, farmId, createNewStateForFarm())
    }
  },

  /**
   * Sets the journals for a specific farm within the store
   *
   * @param {Object} state - The current state object of the application.
   * @param {Object} payload - The payload containing the required data for setting journals.
   * @param {Array} payload.journalDataList - An array of journal data objects, each containing a journal and its associated entries.
   * @param {string|number} payload.farmId - The unique identifier for the farm for which the journals are being set.
   * @return {void} This method does not return any value.
   */
  setJournalsForFarm(state, { journalDataList, farmId }) {
    // This will set the state, but currently just console log to show we have reached this bit of code
    console.log('***Journal*** setJournalsForFarm', journalDataList)
    const newJournalPairs = []

    for (const journalData of journalDataList) {
      newJournalPairs.push({
        id: journalData.journal.id,
        initialJournal: {
          journal: { ...journalData.journal },
          journalEntries: journalData.journalEntries.map(item => {
            return { ...item }
          })
        },
        currentJournal: {
          journal: { ...journalData.journal },
          journalEntries: journalData.journalEntries.map(item => {
            return { ...item }
          })
        }
      })
    }
    set(state.farms[farmId], 'journalRepository', { journalPairs: newJournalPairs, hasUnsavedChanges: false })
  },

  /**
   * Refreshes a journal and it's entries within the store with the provided data
   *
   * @param {Object} state - The current state object containing farm and journal information.
   * @param {Object} payload - An object containing the journal data and associated entries to be updated.
   * @param {Object} payload.journal - The journal object containing updated information.
   * @param {Array} payload.journalEntries - An array of journal entry objects to be synchronized.
   * @param {string} payload.farmId - The identifier for the farm whose journal data needs to be re-synchronized.
   *
   * @return {void} This function does not return a value; it directly modifies the state.
   */
  refreshJournalInStore(state, { journal, journalEntries, farmId }) {
    const journalPair = state.farms[farmId]?.journalRepository.journalPairs.find(item => item.id === journal.id)

    if (journalPair) {
      journalPair.initialJournal.journal = { ...journal }
      journalPair.initialJournal.journalEntries = journalEntries.map(item => {
        return { ...item }
      })
      journalPair.currentJournal.journal = { ...journal }
      journalPair.currentJournal.journalEntries = journalEntries.map(item => {
        return { ...item }
      })
    }

    const hasUnsavedChanges = new JournalStoreManagement(state.farms[farmId]).doAnyJournalsHaveUnsavedChanges()

    set(state.farms[farmId].journalRepository, 'hasUnsavedChanges', hasUnsavedChanges)
  },

  addJournalEntryToJournal(state, { journalData: { journalEntry, journalId, farmId }, resolve }) {
    const journalEntryId = new JournalStoreManagement(state.farms[farmId]).addJournalEntry(journalId, journalEntry)

    const hasUnsavedChanges = new JournalStoreManagement(state.farms[farmId]).doAnyJournalsHaveUnsavedChanges()

    set(state.farms[farmId].journalRepository, 'hasUnsavedChanges', hasUnsavedChanges)

    resolve(journalEntryId)
  },

  removeJournalEntryFromJournal(state, { journalEntryId, journalId, farmId }) {
    new JournalStoreManagement(state.farms[farmId]).removeJournalById(journalId, journalEntryId)

    const hasUnsavedChanges = new JournalStoreManagement(state.farms[farmId]).doAnyJournalsHaveUnsavedChanges()

    set(state.farms[farmId].journalRepository, 'hasUnsavedChanges', hasUnsavedChanges)
  },

  updateJournalEntryInJournal(state, { journalEntry, journalId, farmId }) {
    new JournalStoreManagement(state.farms[farmId]).updateJournalEntry(journalId, journalEntry)

    const hasUnsavedChanges = new JournalStoreManagement(state.farms[farmId]).doAnyJournalsHaveUnsavedChanges()

    set(state.farms[farmId].journalRepository, 'hasUnsavedChanges', hasUnsavedChanges)
  },

  attachFileReferenceToJournalEntry(state, { journalEntryId, journalId, publicUrl, fileId, farmId }) {
    new JournalStoreManagement(state.farms[farmId]).attachFileReferenceToJournalEntry({
      journalEntryId,
      journalId,
      publicUrl,
      fileId
    })

    const hasUnsavedChanges = new JournalStoreManagement(state.farms[farmId]).doAnyJournalsHaveUnsavedChanges()

    set(state.farms[farmId].journalRepository, 'hasUnsavedChanges', hasUnsavedChanges)
  }
}
