// We store location logs in Vuex
// We then rely on the client to periodically send these off once the page has loaded, including it's own
export default function ({ store }, inject) {
  const locationProcessor = {
    queueTimer: null,

    startQueueTimer() {
      this.queueTimer = setInterval(() => {
        this.sendLogs()
      }, 12000)
    },

    async sendLogs() {
      await store.dispatch('geo/sendLocationLogs')
    }
  }

  locationProcessor.startQueueTimer()

  // When the user is closing the page, flush out our logs queue
  window.addEventListener('beforeunload', async function () {
    await locationProcessor.sendLogs()
  })

  // Inject to context as $locationProcessor
  inject('locationProcessor', locationProcessor)
}
