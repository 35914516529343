import { ExchangeApi } from '~/services/api/exchange'

export class SystemService extends ExchangeApi {
  async getStatus() {
    return await this.get(`/system/status`)
  }

  async saveLogs(logs) {
    return await this.post(`/system/logs`, logs)
  }
}
