import { ExchangeApi } from '~/services/api/exchange'

export class JournalService extends ExchangeApi {
  async loadJournalsForFarm(farmId) {
    return await this.get(`/journal/farm/${farmId}`)
  }

  async saveJournalManifest(journalManifest) {
    return await this.put(`/journal/save-manifest`, journalManifest)
  }
}
