
export default {
  name: 'BannerOffline',

  props: {
    isRounded: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isChecking: false
    }
  },

  methods: {
    async checkConnection() {
      this.isChecking = true

      await this.$network.checkConnection(true)

      this.isChecking = false
    }
  }
}
