export const hedgerowStructureOptions = [
  {
    value: 'H1',
    label: 'H1 Over-trimmed'
  },
  {
    value: 'H2',
    label: 'H2 Over-trimmed'
  },
  {
    value: 'H3',
    label: 'H3 Over-trimmed'
  },
  {
    value: 'H4a',
    label: 'H4a Rejuvenated - Recently laid'
  },
  {
    value: 'H4b',
    label: 'H4b Rejuvenated - Recently coppiced'
  },
  {
    value: 'H4a',
    label: 'H4c Rejuvenated - Recently planted'
  },
  {
    value: 'H5',
    label: 'H5 Dense and Managed'
  },
  {
    value: 'H6',
    label: 'H6 Dense and Managed'
  },
  {
    value: 'H7',
    label: 'H7 Dense and Managed'
  },
  {
    value: 'H8',
    label: 'H8 Tall or overgrown'
  },
  {
    value: 'H9',
    label: 'H9 Tall or overgrown'
  },
  {
    value: 'H10',
    label: 'H10 Tall or overgrown'
  }
]
