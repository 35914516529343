import { ExchangeApi } from '~/services/api/exchange'

export class NoteService extends ExchangeApi {
  async getAll() {
    return await this.get(`/note`)
  }

  async getAllByType(type) {
    return await this.get(`/note/type/${type}`)
  }

  async create(type) {
    return await this.post(`/note/type/${type}`)
  }

  async update(id) {
    return await this.put(`/note/${id}`)
  }

  async deleteById(id) {
    return await this.delete(`/note/${id}`)
  }
}
