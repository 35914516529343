import { LayeredNonDirectedGraph } from '../utility/abstractGraph/nonDirectedGraph.js'
import {
  geometryComparisonFunction,
  GeometryRelationshipFinder,
  sortByRelationshipWeights
} from './geometryRelationshipFinder.js'

/**
 * Represents a geographic feature with geometry, properties, and an optional identifier.
 * NOTE: Since we are using javascript we cannot import the usual geojson Feature type definition, so define our own slimmed down version here
 */
class Feature {
  type = 'Feature'

  /**
   * @type object
   */
  geometry

  /**
   * @type {number | string}
   */
  id

  /**
   * @type object
   */
  properties
}

class LayerDefinition {
  /**
   * @type string
   */
  layerName

  /**
   * @type {Feature[]}
   */
  features
}

/**
 * Calculate relationship between the test layers and the source layer
 * Note: Does not calculate relationships between test layers
 * @param {LayerDefinition} sourceLayer
 * @param {LayerDefinition[]} testLayers
 * @returns {LayeredNonDirectedGraph}
 */
export const buildParcelRelationshipGraph = (sourceLayer, testLayers) => {
  const relationshipGraph = new LayeredNonDirectedGraph()

  const geometryRelationshipFinder = new GeometryRelationshipFinder(
    sourceLayer.features.map(feature => {
      // Add each feature as a vertex in the layered non-directed graph
      const vertexData = { id: feature.id, properties: feature.properties }
      const vertex = relationshipGraph.addVertex([sourceLayer.layerName, feature.id], vertexData)

      return { geometryGeoJson: feature.geometry, userData: vertex }
    }),
    geometryComparisonFunction
  )

  for (const testLayer of testLayers) {
    for (const feature of testLayer.features) {
      // Add each feature as a vertex in the layered non-directed graph
      const vertexData = { id: feature.id, properties: feature.properties }
      const vertex = relationshipGraph.addVertex([testLayer.layerName, feature.id], vertexData)

      const matchingFeatures = geometryRelationshipFinder.findRelationships(feature.geometry)

      for (const matchingFeature of matchingFeatures) {
        relationshipGraph.addEdge(vertex, matchingFeature.sourceGeometryEntry.userData, {
          relationshipWeight: matchingFeature.relationshipWeight
        })
      }
    }
  }
  return relationshipGraph
}

export class ParcelRelationshipGraphEdgeDataEncoder {
  /**
   * Encode the edge data ready for transport
   * @param edgeData
   */
  encode(edgeData) {
    const { relationshipWeight } = edgeData
    return relationshipWeight
  }

  /**
   * Decode edge data ready to be inserted into the graph as a edge
   * @param encodedEdgeData
   * @returns Object
   */
  decode(encodedEdgeData) {
    return { relationshipWeight: encodedEdgeData }
  }
}
