import { LayeredNonDirectedGraph, NonDirectedGraph } from './nonDirectedGraph.js'

/**
 * Builds a JSON object output based on the given graph.
 *
 * @param {NonDirectedGraph|LayeredNonDirectedGraph} graph - The graph object.
 * @return {Object} The JSON object output.
 */
export const buildObjectJsonFromGraph = graph => {
  const { directed, layered } = graph.getGraphStructure()
  const outputJson = { directed, layered, format: 'object', vertices: [], edges: [] }

  // Keeping a list of vertices that have been processed allow non-directed graphs to only include an edge once in the output json
  const processedVertexIds = new Set()
  const vertices = graph.getAllVertices()
  for (const vertex of vertices) {
    const edges = vertex.getAllEdges()
    for (const edge of edges) {
      // Don't add non directed graph edges twice
      if (directed || (!directed && !processedVertexIds.has(edge.targetVertex.id))) {
        const edgeData = {
          source: vertex.id,
          target: edge.targetVertex.id,
          edgeData: edge.edgeData
        }
        outputJson.edges.push(edgeData)
      }
    }
    const vertexData = {
      id: vertex.id,
      vertexKey: vertex.getVertexKey(),
      vertexData: vertex.getVertexData()
    }
    outputJson.vertices.push(vertexData)
    processedVertexIds.add(vertex.id)
  }

  return outputJson
}

/**
 * Builds a tabular JSON output from a given graph.
 * The output includes information about the graph's vertices and edges.
 *
 * @param {NonDirectedGraph|LayeredNonDirectedGraph} graph - The input graph.
 * @param edgeEncodingClass - A class with functions to convert the edge data to/from a format suitable for transport. Typically compressing the data
 * @returns {Object} The tabular JSON output.
 */
export const buildTabularJsonFromGraph = (graph, edgeEncodingClass = undefined) => {
  const { directed, layered } = graph.getGraphStructure()
  const outputJson = {
    directed,
    layered,
    format: 'tabular',
    vertices: [],
    vertexColumnNames: ['id', 'vertexKey', 'vertexData'],
    edges: [],
    edgeColumnNames: ['source', 'target']
  }

  const edgeDataPropertyNamesForTable = graph.getEdgeDataPropertyNames()
  if (edgeEncodingClass) {
    // Encode the edge data
    outputJson.edgeColumnNames.push('encoded')
  } else {
    // Send the edge data as properties
    for (const edgeDataPropertyName of edgeDataPropertyNamesForTable) {
      outputJson.edgeColumnNames.push(edgeDataPropertyName)
    }
  }

  // Keeping a list of vertices that have been processed allow non-directed graphs to only include an edge once in the output json
  const processedVertexIds = new Set()
  const vertices = graph.getAllVertices()
  for (const vertex of vertices) {
    const edges = vertex.getAllEdges()
    for (const edge of edges) {
      // Don't add non directed graph edges twice
      if (directed || (!directed && !processedVertexIds.has(edge.targetVertex.id))) {
        const edgeDataOutput = [vertex.id, edge.targetVertex.id]
        const edgeData = edge.edgeData
        if (edgeEncodingClass) {
          // Encode the edge data
          edgeDataOutput.push(edgeEncodingClass.encode(edgeData))
        } else {
          // Send the edge data as properties
          for (const edgeDataPropertyName of edgeDataPropertyNamesForTable) {
            if (edgeData[edgeDataPropertyName] !== undefined) {
              edgeDataOutput.push(edgeData[edgeDataPropertyName])
            } else {
              edgeDataOutput.push('')
            }
          }
        }
        outputJson.edges.push(edgeDataOutput)
      }
    }
    const vertexData = [vertex.id, vertex.getVertexKey(), vertex.getVertexData()]
    outputJson.vertices.push(vertexData)
    processedVertexIds.add(vertex.id)
  }

  return outputJson
}

/**
 * Parses a JSON object representing a graph and returns a NonDirectedGraph object.
 *
 * @param {Object} jsonGraphData - The JSON object representing the graph.
 * @param edgeEncodingClass - A class with functions to convert the edge data to/from a format suitable for transport. Typically compressing the data
 * @returns {NonDirectedGraph | LayeredNonDirectedGraph } - A NonDirectedGraph object created from the JSON data.
 */
export const buildGraphFromJson = (jsonGraphData, edgeEncodingClass = undefined) => {
  let graph
  if (jsonGraphData.directed === false) {
    if (jsonGraphData.layered === false) {
      graph = new NonDirectedGraph()
    } else {
      graph = new LayeredNonDirectedGraph()
    }
  }

  if (jsonGraphData.format === 'object') {
    populateGraphFromObjectJson(graph, jsonGraphData)
  } else if (jsonGraphData.format === 'tabular') {
    populateGraphFromTabularJson(graph, jsonGraphData, edgeEncodingClass)
  }
  return graph
}

/**
 * Populates the given graph object with data from a JSON graph representation.
 *
 * @param {NonDirectedGraph|LayeredNonDirectedGraph} graph - The graph object to populate.
 * @param {Object} jsonGraphData - The JSON data representing the graph.
 */
const populateGraphFromObjectJson = (graph, jsonGraphData) => {
  const vertexIdToKeyMap = new Map()

  for (const vertexData of jsonGraphData.vertices) {
    vertexIdToKeyMap.set(vertexData.id, vertexData.vertexKey)

    graph.addVertex(vertexIdToKeyMap.get(vertexData.id), vertexData.vertexData)
  }

  for (const edgeData of jsonGraphData.edges) {
    const vertex1 = graph.getVertex(vertexIdToKeyMap.get(edgeData.source))
    const vertex2 = graph.getVertex(vertexIdToKeyMap.get(edgeData.target))
    graph.addEdge(vertex1, vertex2, edgeData.edgeData)
  }
}

/**
 * Populate the graph with data from a tabular JSON.
 *
 * @param {NonDirectedGraph|LayeredNonDirectedGraph} graph - The graph object to populate.
 * @param {Object} jsonGraphData - The JSON data containing the graph information.
 * @param edgeEncodingClass - A class with functions to convert the edge data to/from a format suitable for transport. Typically compressing the data
 */
const populateGraphFromTabularJson = (graph, jsonGraphData, edgeEncodingClass) => {
  const vertexIdToKeyMap = new Map()

  // Vertex columns
  let vertexIdColumnIndex = 0
  let vertexKeyColumnIndex = 1
  let vertexDataColumnIndex = 2
  let i = 0
  for (const vertexColumnName of jsonGraphData.vertexColumnNames) {
    switch (vertexColumnName) {
      case 'id':
        vertexIdColumnIndex = i
        break
      case 'vertexKey':
        vertexKeyColumnIndex = i
        break
      case 'vertexData':
        vertexDataColumnIndex = i
        break
    }
    i++
  }

  // Edge columns
  const edgeDataColumnNameMap = new Map()
  let sourceColumnIndex = 0,
    targetColumnIndex = 1
  i = 0
  for (const edgeColumnName of jsonGraphData.edgeColumnNames) {
    switch (edgeColumnName) {
      case 'source':
        sourceColumnIndex = i
        break
      case 'target':
        targetColumnIndex = i
        break
      default:
        edgeDataColumnNameMap.set(edgeColumnName, i)
    }
    i++
  }

  for (const vertexDataRow of jsonGraphData.vertices) {
    vertexIdToKeyMap.set(vertexDataRow[vertexIdColumnIndex], vertexDataRow[vertexKeyColumnIndex])

    const vertexKey = vertexIdToKeyMap.get(vertexDataRow[vertexIdColumnIndex])
    graph.addVertex(vertexKey, vertexDataRow[vertexDataColumnIndex])
  }

  for (const edgeDataRow of jsonGraphData.edges) {
    const sourceVertexKey = vertexIdToKeyMap.get(edgeDataRow[sourceColumnIndex])
    const targetVertexKey = vertexIdToKeyMap.get(edgeDataRow[targetColumnIndex])

    const vertex1 = graph.getVertex(sourceVertexKey)
    const vertex2 = graph.getVertex(targetVertexKey)
    let edgeData = {}
    if (edgeEncodingClass) {
      edgeData = edgeEncodingClass.decode(edgeDataRow[edgeDataColumnNameMap.get('encoded')])
    } else {
      for (const [edgeDataColumnName, columnIndex] of edgeDataColumnNameMap.entries()) {
        edgeData[edgeDataColumnName] = edgeDataRow[columnIndex]
      }
    }
    graph.addEdge(vertex1, vertex2, edgeData)
  }
}
