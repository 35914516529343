import { ExchangeApi } from '~/services/api/exchange'

export class DataEntryService extends ExchangeApi {
  async save(dimension, model) {
    return await this.post(`/data-entry/${dimension}`, model)
  }

  async fetchData(dimension, source) {
    return await this.get(`/data-entry/${dimension}`, { source })
  }
}
