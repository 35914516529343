import { cloneDeep } from 'lodash'
import { set } from 'vue'

import { ExchangeApi } from '~/services/api/exchange'

export class FarmService extends ExchangeApi {
  async getAll() {
    return await this.get(`/farm/admin`)
  }

  async getAllMap() {
    return await this.get('/farm/admin/map')
  }

  async clone() {
    return await this.post('/farm/admin/clone')
  }

  async getFieldBoundaries(boundingBox) {
    return await this.post('/farm/field-boundaries', { boundingBox })
  }

  async deleteFarm(farmId) {
    return await this.delete(`/farm/${farmId}`)
  }

  async saveHedgerowConfig(farmId) {
    return await this.put(`/farm/${farmId}/hedgerow/config`)
  }

  async saveHedgerows({ clearExistingHedgerows = false, farmId, hedgerows = [] }) {
    return await this.post(`/farm/${farmId}/hedgerow`, { clearExistingHedgerows, hedgerows })
  }

  async updateHedgerows({ farmId, hedgerows = [] }) {
    return await this.put(`/farm/${farmId}/hedgerow`, { hedgerows })
  }

  async deleteHedgerow({ farmId, hedgerowId }) {
    return await this.delete(`/farm/${farmId}/hedgerow/${hedgerowId}`)
  }

  async getHedgerows(farmId) {
    return await this.get(`/farm/${farmId}/hedgerow`)
  }

  async saveHedgerowsFromSbi({ farmId, sbiNumber }) {
    return await this.post(`/farm/${farmId}/hedgerow/sbi/${sbiNumber}`)
  }

  // Contextual data proximity graph =================================================================

  async getContextualDataProximityGraphForFarm(farmId) {
    return await this.get(`/farm/${farmId}/contextualdata`)
  }

  // Parcel Habitats =================================================================================

  async saveParcelHabitats({ farmId, habitats }) {
    return await this.post(`/farm/${farmId}/parcel/habitat`, { habitats })
  }

  async updateParcelHabitats({ farmId, habitats }) {
    return await this.put(`/farm/${farmId}/parcel/habitat`, { habitats })
  }

  async getParcelHabitats(farmId) {
    return await this.get(`/farm/${farmId}/parcel/habitat`)
  }

  async deleteParcelHabitat({ farmId, habitatId }) {
    return await this.delete(`/farm/${farmId}/parcel/habitat/${habitatId}`)
  }

  async downloadShapefile(farmId) {
    return await this.get(`/farm/${farmId}/shapefile`)
  }

  // RPA parcel graph allows us to see which rpa parcels are associated with one of our sax parcels (using the uuid)
  async loadRpaParcelGraph(farmId) {
    return await this.get(`/farm/rpa-landcover-graph/farm/${farmId}`)
  }

  // This is a really hacky quick way to get around AWS Lambda's 6MB response limit
  // We are calling the same endpoint twice, once to get the farm data with zones, and once again to get the parcels
  // The order here matters because whatever is called last is what the current "state" of the core API / apiModel is
  async getFarm(farmLastUpdatedGeometryAt, existingFarm) {
    const existingFarmData = cloneDeep(existingFarm)

    // If we have no parcels locally then we need the API to return some
    if (!existingFarmData.parcels || existingFarmData.parcels.length === 0) {
      farmLastUpdatedGeometryAt = 0
    }

    const fetchFarm = await this.get('/farm')

    const farmHasUpdatedGeomtry = fetchFarm.response.data?.farmHasUpdatedGeomtry

    if (farmHasUpdatedGeomtry) {
      const [fetchZones, fetchParcels] = await Promise.all([
        this.get('/zonation/farm/zones'),
        this.get('/zonation/farm/parcels')
        // this.get('/zonation/farm/parcelHabitats')
      ])

      const zones = cloneDeep(fetchZones.response.data || [])
      const parcels = cloneDeep(fetchParcels.response.data || [])

      set(this.state, 'response', fetchFarm.response)
      set(this.state.response.data, 'zones', zones)
      set(this.state.response.data, 'parcels', parcels)

      this.updateState()
    } else {
      // If there's no new geometry let's not make multiple farm calls and instead use the previous farm-with-zones call to populate the main API state
      set(this.state, 'response', fetchFarm.response)

      // Also since we've not received new parcels or zones from the API we need to re-add the existing ones from our local state
      set(this.state.response.data, 'zones', existingFarmData.zones)
      set(this.state.response.data, 'parcels', existingFarmData.parcels)

      this.updateState()
    }

    return cloneDeep(this.state)
  }
}
