import { set } from 'vue'

import { Article } from '~/lib/entities/content'
import { ApiModel } from '~/plugins/api/model'

export const state = () => {
  return {
    articles: [],
    fundingArticles: [],
    fetchApi: new ApiModel(),
    searchApi: new ApiModel()
  }
}

export const getters = {
  funding(state) {
    return state.fundingArticles.map(article => new Article(article))
  },

  mostRecent(state) {
    return (state.articles || []).slice(0, 3)
  },

  searchApi(state) {
    return state.searchApi
  }
}

export const actions = {
  async fetchData({ state, commit }) {
    const { response } = await this.$api
      .content(state.fetchApi)
      .useStorePath('articles.fetchApi')
      .fetchActiveArticles()

    if (response.data) {
      commit('setArticles', response.data)
    }
  },

  async fetchByTags({ state, commit }, tagsArray = []) {
    return await this.$api
      .content(state.searchApi)
      .useStorePath('articles.searchApi')
      .fetchActiveArticlesByTags(tagsArray.join(','))
  },

  async fetchFundingArticles({ dispatch, state, commit }) {
    try {
      const { response } = await dispatch('fetchByTags', ['funding-price-premiums', 'funding-natural-capital'])

      if (response.data) {
        // Response has content inside array of requested tags
        commit(
          'setFundingArticles',
          response.data.reduce((acc, tag) => {
            return acc.concat(tag.content)
          }, [])
        )
      }
    } catch (error) {
      this.$log.error('Unable to fetch funding articles', error)
    }
  }
}

export const mutations = {
  setArticles(state, data) {
    set(state, 'articles', data)
  },

  setFundingArticles(state, data) {
    set(state, 'fundingArticles', data)
  }
}
