import { ExchangeApi } from '~/services/api/exchange'

export class SfiAgreementService extends ExchangeApi {
  async loadAgreementsForFarm(farmId) {
    return await this.get(`/sfi-evidence/agreements/farm/${farmId}`)
  }

  async saveSfiAgreementManifest(sfiAgreementSaveManifest) {
    return await this.put(`/sfi-evidence/agreement/save-manifest`, sfiAgreementSaveManifest)
  }

  async exportSfiJournalEntries(agreementId, journalEntries) {
    return await this.post(`/sfi-evidence/export`, { agreementId, journalEntries })
  }
}
