import { Geolocation } from '@capacitor/geolocation'

/**
 * Gets the current geographic position of the device.
 * Uses the Geolocation API to retrieve the position with high accuracy.
 * If geolocation is not supported, it throws an error.
 * NOTE: Requires secure https connection in order for navigator.geolocation to be available
 *
 * @return {Promise<GeolocationPosition>} A promise that resolves with the position object or rejects with an error.
 */
export function getCurrentPosition() {
  if ('geolocation' in navigator) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        maximumAge: 11000,
        timeout: 10000
      })
    })
  } else {
    throw new Error('Geolocation is not supported on your device')
  }
}

/**
 * Retrieves the current geographic position of the device.
 * NOTE: Does not require a secure connection
 *
 * @function
 * @name getGeolocationCurrentPosition
 * @returns {Promise<Position>} A promise that resolves to the device's current geographic position.
 */
export const getGeolocationCurrentPosition = () => {
  return Geolocation.getCurrentPosition({ enableHighAccuracy: true })
}
