import { ExchangeApi } from '~/services/api/exchange'

export class GeoService extends ExchangeApi {
  async searchAddress(searchQuery) {
    return await this.get(`/geo/address/search/${searchQuery}`)
  }

  async getFullAddressFromId(addressId) {
    return await this.get(`/geo/address/${addressId}`)
  }

  async addLocation(location) {
    return await this.post('/geo/location', location)
  }

  async removeLocation(locationId) {
    return await this.delete(`/geo/location/${locationId}`)
  }

  async sendLocationLogs(locationLogs) {
    return await this.post('/geo/location/history', locationLogs)
  }

  async convertW3wToCoordinates(w3w) {
    return await this.get(`/geo/convert-w3w/${w3w}`)
  }

  async adminReRunNatCap() {
    return await this.post(`/geo/natcap`)
  }
}
