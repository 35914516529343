export class ContextualDataDefinitionContainer {
  /**
   * @type ContextualDataAnalysisCategory[]
   * @private
   */
  _analysisCategories

  /**
   * @type ContextualDataLayerGroupHeader[]
   * @private
   */
  _layerGroupHeaders

  constructor(analysisCategories, layerGroupHeaders) {
    this._analysisCategories = analysisCategories
    this._layerGroupHeaders = layerGroupHeaders
  }

  get analysisCategories() {
    return this._analysisCategories
  }

  get layerGroupHeaders() {
    return this._layerGroupHeaders
  }

  getAnalysisCategoryIds(limitToVisible = true) {
    const analysisCategoryIds = []
    // Don't sort the underlying array in place because it messes up vue which is observing the array
    const sortedCategories = this._analysisCategories
      .map(item => {
        return { id: item.id, visible: item.visible, sequence: item.sequence }
      })
      .sort((a, b) => a.sequence - b.sequence)
    for (const analysisCategory of sortedCategories) {
      if (limitToVisible === false || analysisCategory.visible) {
        analysisCategoryIds.push(analysisCategory.id)
      }
    }

    return analysisCategoryIds
  }

  getAnalysisCategoryName(analysisCategoryId) {
    for (const analysisCategory of this._analysisCategories) {
      if (analysisCategoryId === analysisCategory.id) {
        return analysisCategory.name
      }
    }
    return analysisCategoryId
  }

  getAnalysisCategory(analysisCategoryId) {
    for (const analysisCategory of this._analysisCategories) {
      if (analysisCategoryId === analysisCategory.id) {
        return analysisCategory
      }
    }

    return analysisCategoryId
  }

  /**
   *
   * @returns {ContextualDataLayerGroup[]}
   */
  getAnalysisGroupIds() {
    const analysisGroupIds = []
    for (const analysisCategory of this._analysisCategories) {
      for (const analysisGroupHeader of analysisCategory.visibleAnalysisGroupHeaders) {
        analysisGroupIds.push(analysisGroupHeader.id)
      }
    }
    return analysisGroupIds
  }

  /**
   *
   * @param analysisGroupHeaderId
   * @returns {{analysisGroupName:string, maxProximityDistanceMeters: number, analysisGroupLayerGroupDetailsInfo: Array<{layerGroupHeaderId:string, analysisGroupDetailInProximityReport:boolean, layerId:string, featureIdColumnName: string, featureNamePrefix:string, featureNameColumnName:string, layerName: string, hyperlinkPrefix: string, hyperlinkProperty: string, layerStyle:string }>}}
   */
  getAnalysisGroupInfo(analysisGroupHeaderId) {
    for (const analysisCategory of this._analysisCategories) {
      for (const analysisGroupHeader of analysisCategory.visibleAnalysisGroupHeaders) {
        if (analysisGroupHeader.id === analysisGroupHeaderId) {
          const analysisGroupLayerGroupDetailsInfo = []

          for (const analysisGroupDetail of analysisGroupHeader.analysisGroupDetails) {
            for (const layerGroupHeader of this._layerGroupHeaders) {
              if (layerGroupHeader.id === analysisGroupDetail.layerGroupHeaderId) {
                for (const layerGroupDetail of layerGroupHeader.layerGroupDetails) {
                  analysisGroupLayerGroupDetailsInfo.push({
                    layerGroupHeaderId: layerGroupHeader.id,
                    analysisGroupDetailInProximityReport: analysisGroupDetail.inProximityReport,
                    layerId: layerGroupDetail.layer.id,
                    layerStyle: layerGroupDetail.layer.layerStyle,
                    featureIdColumnName: layerGroupDetail.layer.source.featureIdProperty,
                    featureNameColumnName: layerGroupDetail.featureNameProperty,
                    featureNamePrefix: layerGroupDetail.featureNamePrefix,
                    hyperlinkPrefix: layerGroupDetail.hyperlinkPrefix,
                    hyperlinkProperty: layerGroupDetail.hyperlinkProperty,
                    layerName: layerGroupDetail.layer.name,
                    sourceInfoUrl: layerGroupDetail.layer.source.datasetInfoWebLink
                  })
                }
              }
            }
          }

          return {
            analysisGroupName: analysisGroupHeader.name,
            maxProximityDistanceMeters: analysisGroupHeader.maxProximityDistanceMeters,
            analysisGroupLayerGroupDetailsInfo
          }
        }
      }
    }
    return {
      analysisGroupName: analysisGroupHeaderId,
      maxProximityDistanceMeters: 0,
      analysisGroupLayerGroupDetailsInfo: []
    }
  }

  /**
   *
   * @param categoryName
   * @returns {string}
   */
  getAnalysisCategoryColor(categoryName) {
    switch (categoryName) {
      case 'Water':
        return '#1515ce'
      case 'Social':
        return '#7c5430'
      case 'Biodiversity':
      default:
        return '#436428'
    }
  }

  getAnalysisGroupHeaderIdsInAnalysisCategory(analysisCategoryId, limitToVisible = true) {
    const analysisGroupHeaderIds = []
    for (const analysisCategory of this._analysisCategories) {
      if (analysisCategoryId === analysisCategory.id) {
        // Don't sort the underlying array in place because it messes up vue which is observing the array
        const sortedAnalysisGroupHeaders = analysisCategory.visibleAnalysisGroupHeaders
          .map(item => {
            return { id: item.id, visible: item.visible, sequence: item.sequence }
          })
          .sort((a, b) => a.sequence - b.sequence)
        for (const analysisGroupHeader of sortedAnalysisGroupHeaders) {
          if (limitToVisible === false || analysisGroupHeader.visible) {
            analysisGroupHeaderIds.push(analysisGroupHeader.id)
          }
        }
      }
    }
    return analysisGroupHeaderIds
  }

  getLayersForMapbox = () => {
    const layerGroupHeaderIds = this.getVisibleLayerGroupHeaderIdsWithParameters().map(item => item.layerHeaderId)

    const layers = this.getLayersForLayerGroupHeaderIds(layerGroupHeaderIds)
    const ret = []
    for (const layer of layers) {
      ret.push({
        layerId: layer.id,
        mapboxTileServerUrl: layer.mapboxTileServerUrl,
        layerEnvelope4326: layer.layerEnvelope4326,
        layerFeatureCount: layer.layerFeatureCount,
        attributions: layer.source.attributions
      })
    }
    return ret
  }

  /**
   *
   * @param layerGroupHeaderIds
   * @returns {ContextualDataLayer[]}
   */
  getLayersForLayerGroupHeaderIds = layerGroupHeaderIds => {
    const layers = []
    for (const layerGroupHeader of this._layerGroupHeaders) {
      if (layerGroupHeaderIds.includes(layerGroupHeader.id)) {
        for (const layerGroupDetail of layerGroupHeader.layerGroupDetails) {
          layers.push(layerGroupDetail.layer)
        }
      }
    }
    return layers
  }

  getLayerGroupHeaders = layerGroupHeaderIds => {
    const layerGroupHeaders = []
    for (const layerGroupHeader of this._layerGroupHeaders) {
      if (layerGroupHeaderIds.includes(layerGroupHeader.id)) {
        layerGroupHeaders.push(layerGroupHeader)
      }
    }
    return layerGroupHeaders
  }

  /**
   * Get a particular layer definition for a named layerId
   * @param layerId
   * @returns {ContextualDataLayer|null}
   */
  getLayer = layerId => {
    const layers = []
    for (const layerGroupHeader of this._layerGroupHeaders) {
      for (const layerGroupDetail of layerGroupHeader.layerGroupDetails) {
        if (layerGroupDetail.layer.id === layerId) return layerGroupDetail.layer
      }
    }
    return null
  }

  /**
   *
   * @returns {Array<{layerHeaderId:string, maxProximityDistanceMeters: number, analysisGroupHeader:ContextualDataAnalysisGroupHeader,analysisGroupDetail:ContextualDataAnalysisGroupDetail}>}
   */
  getVisibleLayerGroupHeaderIdsWithParameters = () => {
    const layerGroupHeaderIdsWithParameters = []
    for (const analysisCategory of this._analysisCategories) {
      for (const analysisGroupHeader of analysisCategory.visibleAnalysisGroupHeaders) {
        for (const analysisGroupDetail of analysisGroupHeader.analysisGroupDetails) {
          layerGroupHeaderIdsWithParameters.push({
            analysisGroupHeader,
            analysisGroupDetail,
            layerHeaderId: analysisGroupDetail.layerGroupHeaderId,
            maxProximityDistanceMeters: analysisGroupHeader.maxProximityDistanceMeters
          })
        }
      }
    }
    return layerGroupHeaderIdsWithParameters
  }

  /**
   *
   * @returns {Array<{layerHeaderId:string, maxProximityDistanceMeters: number, analysisGroupHeader:ContextualDataAnalysisGroupHeader,analysisGroupDetail:ContextualDataAnalysisGroupDetail}>}
   */
  getAllLayerGroupHeaderIdsWithParameters = () => {
    const layerGroupHeaderIdsWithParameters = []
    for (const analysisCategory of this._analysisCategories) {
      for (const analysisGroupHeader of analysisCategory.allAnalysisGroupHeaders) {
        for (const analysisGroupDetail of analysisGroupHeader.analysisGroupDetails) {
          layerGroupHeaderIdsWithParameters.push({
            analysisGroupHeader,
            analysisGroupDetail,
            layerHeaderId: analysisGroupDetail.layerGroupHeaderId,
            maxProximityDistanceMeters: analysisGroupHeader.maxProximityDistanceMeters
          })
        }
      }
    }
    return layerGroupHeaderIdsWithParameters
  }
}
