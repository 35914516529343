import { ApiModel } from '~/plugins/api/model'
import { isNative } from '~/plugins/native/capacitor'

const initialState = () => {
  return {
    stickyList: [],
    floatingList: [],
    accountList: [],
    pushNotifications: {
      isRegistered: false,
      hasCorrectPermissions: false,
      hasDeniedPermissions: false,
      hasIgnored: false,
      isLoading: false,
      token: null,
      updateTokenApi: new ApiModel()
    },
    getNotificationsApi: new ApiModel(),
    updateNotificationApi: new ApiModel()
  }
}

export const state = () => initialState()

export const getters = {
  accountNotificationsUnread: (state, getters) => {
    return getters.accountNotificationsWithRedirectActions.filter(notification => !notification.read)
  },

  accountNotificationsUnreadCount: (state, getters) => {
    return getters.accountNotificationsUnread.length
  },

  accountNotifications: (state, getters) => {
    return getters.accountNotificationsWithRedirectActions
  },

  isPushNotificationsReady: state => {
    return state.pushNotifications.isRegistered && state.pushNotifications.hasCorrectPermissions
  },

  hasDeniedPushNotificationPermission: state => {
    return state.pushNotifications.hasDeniedPermissions
  },

  isWaitingForPushNotificationSetup: (state, getters) => {
    return (
      isNative &&
      !state.pushNotifications.hasIgnored &&
      !getters.isPushNotificationsReady &&
      !getters.hasDeniedPushNotificationPermission
    )
  },

  accountNotificationsWithRedirectActions: state => {
    // Filter only the notifications that have primary actions and redirects
    const notificationsWithPrimaryActions = state.accountList.filter(accountListNotification => {
      const primaryAction = accountListNotification.callToAction.actions.find(action => action.isPrimary)

      return primaryAction && (primaryAction.redirect?.path || primaryAction.redirect?.url)
    })

    // Filter the notification actions to only return the ones we want to display (has redirect)
    return notificationsWithPrimaryActions.map((notification, index, array) => {
      return {
        ...notification,
        callToAction: {
          ...notification.callToAction,
          actions: notification.callToAction.actions.filter(action => {
            return action.isPrimary && (action.redirect?.path || action.redirect?.url)
          })
        }
      }
    })
  }
}

export const actions = {
  add({ commit }, { title, message, type = 'success', position = 'sticky', data = null, timeoutMs = 6000 }) {
    const id = Date.now()

    const messageData = {
      title,
      message,
      type,
      id,
      data,
      timeoutMs,
      routeChangeCount: 0
    }

    const addMutation = position === 'sticky' ? 'addSticky' : 'addFloating'

    commit(addMutation, messageData)

    if (position === 'floating') {
      setTimeout(() => commit('delete', id), timeoutMs)
    }
  },

  async fetch({ state, commit }, requestModel = {}) {
    commit(
      'setGlobalState',
      {
        statePath: 'notifications.getNotificationsApi.model',
        newState: requestModel
      },
      { root: true }
    )

    const allNotifications = await new this.$Api(
      state.getNotificationsApi,
      'notifications.getNotificationsApi'
    ).get('/sys/v1/notifications')

    commit('setAccountListNotifications', allNotifications.response.data.slice(0, 10))

    if (isNative) {
      this.$nativeAppIcon.setUnreadCount(
        allNotifications.response.data.filter(notification => !notification.read).length
      )
    }

    return allNotifications.response.data.slice(0, 10)
  },

  async markAsRead({ state, commit }, requestModel = []) {
    commit(
      'setGlobalState',
      {
        statePath: 'notifications.updateNotificationApi.model',
        newState: requestModel
      },
      { root: true }
    )

    await new this.$Api(state.updateNotificationApi, 'notifications.updateNotificationApi').post(
      '/sys/v1/notifications/read',
      requestModel
    )
  },

  async sendPushNotificationToken({ state, commit, rootGetters }, token) {
    const app = rootGetters['app/getApp']

    // In some flows the PN register event is fired multiple times, only send a request if we've not saved already
    if (app.isAuth && !state.pushNotifications.token) {
      commit('setPushNotificationToken', token)

      // Send FCM token to API
      await this.$api
        .notification(state.pushNotifications.updateTokenApi)
        .useStorePath('notifications.pushNotifications.updateTokenApi')
        .savePushToken(token)
    }
  }
}

export const mutations = {
  addSticky(state, messageData) {
    state.stickyList.unshift(messageData)
  },

  addFloating(state, messageData) {
    state.floatingList.unshift(messageData)
  },

  // Delete notification by ID
  delete(state, id) {
    state.stickyList = state.stickyList.filter(notification => notification.id !== id)
    state.floatingList = state.floatingList.filter(notification => notification.id !== id)
  },

  setPushNotificationRegistration(state, isRegistered) {
    state.pushNotifications.isRegistered = isRegistered
  },

  setPushNotificationPermission(state, isPermissionGranted) {
    state.pushNotifications.hasCorrectPermissions = isPermissionGranted
  },

  setPushNotificationDeniedPermission(state, hasDeniedPermission) {
    state.pushNotifications.hasDeniedPermissions = hasDeniedPermission
  },

  setPushNotificationToken(state, token) {
    state.pushNotifications.token = token
  },

  setPushNotificationIsLoading(state, isLoading) {
    state.pushNotifications.isLoading = isLoading
  },

  setAccountListNotifications(state, notifications) {
    state.accountList = notifications
  },

  // Every time the route changes let's work out if we need to remove old sticky messages
  routeChange(state) {
    state.stickyList = state.stickyList.map(n => {
      n.routeChangeCount++
      return n
    })

    state.stickyList = state.stickyList.filter(n => {
      return n.routeChangeCount < 2
    })
  }
}
