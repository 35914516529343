
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'Notification',
  props: {
    type: {
      type: String,
      default: 'information',
      validator: prop => ['information', 'warning', 'error', 'success'].includes(prop)
    },

    isIconVisible: {
      type: Boolean,
      default: true
    },

    hasBorder: {
      type: Boolean,
      default: true
    },

    isDismissible: {
      type: Boolean,
      default: false
    },

    // Used for analytics when dismissing a message.
    // If isDismissible is true then you must provide a name
    name: {
      type: String,
      default: null
    },

    isRounded: {
      type: Boolean,
      default: true
    },

    icon: {
      type: Object,
      default: null
    },

    smallText: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      faTimes,
      hasBeenDismissed: false
    }
  },

  computed: {
    compIsErrorWithDissmisble() {
      return this.isDismissible && !this.name
    },

    compIcon() {
      if (this.icon) {
        return this.icon
      }

      let icon = faInfoCircle

      switch (this.type) {
        case 'warning':
          icon = faExclamationTriangle
          break

        case 'error':
          icon = faTimesCircle
          break

        case 'success':
          icon = faCheckCircle
          break
      }

      return icon
    }
  },

  created() {
    if (this.compIsErrorWithDissmisble) {
      console.error('You must provide a name to enable isDismissible')
    }
  },

  methods: {
    dismiss() {
      if (this.compIsErrorWithDissmisble) {
        return false
      }

      this.$analytics.addEvent('Dismissed notification', this.name)

      this.hasBeenDismissed = true
      this.$emit('dismiss', this.name)
    }
  }
}
