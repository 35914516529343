import { posthog } from 'posthog-js'
import * as Vue from 'vue'

export default ({ app, $log, route, store }, inject) => {
  const analytics = {
    /**
        By default we send all analytics events to $log.debug to save repetitiveness
      */
    addEvent(eventName, eventData = {}, sendToSlack = false, sendToLogger = true) {
      if (process.env.APP_ANALYTICS_ENABLED === 'true') {
        app = store.getters['app/getApp']

        posthog.capture(eventName, { eventData, full_path: route.fullPath, ...this.userProperties })
      }

      if (sendToLogger) {
        $log.debug(eventName, eventData)
      }
    },

    setUserId(userId) {
      if (process.env.APP_ANALYTICS_ENABLED === 'false' || !userId) {
        return false
      }

      posthog.identify(userId)
    },

    // User properties translate to GA4 Custom dimensions https://analytics.google.com/analytics/web/#/p266649095/customdefinitions/hub?params=_u..nav%3Ddefault%26_u..pageSize%3D25
    setUserProperty(property, value) {
      if (process.env.APP_ANALYTICS_ENABLED === 'false') {
        return false
      }

      posthog.people.set({ [property]: value })
    },

    reset() {
      if (process.env.APP_ANALYTICS_ENABLED === 'false') {
        return false
      }

      posthog.reset()
    }
  }

  if (process.env.APP_ANALYTICS_ENABLED === 'true') {
    posthog.init(process.env.POSTHOG_ID, {
      capture_pageview: false,
      enable_recording_console_log: true,
      disable_flags: true,
      advanced_disable_feature_flags_on_first_load: true,
      api_host: 'https://app.posthog.com',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true
        }
      }
    })

    // Every time the route changes (fired on initialization too)
    app.router.afterEach((to, from) => {
      Vue.nextTick(() => {
        posthog.capture('$pageview', {
          $current_url: to.fullPath
        })
      })
    })
  }

  // Inject to context as $analytics
  inject('analytics', analytics)
}
