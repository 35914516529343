import { nanoid } from 'nanoid'

//import { JournalEntryType, JournalType } from '#services/journal/types.js'

// Define a few types that allow types with unknown properties to be supplied
// type JournalMixed = JournalType & { [key: string]: any }
// type JournalEntryMixed = JournalEntryType & { [key: string]: any }
//
// type JournalInput = {
//   journal: JournalMixed
//   journalEntries: JournalEntryMixed[]
// }

export class JournalContainer {
  /**
   * This must be public so that vuex can detect changes, a getter is not sufficient
   * @type JournalType
   */
  journal

  /**
   * This must be public so that vuex can detect changes, a getter is not sufficient
   * @type {JournalEntryType[]}
   */
  journalEntries

  /**
   * @type {Set<string>}
   */
  #newJournalEntryIdSet

  /**
   * Creates a new instance of the Journal.
   *
   * @param {JournalInput} [journalInput] - Optional input to initialize the journal.
   * @return {Journal} The newly created journal instance.
   */
  constructor(journalInput) {
    if (!journalInput) {
      this.journal = { id: null }
      this.journalEntries = []
    } else {
      this.journal = {
        id: journalInput.journal.id
      }
      const { journalEntries = [] } = journalInput
      this.journalEntries = journalEntries
    }
    this.#newJournalEntryIdSet = new Set()
  }

  /**
   * Retrieves the current journal object.
   *
   * @return {JournalType} The journal object containing entries and related meta-data.
   */
  getJournal() {
    return { ...this.journal }
  }

  /**
   * Retrieves an array of journal entries.
   *
   * @return {JournalEntryType[]} An array of journal entries, where each entry is a copy of the original.
   */
  getJournalEntries() {
    return [
      ...this.journalEntries.map(item => {
        return { ...item }
      })
    ]
  }

  getPendingFileUploads() {
    const pendingFileUploads = []

    for (const journalEntry of this.journalEntries) {
      if (journalEntry._pendingFileUpload) {
        pendingFileUploads.push({
          ...journalEntry._pendingFileUpload,
          journalId: this.journal.id,
          journalEntryId: journalEntry.id
        })
      }
    }
    return pendingFileUploads
  }

  /**
   * Adds a new journal entry to the list of journal entries.
   * Generates a unique ID for the new entry and returns it.
   *
   * @param {JournalEntryType} journalEntry - The journal entry object to be added.
   * @return {string} - The unique ID of the new journal entry.
   */
  addJournalEntry(journalEntry) {
    const newId = nanoid()
    this.#newJournalEntryIdSet.add(newId)
    const newJournalEntry = { ...journalEntry, id: newId }
    this.journalEntries.push(newJournalEntry)
    return newId
  }

  /**
   * Removes a journal entry from the list based on the provided journal entry ID.
   *
   * @param {string} journalEntryToRemoveId - The ID of the journal entry to be removed.
   * @return {void}
   */
  removeJournalById(journalEntryToRemoveId) {
    for (let i = this.journalEntries.length - 1; i >= 0; i--) {
      if (this.journalEntries[i].id === journalEntryToRemoveId) {
        this.journalEntries.splice(i, 1)
      }
    }
  }

  /**
   * Updates an existing journal entry in the journalEntries list.
   *
   * @param {JournalEntryType} journalEntry - The journal entry to be updated, identified by its id.
   * @return {void} This method does not return a value.
   */
  updateJournalEntry(journalEntry) {
    const journalEntryId = journalEntry.id
    for (let i = 0; i < this.journalEntries.length; i++) {
      if (this.journalEntries[i].id === journalEntryId) {
        this.journalEntries[i] = journalEntry
        return
      }
    }
  }
}
