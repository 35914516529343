import { ExchangeApi } from '~/services/api/exchange'

export class SurveyService extends ExchangeApi {
  async fetchQuestions(config) {
    return await this.get(`/survey/questions`, config)
  }

  async saveAnswers(dimension) {
    return await this.post(`/survey/${dimension}`)
  }

  async fetchAnswers(surveyId, dimension) {
    return await this.get(`/survey/${surveyId}/${dimension}`)
  }

  async fetchProgress() {
    return await this.get('/survey/progress')
  }
}
