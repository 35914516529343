import { ExchangeApi } from '~/services/api/exchange'

export class SoilCoverService extends ExchangeApi {
  async createSoilCoverForFarm(farmId) {
    await this.post(`/soil-cover/farm/${farmId}`)
  }

  async fetchSoilCoverForFarm(farmId) {
    return await this.get(`/soil-cover/farm/${farmId}`)
  }
}
