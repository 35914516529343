export const farmTypes = {
  CATTLE_DAIRY: 'Dairy',
  CATTLE_BEEF: 'Beef Cattle',
  SHEEP: 'Sheep',
  CHICKEN_LAYING: 'Laying Hens',
  CHICKEN_POULTRY: 'Chicken / Table Poultry',
  PIGS: 'Pigs',
  ARABLE: 'Arable crops (inc potatoes)',
  VEGETABLES: 'Vegetables'
}

export const animalFarmTypes = [
  farmTypes.CATTLE_DAIRY,
  farmTypes.CATTLE_BEEF,
  farmTypes.SHEEP,
  farmTypes.CHICKEN_LAYING,
  farmTypes.CHICKEN_POULTRY,
  farmTypes.PIGS
]

// To get a list of custom field IDs call: GET https://api.clickup.com/api/v2/list/901200929030/field
export const enterpriseClickupIdMapping = {
  [farmTypes.CATTLE_DAIRY]: '1cf62a6d-0206-4cc2-9db0-457232e87dd9',
  [farmTypes.CATTLE_BEEF]: '57439d0f-4d6d-4ba9-81ff-2386eff5ccf0',
  [farmTypes.SHEEP]: '55581f7e-ad4d-4c82-82c2-b922284e3d29',
  [farmTypes.CHICKEN_LAYING]: '05e81495-137e-42e3-9e84-6add6809d7f3',
  [farmTypes.CHICKEN_POULTRY]: '67646eac-3fbc-4171-a99f-2944d2448139',
  [farmTypes.PIGS]: '5c7ecd23-0e51-4e37-9218-521df6d7af6c',
  [farmTypes.ARABLE]: '452b9f00-0f83-4e9d-9f17-7bb077eba5fb',
  [farmTypes.VEGETABLES]: 'f99f5db5-5012-40a3-84b8-a9a123aee225'
}
