import { captureConsoleIntegration } from '@sentry/vue'
import * as Sentry from '@sentry/vue'
import { posthog } from 'posthog-js'
import Vue from 'vue'

export default ({ app, $log, $appVersion }, inject) => {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    enabled: process.env.APP_ERROR_REPORTING_ENABLED === 'true',
    environment: process.env.APP_ENVIRONMENT,
    release: $appVersion,
    trackComponents: true,
    ignoreErrors: [
      'Not Authorized',
      'Unauthorised',
      'Non-Error promise rejection captured',
      'Request aborted',
      'Data entry not found',
      'Network Error',
      'Failed to initialize WebGL',
      'Bad HTTP status: 0',
      'Fetch is aborted',
      'Failed to fetch feature flags from PostHog',
      // Random plugins/extensions
      'top.GLOBALS',
      'Unimplemented type: 3',
      'Item with given key does not exist',
      'Redirected when going from "/" to "/platform" via a navigation guard',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    integrations: [
      captureConsoleIntegration({
        levels: ['error']
      }),
      posthog.sentryIntegration({
        organisation: process.env.SENTRY_ORG_NAME,
        projectId: process.env.SENTRY_PROJECT_ID
      })
    ]
  })

  // Inject to context as $sentry
  inject('sentry', Sentry)
}
