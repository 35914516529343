import { fileTypes } from 'exchange-common/files'

import { OfflineError } from '~/lib/files/fileController'
import { base64ToBlob, shrinkImageBlob } from '~/lib/files/fileSize'

/**
 * Uploads a list of files, scaling them down if necessary and saving them to a remote server via the provided fileController.
 *
 * @param {Array} pendingFiles - The list of files to be uploaded. Each file should have properties such as fileUrl, base64, fileName, locationAndHeading, mimeType, id, journalId, and journalEntryId.
 * @param {Object} fileController - The controller responsible for handling the file upload operations.
 * @param {Object} options - Configuration options for the upload process.
 * @param {number} [options.maxDimension=2000] - The maximum dimension (height or width) to which the image should be resized before uploading.
 *
 * @return {Promise<Object>} - An object containing the upload log and offline status. The upload log contains details for each attempted file upload, and the offline status indicates if the operation encountered an offline error.
 */
export async function uploadFiles(pendingFiles, fileController, { maxDimension = 2000 }) {
  const uploadLog = []
  let didGoOffline = false

  let fileSizeBytes = 0
  let fileBase64
  let rawFile

  const startTime = new Date()
  const elapsedTime = () => {
    return `${new Date() - startTime} ms`
  }

  for (const file of pendingFiles) {
    console.log('uploadFiles:', file)

    if (file.fileUrl) {
      console.log('uploadFiles: saving native file:')
      console.log('uploadFiles: file.fileUrl:', file.fileUrl)

      try {
        fileBase64 = undefined

        // Fetch the file from the file system and shrink to a maximum size before uploading to s3
        const response = await fetch(file.fileUrl)

        console.log('uploadFiles: fetch response time', elapsedTime())
        rawFile = await response.blob()

        // rawFile = originalBlob
        // if (fileTypes.image.includes(file.mimeType)) {
        //   rawFile = await shrinkImageBlob(originalBlob, maxDimension)
        //   console.log('uploadFiles: after shrinking image time', elapsedTime())
        // } else {
        //   rawFile = originalBlob
        // }

        fileSizeBytes = rawFile.size
      } catch (e) {
        console.error('Unable to read image', e)
      }
    } else if (file.fileBase64) {
      fileBase64 = undefined
      console.log('saving base64 file')
      // Convert the base64 image data to a blob and then shrink to a maximum size before uploading to s3
      const originalBlob = base64ToBlob(file.fileBase64)

      if (fileTypes.image.includes(file.mimeType)) {
        rawFile = await shrinkImageBlob(originalBlob, maxDimension)
      } else {
        rawFile = originalBlob
      }

      fileSizeBytes = rawFile.size
    }

    console.log('file.locationAndHeading', { ...file.locationAndHeading })
    const fileData = {
      name: file.fileName,
      locationAndHeading: { ...file.locationAndHeading },
      mimeType: file.mimeType,
      sizeBytes: fileSizeBytes,
      base64: fileBase64,
      rawFile
    }

    const log = {
      pendingFileId: file.id,
      isSuccessful: undefined,
      publicUrl: undefined,
      fileId: undefined,
      journalId: file.journalId,
      journalEntryId: file.journalEntryId
    }

    try {
      console.log(`uploadFiles: about to use fileController. fileSizeBytes: ${fileSizeBytes}`)
      const result = await fileController.upload(fileData)

      console.log('uploadFiles: after upload:', elapsedTime())

      log.isSuccessful = true
      log.publicUrl = result.publicUrl
      log.fileId = result.fileId
    } catch (error) {
      log.isSuccessful = false

      if (error instanceof OfflineError) {
        didGoOffline = true
        // We don't want to push to the uploadLog if we were offline
        // When offline, it was neither successful nor unsuccessful
        break
      }
    }

    uploadLog.push(log)
  }

  return { uploadLog, didGoOffline }
}

//
// if (error instanceof OfflineError) {
//   console.log('detected offline error')
// } else {
//   console.log('not an offline error')
// }

// TODO: errors - online or otherwise - let caller know - and complete

// TODO: update entries to have the public url
