import { JournalContainer } from 'exchange-common/journal/journalContainer'
import { buildSaveManifest } from 'exchange-common/journal/manifestBuilder'
import { nanoid } from 'nanoid'

export class JournalStoreManagement {
  /**
   * @type {{journalRepository:{journalPairs:Array<{id:string, initialJournal:{journal, journalEntries}, currentJournal:{journal, journalEntries}>}}}
   */
  state

  constructor(state) {
    this.state = state
  }

  getJournalIds() {
    return this.state.journalRepository.journalPairs.map(item => item.id)
  }

  getJournalPairFromId(journalId) {
    return this.state.journalRepository.journalPairs.find(item => item.id === journalId)
  }

  getJournalContainerPairFromId(journalId) {
    const journalPair = this.getJournalPairFromId(journalId)

    return {
      initialJournalContainer: new JournalContainer(journalPair.initialJournal),
      currentJournalContainer: new JournalContainer(journalPair.currentJournal)
    }
  }

  getJournalEntries(journalId) {
    const journalPair = this.getJournalPairFromId(journalId)

    if (!journalPair) {
      return []
    }

    return journalPair.currentJournal.journalEntries
  }

  addJournalEntry(journalId, journalEntry) {
    const journalPair = this.getJournalPairFromId(journalId)

    if (!journalPair) {
      return undefined
    }

    const newId = nanoid()

    journalPair.currentJournal.journalEntries = [
      ...journalPair.currentJournal.journalEntries,
      { ...journalEntry, id: newId }
    ]

    return newId
  }

  removeJournalById(journalId, journalEntryId) {
    const journalPair = this.getJournalPairFromId(journalId)

    if (journalPair && journalPair.currentJournal) {
      journalPair.currentJournal.journalEntries = journalPair.currentJournal.journalEntries.filter(
        item => item.id !== journalEntryId
      )
    }
  }

  updateJournalEntry(journalId, journalEntry) {
    const journalPair = this.getJournalPairFromId(journalId)

    if (journalPair && journalPair.currentJournal) {
      journalPair.currentJournal.journalEntries = journalPair.currentJournal.journalEntries.map(item =>
        item.id === journalEntry.id ? journalEntry : item
      )
    }
  }

  attachFileReferenceToJournalEntry({ journalEntryId, journalId, publicUrl, fileId }) {
    const journalPair = this.getJournalPairFromId(journalId)

    if (journalPair && journalPair.currentJournal) {
      journalPair.currentJournal.journalEntries = journalPair.currentJournal.journalEntries.map(item => {
        if (item.id === journalEntryId) {
          return { ...item, _pendingFileUpload: undefined, fileData: { fileId, publicUrl } }
        } else {
          return item
        }
      })
    }
  }

  /**
   * Checks whether any journals have unsaved changes. It iterates over all journal IDs and builds a save manifest for each journal.
   * If there are any journals that need to be saved or any journal IDs that need to be deleted, it indicates there are unsaved changes.
   *
   * @return {boolean} Returns true if there are any journals with unsaved changes, otherwise false.
   */
  doAnyJournalsHaveUnsavedChanges() {
    const journalIds = this.getJournalIds()

    for (const journalId of journalIds) {
      const journalContainerPair = this.getJournalContainerPairFromId(journalId)
      const saveManifest = buildSaveManifest([journalContainerPair])

      if (saveManifest.journalsToSave.length > 0 || saveManifest.journalIdsToDelete.length > 0) {
        return true
      }
    }
    return false
  }
}
