import { GraphEdge } from './graphEdge.js'
import { EdgeStore, LayeredEdgeStore } from './edgeStore.js'

/**
 * Represents a vertex in a graph
 */
export class GraphVertexAbstract {
  /**
   * Unique vertex id within a graph
   * Typically used when serialising/de-serialising
   * @type number
   * @private
   */
  _id

  /**
   * @type {string | number | Array<string | number>}
   * @private
   */
  _vertexKey

  /**
   * @type {any}
   * @private
   */
  _vertexData

  /**
   * @type {EdgeStore | LayeredEdgeStore}
   * @private
   */
  _edgeStore

  constructor(edgeStore, id, vertexKey, vertexData) {
    this._edgeStore = edgeStore
    this._id = id
    this._vertexKey = vertexKey
    this._vertexData = vertexData
  }

  get id() {
    return this._id
  }

  /**
   * Retrieves the ID of the vertex.
   *
   * @returns {string|number} The ID of the vertex.
   */
  getVertexKey() {
    return this._vertexKey
  }

  /**
   * Retrieves the vertex data of an object.
   *
   * @returns {any} The vertex data.
   */
  getVertexData() {
    return this._vertexData
  }

  /**
   * Sets the vertex data for the object.
   *
   * @param {any} value - The new value for the vertex data.
   * @return {void} - This method does not return a value.
   */
  setVertexData(value) {
    this._vertexData = value
  }

  /**
   * Returns a list of all the edges from the vertex.
   *
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {GraphEdge[]} An iterator over all the edges from the vertex
   */
  getAllEdges(edgeDataFilterFn = undefined) {
    return this._edgeStore.getAllEdges(edgeDataFilterFn)
  }

  /**
   * Returns the edge between the current vertex and the specified end vertex.
   *
   * @param {GraphVertex | LayeredGraphVertex} targetVertex - The end vertex of the edge.
   * @returns {GraphEdge} - The graph edge between the current vertex and the end vertex.
   */
  getEdge(targetVertex) {
    return this._edgeStore.getEdge(targetVertex)
  }

  /**
   * Adds an edge from this vertex to the end vertex
   *
   * @param {GraphVertex | LayeredGraphVertex} targetVertex - The target vertex the edge will connect to.
   * @param {any} edgeData - The data associated with the edge.
   * @return {void}
   */
  addEdge(targetVertex, edgeData) {
    this._edgeStore.addEdge(this, targetVertex, edgeData)
  }

  /**
   * Removes an edge from this vertex that ends at the specified vertex.
   *
   * @param {GraphVertex | LayeredGraphVertex} targetVertex - The vertex at which the edge ends.
   *
   * @return {undefined}
   */
  removeEdge(targetVertex) {
    this._edgeStore.removeEdge(targetVertex)
  }
}

/**
 * A vertex in a graph
 */
export class GraphVertex extends GraphVertexAbstract {
  constructor(id, vertexKey, vertexData) {
    super(new EdgeStore(), id, vertexKey, vertexData)
  }
}

/**
 * A vertex in a layered graph
 */
export class LayeredGraphVertex extends GraphVertexAbstract {
  constructor(id, layerId, featureId, vertexData) {
    super(new LayeredEdgeStore(), id, [layerId, featureId], vertexData)
  }

  /**
   * Returns a list of all the edges from the vertex.
   * Limits to edges from this vertex to target vertices in given list of layerId's
   * Optionally filters with edgeDataFilterFn
   *
   * @param targetLayerIds
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {GraphEdge[]} An iterator over all the edges from the vertex
   */
  getAllEdgesForLayers(targetLayerIds, edgeDataFilterFn) {
    return this._edgeStore.getAllEdgesForLayers(targetLayerIds, edgeDataFilterFn)
  }

  /**
   * Returns the layer id for this vertex
   * @returns {number|string}
   */
  getLayerId() {
    return this._vertexKey[0]
  }

  /**
   * Returns the feature id for this vertex
   * @returns {number|string}
   */
  getFeatureId() {
    return this._vertexKey[1]
  }
}
