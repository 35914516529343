import { sampleType } from 'exchange-common/sampling/sampleType.js'
import dayjs from 'dayjs'

function planItemHasEnoughSamples(planItemId, samples, minRequired) {
  const count = samples.reduce((acc, sample) => (sample.samplePlanId === planItemId ? acc + 1 : acc), 0)

  return count >= minRequired
}

// This is a way to determine if pH probes can be - or were - used during sampling.
// If there are no vess samples before this date then it indicates that
// sampling occurred before use of probes was introduced
export function canUsePhProbe(vessSamples = []) {
  return !vessSamples.some(sample => dayjs(sample.recordedAt).isBefore('2024-01-25'))
}

export class BasicSampleRequirements {
  constructor() {
    this.birds = 1
    this.hedgerows = 1
    this.plants = 1
    this.vess = 3
    this.earthworms = 3
    this.ph = 0
  }
}

export class AdvancedSampleRequirements extends BasicSampleRequirements {
  constructor() {
    super()
    this.ph = 3
  }
}

export function getPlanRequirements({ isStaff, isPaid, vessSamples = [] }) {
  return isStaff && isPaid && canUsePhProbe(vessSamples)
    ? new AdvancedSampleRequirements()
    : new BasicSampleRequirements()
}

export function getPlanWithCompleteness({
  flatPlan = [],
  requirements = {},
  birdSamples = [],
  hedgerowSamples = [],
  plantSamples = [],
  vessSamples = [],
  earthwormSamples = [],
  phSamples = []
}) {
  return flatPlan.map(item => {
    switch (item.sampleType) {
      case sampleType.BIRD:
        return {
          ...item,
          isComplete: planItemHasEnoughSamples(item.id, birdSamples, requirements.birds)
        }

      case sampleType.HEDGEROW:
        return {
          ...item,
          isComplete: planItemHasEnoughSamples(item.id, hedgerowSamples, requirements.hedgerows)
        }

      case sampleType.PLANT_ARABLE:
      case sampleType.PLANT_FIELD_MARGIN:
      case sampleType.PLANT_GRASSLAND:
      case sampleType.PLANT_HEDGEROW:
        return {
          ...item,
          isComplete: planItemHasEnoughSamples(item.id, plantSamples, requirements.plants)
        }

      case sampleType.SOIL:
        return {
          ...item,
          isComplete:
            planItemHasEnoughSamples(item.id, vessSamples, requirements.vess) &&
            planItemHasEnoughSamples(item.id, earthwormSamples, requirements.earthworms)
          // 21/10/2024 - Harriet asked on Slack to remove pH from completion percentage
          // planItemHasEnoughSamples(item.id, phSamples, requirements.ph)
        }
      default:
        return item
    }
  })
}

export function getCountOfCompleteItemsInCollection(collectionWithCompleteness = []) {
  return collectionWithCompleteness.reduce((acc, { isComplete }) => (isComplete ? acc + 1 : acc), 0)
}

export function collectionIsComplete(collectionWithCompleteness = []) {
  return getCountOfCompleteItemsInCollection(collectionWithCompleteness) === collectionWithCompleteness.length
}

export function getPercentageCompleteFromCollection(collectionWithCompleteness = []) {
  return Math.round(
    (getCountOfCompleteItemsInCollection(collectionWithCompleteness) / collectionWithCompleteness.length) * 100
  )
}

export function getSamplesCountForSoilPlanItem({
  soilPlanItemId,
  vessSamples = [],
  earthwormSamples = [],
  phSamples = []
}) {
  const [vessCount, earthwormsCount, phCount] = [vessSamples, earthwormSamples, phSamples].map(
    sampleSet => sampleSet.filter(({ samplePlanId }) => samplePlanId === soilPlanItemId).length
  )

  return { vessCount, earthwormsCount, phCount }
}
