import { loadAbilityFromJSON } from 'exchange-common/roles'

export default function ({ $notify, app }, inject) {
  const ability = loadAbilityFromJSON()

  const can = (...args) => {
    return ability.can(...args)
  }

  // Inject to context as $ability and $can
  inject('ability', ability)
  inject('can', can)
}
