export default function () {
  return {
    model: {
      email: ['local'].includes(process.env.APP_ENVIRONMENT) ? 'alex.crooks@soilassociation.exchange' : null,
      password: ['local'].includes(process.env.APP_ENVIRONMENT) ? 'testingthis' : null
    },
    validation: {
      email: { required: true, email: true },
      password: { required: true, min: 8 }
    }
  }
}
