
import { faCross } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'DropdownMenuList',

  props: {
    shouldShowCancel: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      required: true,
      default: () => [
        {
          label: 'Option label',
          prefixIcon: null,
          onClick: () => {},
          value: null,
          localeCode: null,
          isActive: false
        }
      ]
    },

    onClose: {
      type: Function,
      default: null
    },

    dropdownSize: {
      type: String,
      default: 'md',
      validator: prop => ['sm', 'md', 'auto'].includes(prop)
    },

    shouldOpenLeftDesktop: {
      type: Boolean,
      default: false
    },

    hasMobileMenu: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isClosing: false,
      faCross
    }
  },

  methods: {
    handleOverlayClick() {
      this.closeOverlay()
    },

    closeOverlay() {
      // Trigger animation
      this.isClosing = true

      if (this.onClose) {
        // Timeout to allow the animation to finish
        setTimeout(() => {
          this.onClose()
        }, 200)
      }
    },

    handleClick(option) {
      this.$emit('click', option)

      if (option.onClick) {
        option.onClick()
      }

      this.closeOverlay()
    }
  }
}
