import { syncStatus, syncStatusDiscrete } from '~/lib/enums/otaSyncStatus'

const initialState = () => {
  return {
    statusCode: 5,
    localVersion: {},
    error: null
  }
}

export const state = () => initialState()

export const getters = {
  getDiscreteStatus: state => syncStatusDiscrete[state.statusCode],

  isUpToDate: state => state.statusCode === syncStatus[0]
}

export const mutations = {
  setStatusCode(state, statusCode) {
    state.statusCode = statusCode
  },

  setLocalVersion(state, version) {
    state.localVersion = version
  },

  setError(state, error) {
    state.error = error
  },

  reset(state) {
    this.$log.debug('Resetting OTA module')

    Object.assign(state, initialState())
  }
}
