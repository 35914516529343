/**
 * Proximity data
 * This will be a type when we convert to typescript
 */
export class EdgeProximityData {
  /**
   * Closest proximity of the geometries
   * @type {number | null}
   */
  proximityMeters

  /**
   * true is there is an intersection:
   * A point within a geometry
   * A line or part of a line within a geometry
   * An intersection between polygons
   * @type boolean
   */
  hasIntersection

  /**
   * The area of intersection (hectares)
   * Note: Only positive for polygon overlaps
   * @type number
   */
  intersectionAreaHectares
}

export class EdgeProximityDataEncoder {
  /**
   * Encode the edge data ready for transport
   * @param {EdgeProximityData} edgeData
   */
  encode(edgeData) {
    if (!edgeData.hasIntersection) {
      return edgeData.proximityMeters
    }
    if (edgeData.intersectionAreaHectares > 0.0) {
      return -edgeData.intersectionAreaHectares
    }
    return null
  }

  /**
   * Decode edge data ready to be inserted into the graph as a edge
   * @param encodedEdgeData
   * @returns EdgeProximityData
   */
  decode(encodedEdgeData) {
    if (encodedEdgeData === null) {
      return { proximityMeters: 0, hasIntersection: true, intersectionAreaHectares: 0 }
    }
    if (encodedEdgeData >= 0) {
      return { proximityMeters: encodedEdgeData, hasIntersection: false, intersectionAreaHectares: 0 }
    }
    return { proximityMeters: 0, hasIntersection: true, intersectionAreaHectares: -encodedEdgeData }
  }
}
