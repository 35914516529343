import { render, staticRenderFns } from "./default.vue?vue&type=template&id=366953d6"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NativeStatusBar: require('/home/runner/work/exchange/exchange/packages/client/src/components/layouts/native/status-bar/NativeStatusBar.vue').default,AppLoader: require('/home/runner/work/exchange/exchange/packages/client/src/components/layouts/app-loader/AppLoader.vue').default,ModalWrapper: require('/home/runner/work/exchange/exchange/packages/client/src/components/modals/modal-wrapper/ModalWrapper.vue').default,MobileDropdownMenuOverlay: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/dropdown-menu-list/MobileDropdownMenuOverlay.vue').default,NotificationFloatingWrapper: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/notification/NotificationFloatingWrapper.vue').default,AppWrapper: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/app-wrapper/AppWrapper.vue').default})
