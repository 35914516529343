import { ExchangeApi } from '~/services/api/exchange'

export class FileService extends ExchangeApi {
  async getSignedKey(fileData) {
    const request = await this.post(`/file/signed-url`, fileData)

    return request.response.data
  }

  async deleteFile(fileId) {
    await this.delete(`/file/${fileId}`)
  }
}
