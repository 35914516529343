import { ExchangeApi } from '~/services/api/exchange'

export class UserService extends ExchangeApi {
  async getAll() {
    return await this.get(`/user`)
  }

  async getById(userId) {
    return await this.get(`/user/${userId}`)
  }

  async impersonate(userId) {
    return await this.post(`/user/${userId}/impersonate`)
  }

  async saveProfile() {
    const model = { ...this.state.model }

    if (!model.avatar || !model.avatar?.fileId) {
      delete model.avatar
    }

    return await this.put(`/user/profile`, model)
  }

  async deleteProfile() {
    return await this.delete(`/user/account`)
  }
}
