import { GraphVertex, LayeredGraphVertex } from './graphVertex.js'

/**
 * Represents an edge in a graph
 */
export class GraphEdge {
  /**
   * Source vertex
   * @type {GraphVertex | LayeredGraphVertex}
   * @readonly
   */
  sourceVertex

  /**
   * Target vertex
   * @type {GraphVertex | LayeredGraphVertex}
   * @predonly
   */
  targetVertex

  /**
   * Edge data
   * @type any
   * @readonly
   */
  edgeData

  /**
   * Creates a new edge object.
   *
   * @param {GraphVertex | LayeredGraphVertex} sourceVertex - The source vertex of the edge.
   * @param {GraphVertex | LayeredGraphVertex} targetVertex - The target vertex of the edge.
   * @param {any} edgeData - Data associated with the edge.
   *
   * @return {void}
   */
  constructor(sourceVertex, targetVertex, edgeData) {
    this.sourceVertex = sourceVertex
    this.targetVertex = targetVertex
    this.edgeData = edgeData
  }
}
