import { isNative, platform } from '~/plugins/native/capacitor'

// Handle modifications to app icon, be it changing the image or setting unread counts
export default async function ({ store, $log, app, $openWindow, $analytics, $notify }, inject) {
  const notifications = {}

  if (isNative) {
    const { PushNotifications } = await import('@capacitor/push-notifications')

    notifications.setup = async () => {
      const $app = store.getters['app/getApp']

      if ($app.deviceDetails.isVirtual) {
        $log.debug('Simulator detected, skipping PN registration')

        store.commit('notifications/setPushNotificationPermission', false)
        store.commit('notifications/setPushNotificationDeniedPermission', true)

        return false
      }

      // Have we already set up push notifications?
      if (store.state.notifications.pushNotifications.isRegistered) {
        $log.debug('Native PN: Already setup, attempting to re-register')

        // Try re-registering if the user is pressing the button again after failure
        PushNotifications.register()

        return false
      }

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      $log.debug('Native PN: Requesting permission...')

      store.commit('notifications/setPushNotificationIsLoading', true)

      const permissionResult = await PushNotifications.requestPermissions()

      store.commit('notifications/setPushNotificationIsLoading', false)

      notifications.onPermissionResult(permissionResult)
    }

    notifications.checkPermissions = async () => {
      $log.debug('Native PN: Checking permission...')

      const permissionResult = await PushNotifications.checkPermissions()

      notifications.onPermissionResult(permissionResult)
    }

    notifications.onPermissionResult = permissionResult => {
      if (permissionResult.receive === 'granted') {
        $analytics.addEvent('Native Push Notification: Granted')

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()

        store.commit('notifications/setPushNotificationPermission', true)
        store.commit('notifications/setPushNotificationDeniedPermission', false)
      } else if (permissionResult.receive === 'denied') {
        $analytics.addEvent('Native Push Notification: Denied')

        store.commit('notifications/setPushNotificationPermission', false)
        store.commit('notifications/setPushNotificationDeniedPermission', true)
      }
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', token => {
      $log.debug('Native PN: Registered', token.value)

      store.dispatch('notifications/sendPushNotificationToken', token.value)

      store.commit('notifications/setPushNotificationRegistration', true)
    })

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', error => {
      $log.error('Native PN: Error on registration', error?.message || error)

      store.commit('notifications/setPushNotificationPermission', false)
      store.commit('notifications/setPushNotificationDeniedPermission', true)
      store.commit('notifications/setPushNotificationRegistration', false)

      // $notify.error('Error registering Push Notifications, please try again')
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', notification => {
      $log.debug('Native PN: Received while app open', notification)

      // iOS will show a push notification over the app while open.
      // Android will not, so we need to show our own in-app notification
      if (platform === 'android') {
        $notify.information(notification?.data?.title, notification?.data?.body)
      }
    })

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      $log.debug('Native PN: Opened', notification)

      // Report open event to Courier
      if (notification.notification.data?.trackingUrl) {
        fetch(notification.notification.data.trackingUrl, {
          method: 'POST',
          headers: {
            'X-Courier-Client-Key': process.env.COURIER_CLIENT_KEY
          },
          body: JSON.stringify({
            event: 'OPENED'
          })
        })
      }

      const redirectLink = notification.notification.data?.link
        ? JSON.parse(notification.notification.data.link)
        : null

      if (redirectLink) {
        if (redirectLink.path) {
          app.router.push({ path: redirectLink.path }).catch(() => {})
        } else if (redirectLink.href) {
          $openWindow(redirectLink.href, true)
        }

        $analytics.addEvent('Clicked push notification', {
          platform,
          redirectPath: redirectLink.path || redirectLink.href
        })
      }
    })

    const { App } = await import('@capacitor/app')

    // Check permissions if app comes back to the foreground, i.e the user has come back from adjusting settings
    App.addListener('appStateChange', appState => {
      $log.debug('Push notification setup, app state change', appState)

      if (appState.isActive && !store.state.notifications.pushNotifications.isRegistered) {
        notifications.checkPermissions()
      }
    })
  }

  // Inject to Nuxt context as $pushNotifications
  inject('pushNotifications', notifications)
}
