const colors = require('tailwindcss/colors')

const primary = {
  50: '#e7ecf1',
  100: '#d5dbe2',
  200: '#c3cad4',
  300: '#b4b9c5',
  400: '#9197aa',
  500: '#74798f',
  DEFAULT: '#454659',
  600: '#2d3043',
  700: '#1a1f2e',
  800: '#0c1018',
  900: '#010203',
  text: '#454659'
}

const secondary = {
  100: '#E0ECF5',
  200: '#C2D9EB',
  300: '#A3C7E2',
  400: '#85B4D8',
  500: '#66a1ce',
  DEFAULT: '#66a1ce',
  600: '#408EC9',
  700: '#2C79B5',
  800: '#1F6498',
  900: '#154E7A',
  text: '#959595'
}

const highlight = '#8A4824'
const sideBarItemLinkText = '#E9CDBE'
const sideBarItemText = '#F2D8C9'
const sideBarItemHover = '#9B5630'
const sideBarBackground = '#A66541'
const uiBackground = '#FBFAF8'

const tailwindColorConfig = {
  body: {
    DEFAULT: primary.DEFAULT
  },
  highlight,
  'highlight-lighter': '#8A4824',
  sideBarBackground,
  sideBarItemLinkText,
  sideBarItemText,
  sideBarItemHover,
  uiBackground,
  error: colors.red[700],
  success: colors.green[600],
  active: highlight,
  link: '#0075B6',
  'link-hover': '#073d59',
  label: colors.gray[800],
  light: colors.gray[500],
  lighter: colors.gray[400],
  'panel-bg': colors.gray[50],
  'panel-highlight-bg': '#f2f1e5',
  'panel-border': colors.gray[200],
  overlay: 'rgba(7, 17, 81, 0.4)',
  hover: colors.gray[50],
  primary,
  'primary-hover': primary[700],
  secondary,
  'primary-brown': '#A66541',
  'tab-background': '#EFECE5',
  'table-header-background': '#F5F2EA',
  'table-column-divider': '#E4D6CF',
  'recommendation-column-divider': '#E9E9E9',
  'recommendation-bg': '#EBEDF1'
}

module.exports = {
  primary,
  highlight,
  tailwindColorConfig
}
