import { ExchangeApi } from '~/services/api/exchange'

export class SamplingService extends ExchangeApi {
  async fetchSamples(farmId, entityType) {
    return await this.get(`/sampling/farm/${farmId}/${entityType}`)
  }

  async processBatch(farmId, entityType, data) {
    return await this.put(`/sampling/farm/${farmId}/${entityType}`, data)
  }

  async identifyPlant(farmId, publicUrl) {
    return await this.post(`/sampling/farm/${farmId}/plant/identify`, { publicUrl })
  }

  async fetchPlan(farmId) {
    return await this.get(`/sampling/farm/${farmId}/plan`)
  }

  async createPlannedSample(farmId, plannedSample) {
    return await this.post(`/sampling/farm/${farmId}/plan`, plannedSample)
  }

  async deletePlannedSample(farmId, plannedSampleId) {
    return await this.delete(`/sampling/farm/${farmId}/plan/${plannedSampleId}`)
  }
}
