import { Capacitor } from '@capacitor/core'
import { Filesystem } from '@capacitor/filesystem'
import { Media } from '@capacitor-community/media'

import { platform } from '~/plugins/native/capacitor'

/**
 * A base64 encoded string representing a 1x1 pixel PNG image.
 *
 * Useful for testing writing to an album to determine if we have access to write to an album
 */
export const dummyImageBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgMBApL2xkwAAAAASUVORK5CYII='

/**
 * Tests write permissions for a specified photo album by attempting to save
 * and then delete a dummy photo file.
 *
 * @async
 * @function
 * @param {Object} options - The options for the function.
 * @param {string} [options.targetAlbumName='SAX Journal'] - The name of the album to test write permissions for.
 * @returns {Promise<boolean>} A promise that resolves when the test is complete.
 */
export const testAlbumWritePermissions = async ({ targetAlbumName = 'SAX Journal' }) => {
  let success = true

  try {
    const photoAlbumIdentifier = await getPhotoAlbumIdentifier(targetAlbumName)
    const photoFileName = getUniquePhotoFileName('journal', 'jpg')
    const albumSaveResponse = await Media.savePhoto({
      path: dummyImageBase64,
      albumIdentifier: photoAlbumIdentifier,
      fileName: photoFileName
    })
    const albumFilePath = albumSaveResponse.filePath

    await Filesystem.deleteFile({
      path: albumFilePath
    })
  } catch (error) {
    console.error('***PhotoAlbum*** Error testing album write permissions:', error)
    success = false
  }
  return success
}

/**
 * Copies an image from the specified source file path to the target album.
 * Optionally deletes the source file after copying.
 *
 * @async
 * @function
 * @param {Object} options - The options for the function.
 * @param {string} options.sourceFilePath - The file path of the source image to be copied.
 * @param {string} [options.targetAlbumName='SAX Journal'] - The name of the target album. Defaults to 'SAX Journal'.
 * @param {boolean} [options.deleteSourceFile=true] - Whether to delete the source file after copying. Defaults to true.
 * @returns {Promise<Object>} - Resolves with an object containing the album file path and album file URL.
 * @throws {Error} - Throws an error if there is an issue copying the image to the album.
 */
export const copyImageToAlbum = async ({
  sourceFilePath,
  targetAlbumName = 'SAX Journal',
  deleteSourceFile = true
}) => {
  console.log('***PhotoAlbum*** copyImageToAlbum', sourceFilePath, targetAlbumName, deleteSourceFile)
  try {
    const photoAlbumIdentifier = await getPhotoAlbumIdentifier(targetAlbumName)
    const photoFileName = getUniquePhotoFileName('journal', 'jpg')
    const albumSaveResponse = await Media.savePhoto({
      path: sourceFilePath,
      albumIdentifier: photoAlbumIdentifier,
      fileName: photoFileName
    })

    let albumFilePath

    console.log('***PhotoAlbum*** platform', platform)

    if (platform === 'ios') {
      // iOS
      // the Media.savePhoto function returns undefined on iOS
      // we need to scan the album for the file that we just copied
      // Media.getMedias is only supported on iOS
      const mediaFiles = await Media.getMedias({
        albumIdentifier: photoAlbumIdentifier,
        quantity: 1,
        sort: {
          key: 'creationDate', // Sort by creation date
          order: 'desc' // Descending order
        }
      })

      const albumPictureMedia = mediaFiles.medias[0]

      if (!albumPictureMedia) {
        throw new Error(`File "${photoFileName}" not found in album "${targetAlbumName}"`)
      }

      const albumPicturePathObject = await Media.getMediaByIdentifier({ identifier: albumPictureMedia.identifier })

      albumFilePath = albumPicturePathObject.path
    } else {
      // Android
      // the Media.savePhoto function returns the filePath on Android
      console.log('***PhotoAlbum*** albumSaveResponse', albumSaveResponse)
      albumFilePath = albumSaveResponse.filePath
    }

    if (deleteSourceFile) {
      console.log('***PhotoAlbum*** deleteSourceFile')
      // Remove the copy of the file that was saved to the cache by cameraPreview
      await Filesystem.deleteFile({
        path: sourceFilePath
      })
    }

    const albumFileUrl = Capacitor.convertFileSrc(albumFilePath)

    console.log('***PhotoAlbum*** albumFileUrl', albumFileUrl)
    console.log('***PhotoAlbum*** albumFilePath', albumFilePath)

    return { albumFilePath, albumFileUrl }
  } catch (error) {
    console.error('***PhotoAlbum*** Error copying image to album:', error)
    throw new Error(`***PhotoAlbum*** Error copying image to album: ${error}`)
  }
}

/**
 * Generates a unique file name for a photo using a specified prefix and extension.
 *
 * @param {string} [prefix='photo'] - The prefix for the file name.
 * @param {string} [extension='jpg'] - The file extension.
 * @returns {string} - A unique file name combining the prefix, a timestamp, and the extension.
 */
export const getUniquePhotoFileName = (prefix = 'photo', extension = 'jpg') => {
  const timestamp = new Date().toISOString().replace(/[-:T.Z]/g, '')

  return `${prefix}_${timestamp}.${extension}`
}

/**
 * Asynchronously retrieves the identifier of a photo album by its name.
 * If the specified album does not exist, it will be created and its identifier will be returned.
 *
 * @param {string} [albumName='SAX Journal'] - The name of the photo album to find or create.
 * @returns {Promise<string|undefined>} A promise that resolves to the identifier of the photo album,
 * or `undefined` if the album could not be found or created.
 */
export const getPhotoAlbumIdentifier = async (albumName = 'SAX Journal') => {
  let photoAlbum = await findAlbum(albumName)

  if (!photoAlbum) {
    // Album does not exist, so add and find it again
    console.log('***PhotoAlbum*** album does not exist, so add and find it again', albumName)
    await Media.createAlbum({ name: albumName })
    photoAlbum = await findAlbum(albumName)
  }

  if (!photoAlbum) {
    return undefined
  }

  return photoAlbum.identifier
}

/**
 * Asynchronously finds an album by name from the photo albums on the device.
 *
 * This function searches through the list of photo albums to find a matching album based on the provided name.
 * It handles both Android and iOS platforms with specific search conditions:
 * - On Android, it additionally checks if the album's identifier starts with the albums' path.
 * - On iOS, it simply matches the album's name.
 *
 * @param {string} albumName - The name of the album to find.
 * @returns {Promise<Object|undefined>} - A promise that resolves to the album object if found, otherwise undefined.
 */
export const findAlbum = async albumName => {
  const { albums } = await Media.getAlbums()
  let photoAlbum

  if (Capacitor.getPlatform() === 'android') {
    // Android
    const albumsPath = (await Media.getAlbumsPath()).path

    photoAlbum = albums.find(a => a.name === albumName && a.identifier.startsWith(albumsPath))
  } else {
    // iOS
    photoAlbum = albums.find(a => a.name === albumName)
  }

  return photoAlbum
}
