import { actionStatus } from 'exchange-common/status/action'

import { ApiModel } from '~/plugins/api/model'

const initialState = () => {
  return {
    fetchRecommendationsApi: new ApiModel(),
    fetchActionsApi: new ApiModel(),
    fetchQuickWinsApi: new ApiModel(),
    recommendations: [],
    actions: [],
    quickWins: []
  }
}

export const state = () => initialState()

export const getters = {
  recommendations(state) {
    return state.recommendations
  },

  actions(state) {
    return state.actions
  },

  inProgressActions(state) {
    return state.actions.filter(action => action.status === actionStatus.IN_PROGRESS)
  },

  quickWins(state) {
    return state.quickWins
  },

  isLoading(state) {
    return state.fetchRecommendationsApi.isLoading || state.fetchActionsApi.isLoading
  }
}

export const actions = {
  async getRecommendations({ state, commit }) {
    const { response } = await this.$api
      .recommendation(state.fetchRecommendationsApi)
      .useStorePath('actions.fetchRecommendationsApi')
      .getAllRecommendations()

    commit('setRecommendations', response.data)
  },

  async getActions({ state, commit }) {
    const { response } = await this.$api
      .recommendation(state.fetchActionsApi)
      .useStorePath('actions.fetchActionsApi')
      .getAllActions()

    commit('setActions', response.data)
  },

  async fetchQuickWins({ state, commit }) {
    try {
      const { response } = await this.$api
        .recommendation(state.fetchQuickWinsApi)
        .useStorePath('actions.fetchQuickWinsApi')
        .getQuickWinRecommendations()

      commit('setQuickWins', response.data)
    } catch (error) {
      this.$log.error('Error fetching quick wins', error)
    }
  }
}

export const mutations = {
  setRecommendations(state, recommendations) {
    state.recommendations = recommendations
  },

  setActions(state, actions) {
    state.actions = actions
  },

  setQuickWins(state, quickWins) {
    state.quickWins = quickWins
  },

  reset(state) {
    Object.assign(state, initialState())
  }
}
