import { ExchangeApi } from '~/services/api/exchange'

export class FootpathService extends ExchangeApi {
  async saveFootpaths({ farmId, footpaths }) {
    return await this.post(`/footpath/${farmId}`, { footpaths })
  }

  async updateFootpaths({ farmId, footpaths }) {
    return await this.put(`/footpath/${farmId}`, { footpaths })
  }

  async getAll(farmId) {
    return await this.get(`/footpath/${farmId}`)
  }

  async deleteFootpath({ farmId, footpathId }) {
    return await this.delete(`/footpath/${farmId}/${footpathId}`)
  }

  async requeueFootpaths(farmId) {
    return await this.post(`/footpath/${farmId}/requeue`)
  }
}
