import { animalFarmTypes } from 'exchange-common/data/farmTypes'
import { roleConfig, staffRoles } from 'exchange-common/roles'
import { camelCase, capitalize, cloneDeep, get, set, upperFirst } from 'lodash'
import Vue from 'vue'

import appVersion from '~/lib/appVersion'
import { ApiModel } from '~/plugins/api/model'
import { isNative, platform } from '~/plugins/native/capacitor'

const initialState = (existingState = {}) => {
  const state = {
    isClientLoaded: false,
    hasFetchedInitialStatus: false,
    hasFailedToFetchInitialStatus: false,
    statusApi: new ApiModel(),
    maintenanceMode: {
      enabled: false,
      bypassed: false,
      message: null,
      resolvedByDateUTC: null
    },
    globalMessage: null
  }

  return state
}

export const state = () => cloneDeep(initialState())

export const getters = {
  isMaintenanceModeEnabled: state => state.maintenanceMode.enabled,
  isMaintenanceModeBypassed: state => state.maintenanceMode.bypassed,

  statusFailedToLoad: state => state.statusApi.response?.code === 403,

  getApp: (state, getters, rootState, rootGetters) => {
    const app = {}

    // GENERAL APP CONFIG

    app.config = state.config
    app.platform = platform
    app.deviceDetails = rootState.device.details[isNative ? 'native' : 'web']
    app.version = appVersion.default
    app.nativeVersion = get(app.deviceDetails, 'app.version', null)
    app.user = rootGetters['auth/getUser']
    app.currentOrganisation = rootGetters['auth/getCurrentOrganisation']
    app.farm = rootGetters['farm/farm'] || {}
    app.farmId = rootGetters['farm/farm']?.id || null
    app.role = get(app.currentOrganisation, 'role', null)
    app.organisations = rootGetters['auth/userOrganisations']
    app.countries = app.currentOrganisation?.countries?.split(',') || []
    app.impersonatingOriginalAdmin = rootState.auth.impersonatingOriginalAdmin
    app.enterprises = app.currentOrganisation?.enterprises || []

    // BOOLEAN STATES

    // app.isProduction app.isLocal etc
    app[`is${capitalize(process.env.APP_ENVIRONMENT)}`] = true
    app[`is${capitalize(platform)}`] = true
    app[`is${upperFirst(camelCase(app.role))}`] = true
    app.isAuth = rootGetters['auth/hasToken'] && !!rootState.auth.user?.id
    app.isFirstLoad = rootState.navigation.routeHistory.length < 2
    app.isNative = isNative
    app.isMobileOrNative = isNative || Vue.prototype.$screen.width <= 1024
    app.isUsingTouch = process.client && window.matchMedia('(any-pointer: coarse)').matches
    app.isMaintenanceModeEnabled = getters.isMaintenanceModeEnabled && !getters.isMaintenanceModeBypassed
    app.isClientLoaded = state.isClientLoaded
    app.isReady = state.hasFetchedInitialStatus
    app.hasFailedToLoad = (getters.statusFailedToLoad || state.hasFailedToFetchInitialStatus) && !app.isReady
    app.isWaitingForPushNotificationSetup = rootGetters['notifications/isWaitingForPushNotificationSetup']
    app.isDebug = process.client ? window.location.search.includes('dev=true') : false
    app.isOnline = rootGetters['device/isOnline']
    app.isStaffOrganisation = app.currentOrganisation?.isStaff === true
    app.isPartner = app.currentOrganisation?.isPartner === true
    app.isImpersonatingUser = rootState.auth.isImpersonatingUser
    app.isMultiOrganisationUser = app.organisations.length > 1
    app.isPaid = app.currentOrganisation?.isPaid === true
    app.isStaffRole = staffRoles.includes(app.role)
    app.isTechnicianOrAdvisor =
      app.role === roleConfig.STAFF_TECHNICIAN.value || app.role === roleConfig.STAFF_ADVISOR.value
    app.hasFilesUploading = rootGetters['files/hasFilesUploading']
    app.isAccessingAnotherOrganisation = !(app.organisations || [])
      .map(({ id }) => id)
      .includes(app?.currentOrganisation?.id)

    const farmTypes = app.currentOrganisation?.enterprises || []

    app.isAnimalFarmType = animalFarmTypes.filter(type => farmTypes.includes(type)).length > 0
    app.isArableFarmType = farmTypes.includes('Arable crops (inc potatoes)')
    app.hasLegacyZonation = app.currentOrganisation?.hasLegacyZonation === true
    app.isLoadingFarm = rootGetters['farm/isLoading']

    app.isSbiRelevant = app.countries && app.countries.length > 0 && app.countries.includes('England')
    app.isScottishFarm = app.countries && app.countries.length > 0 && app.countries.includes('Scotland')

    if (process.client) {
      app.isRetina = window.devicePixelRatio >= 2
    }

    return app
  }
}

export const actions = {
  // This is our entry API call. Here we find out if we are authed or not, and what the config for the app/org is
  async fetchStatus({ state, rootState, commit, dispatch, getters }) {
    // If we are offline and booting up again let's apply permissions from the persisted state
    // as we won't be able to fetch them from the server
    if (!getters.getApp.isOnline && rootState.auth.permissions.length > 0) {
      this.$ability.update(rootState.auth.permissions)

      return
    }

    try {
      await this.$api.system(state.statusApi).useStorePath('app.statusApi').getStatus()

      commit('auth/setUser', get(state.statusApi, 'response.data.user', {}), { root: true })

      if (state.statusApi.response?.data?.permissions) {
        commit('auth/setPermissions', state.statusApi.response.data.permissions, { root: true })

        this.$ability.update(state.statusApi.response.data.permissions)
      }

      await dispatch('auth/setupActiveOrganisation', state.statusApi.response.data?.user?.currentOrganisation, {
        root: true
      })

      commit('setState', { key: 'hasFetchedInitialStatus', value: true })
      commit('setState', { key: 'hasFailedToFetchInitialStatus', value: false })

      dispatch('auth/handleUserResponse', {}, { root: true })
    } catch (error) {
      // Times out after around 30 seconds
      this.$log.debug('Error fetching /status', error)
      commit('setState', { key: 'hasFailedToFetchInitialStatus', value: true })
    }
  },

  async populateStoresForOrganisation({ state, dispatch, commit, rootGetters }, organisation) {
    if (!organisation?.id) {
      return
    }

    dispatch('organisations/addPopulatedOrganisation', organisation, { root: true })

    this.$log.debug('Populating offline stores')

    // Don't fetch data for non farm organisations
    if (!organisation.isStaff && !organisation.isPartner) {
      await dispatch('farm/getFarm', null, { root: true })

      await Promise.all([
        dispatch('report-v3/getReportSummary', undefined, { root: true }),
        dispatch('report-v3/getDimensionNotes', null, { root: true }),
        dispatch('sampling/fetchSamples', 'birds', { root: true }),
        dispatch('sampling/fetchSamples', 'plants', { root: true }),
        dispatch('sampling/fetchSamples', 'hedgerows', { root: true }),
        dispatch('sampling/fetchSamples', 'vess', { root: true }),
        dispatch('sampling/fetchSamples', 'earthworms', { root: true }),
        dispatch('sampling/fetchSamples', 'water', { root: true }),
        dispatch('sampling/fetchSoilLabResults', null, { root: true }),
        dispatch('sampling/fetchPlan', null, { root: true })
      ])
    }
  }
}

export const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  setMaintenanceMode(state, maintenanceModeConfig) {
    set(state, 'maintenanceMode', maintenanceModeConfig)
  },

  setClientLoaded(state) {
    state.isClientLoaded = true
  },

  reset(state) {
    this.$log.debug('Resetting app module')

    Object.assign(state, cloneDeep(initialState()))
  }
}
