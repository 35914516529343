import { ExchangeApi } from '~/services/api/exchange'

export class LabService extends ExchangeApi {
  async fetchSoilLabResults(farmId) {
    return await this.get(`/lab/farm/${farmId}/soil-lab-result`)
  }

  async saveSoilLabResults(farmId, labResults) {
    return await this.put(`/lab/farm/${farmId}/soil-lab-result`, labResults)
  }

  async processAdminImportedFile(fileId) {
    return await this.post(`/lab/admin/soil-lab-result/import/process`, { fileId })
  }

  async saveAdminImportedResults(labResults) {
    return await this.post(`/lab/admin/soil-lab-result/import`, labResults)
  }
}
