import { cloneDeep } from 'lodash'
import { set } from 'vue'

import { ApiModel } from '~/plugins/api/model'
import { isNative } from '~/plugins/native/capacitor'

class OrganisationEntry {
  constructor(organisation) {
    this.id = organisation.id
    this.data = organisation
    this.timestamp = Date.now()
  }
}

const initialState = () => {
  return {
    populatedOrganisations: [],
    invite: {
      code: null,
      introducedBy: null
    }
  }
}

export const state = () => initialState()

export const getters = {
  populatedOrganisations(state) {
    return state.populatedOrganisations
  },

  organisationIsPopulated: state => organisationId => {
    return state.populatedOrganisations.map(({ id }) => id).includes(organisationId)
  },

  getPopulatedOrganisationById: state => organisationId => {
    return state.populatedOrganisations.find(({ id }) => id === organisationId)
  },

  invite(state) {
    return state.invite
  }
}

export const actions = {
  addPopulatedOrganisation({ state, commit }, organisation) {
    if (!organisation) {
      return false
    }

    const populatedOrganisations = cloneDeep(state.populatedOrganisations)
    const index = populatedOrganisations.findIndex(({ id }) => id === organisation.id)

    // remove the old version so we get new data and a new timestamp
    if (index !== -1) {
      populatedOrganisations.splice(index, 1)
    }

    populatedOrganisations.push(new OrganisationEntry(organisation))

    commit('setPopulatedOrganisations', { organisations: populatedOrganisations })
  },

  async checkPopulatedOrganisations({ state, commit }) {
    if (!isNative) {
      return false
    }

    const populatedOrganisations = cloneDeep(state.populatedOrganisations)

    for (const organisation of populatedOrganisations) {
      const { Filesystem, Directory, Encoding } = require('@capacitor/filesystem')

      try {
        await Filesystem.readFile({
          path: `farm-${organisation.id}.json`,
          directory: Directory.Data,
          encoding: Encoding.UTF8
        })
      } catch (error) {
        this.$log.debug('Removing populated organisation', organisation.id)

        commit('removePopulatedOrganisation', { organisationId: organisation.id })
      }
    }
  },

  async validateInviteCode({ commit, getters }, code) {
    const inviteCodeApi = new ApiModel()

    this.$log.debug('Validating invite code', code)

    await this.$api.organisation(inviteCodeApi).validateInviteCode(code)

    const invite = {
      code,
      introducedBy: inviteCodeApi.response.data.introducedBy
    }

    commit('setInviteCode', { code: invite.code, introducedBy: invite.introducedBy })

    return invite
  },

  async loadExistingInviteCode({ state, commit, dispatch }) {
    const inviteCode = this.$cookies.get('invite-code')

    if (inviteCode) {
      commit('setInviteCode', { code: inviteCode })

      // Fetch the invite record again if the user is returning to complete setup
      if (!state.invite.introducedBy) {
        await dispatch('validateInviteCode', inviteCode)
      }
    }
  },

  accessOrganisation({ state }, organisationId) {
    const cookieIds = this.$cookies.get('recently-accessed-organisation-ids') || []

    if (!cookieIds.includes(organisationId)) {
      this.$cookies.set('recently-accessed-organisation-ids', [...cookieIds, organisationId], {
        expires: this.$date().add(2, 'week').toDate()
      })
    }

    this.$redirect.to(`/admin/access-organisations/${organisationId}`)

    this.$analytics.addEvent('access-organisation', {
      organisationId
    })
  }
}

export const mutations = {
  setPopulatedOrganisations(state, { organisations }) {
    set(state, 'populatedOrganisations', organisations)
  },

  removePopulatedOrganisation(state, { organisationId }) {
    const index = state.populatedOrganisations.findIndex(({ id }) => id === organisationId)

    if (index !== -1) {
      state.populatedOrganisations.splice(index, 1)
    }
  },

  setInviteCode(state, { code, introducedBy }) {
    state.invite.code = code
    state.invite.introducedBy = introducedBy

    this.$cookies.set('invite-code', code, {
      path: '/',
      expires: this.$date().add(4, 'week').toDate()
    })
  },

  resetInviteCode(state) {
    state.invite = initialState().invite

    this.$cookies.remove('invite-code')
  },

  reset(state) {
    this.$log.debug('Resetting org module')

    state.invite = initialState().invite
    this.$cookies.remove('invite-code')
  }
}
