import { contentTypes, contentTypesOptions, contentTypesRouteSlug } from 'exchange-common/content/contentTypes'
import { merge } from 'lodash'

import { date } from '~/plugins/date'

export default function (existingModel = {}) {
  return {
    model: merge(
      {
        type: contentTypes.ADVICE,
        author: 'Exchange',
        title: null,
        featuredImage: [],
        summary: null,
        mainContent: null,
        links: [],
        tags: [],
        articlePDF: [],
        supportingFiles: []
      },
      existingModel
    ),
    validation: {
      type: { required: true },
      author: { required: true },
      title: { required: true },
      featuredImage: { required: false },
      summary: { required: true },
      mainContent: { required: false },
      link: { required: false },
      tags: { required: true },
      articlePDF: { required: false },
      supportingFiles: { required: false }
    }
  }
}

export class Article {
  constructor(apiData) {
    const {
      title,
      featuredImage,
      type,
      author,
      updatedAt,
      mainContent,
      links,
      supportingFiles,
      articlePDF,
      tags,
      summary,
      slug,
      id
    } = apiData

    const hasFeaturedImage = featuredImage?.length > 0
    const hasLinks = links?.length > 0
    const hasSupportingFiles = supportingFiles?.length > 0
    const hasArticlePDF = articlePDF?.length > 0
    const hasTags = tags?.length > 0
    const isExternal = !mainContent && hasLinks && links.length === 1

    Object.assign(this, {
      title,
      featuredImage: hasFeaturedImage ? featuredImage[0] : null,
      hasFeaturedImage,
      type,
      readableType: contentTypesOptions.find(option => option.value === type)?.label || '',
      routeSlugConfig: contentTypesRouteSlug[type],
      author,
      date: date(updatedAt).format('MMMM D, YYYY'),
      mainContent,
      links: hasLinks ? links : [],
      hasLinks,
      supportingFiles: hasSupportingFiles ? supportingFiles : [],
      hasSupportingFiles,
      articlePDF: hasArticlePDF ? articlePDF[0] : null,
      hasArticlePDF,
      tags: hasTags ? tags : [],
      hasTags,
      summary,
      slug,
      id,
      isExternal,
      externalLink: isExternal ? links[0] : null
    })
  }
}
