export const actionStatus = {
  IN_PROGRESS: 'in progress',
  COMPLETED: 'completed'
}

export const actionStatusConfig = {
  IN_PROGRESS: {
    value: actionStatus.IN_PROGRESS,
    color: 'blue',
    translationKey: 'inProgress'
  },
  COMPLETED: {
    value: actionStatus.COMPLETED,
    color: 'gray',
    translationKey: 'completed'
  }
}

export function findActionConfigByValue(value) {
  return Object.values(actionStatusConfig).find(status => status.value === value)
}
