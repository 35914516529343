import { ExchangeApi } from '~/services/api/exchange'

export class WaterResultService extends ExchangeApi {
  async createWaterResultForFarm(farmId) {
    await this.post(`/water-result/farm/${farmId}`)
  }

  async updateWaterResult(id) {
    await this.put(`/water-result/${id}`)
  }

  async fetchWaterResultForFarm(farmId) {
    return await this.get(`/water-result/farm/${farmId}`)
  }
}
