export const contentTypes = {
  CASE_STUDY: 'CASE_STUDY',
  TUTORIAL: 'TUTORIAL',
  ADVICE: 'ADVICE',
  FUNDING: 'FUNDING'
}

export const contentTypesOptions = [
  { value: contentTypes.CASE_STUDY, label: 'Case study' },
  { value: contentTypes.TUTORIAL, label: 'Tutorial' },
  { value: contentTypes.ADVICE, label: 'Advice' },
  { value: contentTypes.FUNDING, label: 'Funding' }
]

export const contentTypesRouteSlug = {
  [contentTypes.CASE_STUDY]: {
    slug: 'case-studies',
    label: 'Case studies'
  },
  [contentTypes.TUTORIAL]: {
    slug: 'tutorials',
    label: 'Tutorials'
  },
  [contentTypes.ADVICE]: {
    slug: 'advice',
    label: 'Advice'
  },
  [contentTypes.FUNDING]: {
    slug: 'funding',
    label: 'Funding'
  }
}

export const contentTypesEnum = Object.values(contentTypes)
