import { isNative } from '~/plugins/native/capacitor'

// Handle Face/Touch ID authentication
export default async function ({ store, $log }, inject) {
  const biometrics = {
    isAvailable: false,

    isActive: () => {
      return store.state.auth.hasBiometrics
    }
  }

  if (isNative) {
    const { BiometricAuth } = await import('@aparajita/capacitor-biometric-auth')
    const { SecureStoragePlugin } = await import('capacitor-secure-storage-plugin')

    const { isAvailable } = await BiometricAuth.checkBiometry()

    if (isAvailable) {
      biometrics.isAvailable = true

      biometrics.isAuthenticated = async () => {
        try {
          await BiometricAuth.authenticate()
          return true
        } catch (error) {
          $log.error('Error with biometric authentication', error)
          return false
        }
      }

      biometrics.saveCredentials = async (email, password) => {
        await SecureStoragePlugin.set({ key: 'email', value: email })
        await SecureStoragePlugin.set({ key: 'password', value: password })

        store.commit('auth/setHasBiometrics', true)
      }

      biometrics.getCredentials = async () => {
        const email = await SecureStoragePlugin.get({ key: 'email' })
        const password = await SecureStoragePlugin.get({ key: 'password' })

        return {
          email: email.value,
          password: password.value
        }
      }

      biometrics.deleteCredentials = async () => {
        try {
          const keys = await SecureStoragePlugin.keys()

          if (keys.length > 0) {
            await SecureStoragePlugin.remove({ key: 'email' })
            await SecureStoragePlugin.remove({ key: 'password' })
          }

          store.commit('auth/setHasBiometrics', false)
        } catch (error) {
          $log.error('Error deleting biometric credentials', error)
        }
      }
    }
  }

  inject('biometrics', biometrics)
}
