import semverCompare from 'semver/functions/compare.js'

import appVersion from '~/lib/appVersion'
import { isNative, platform } from '~/plugins/native/capacitor'

// Check available app store version
export default async function ({ store, app, $log }, inject) {
  const appStoreUpdate = {}

  if (isNative) {
    const { AppUpdate } = await import('@capawesome/capacitor-app-update')
    const { App } = await import('@capacitor/app')

    appStoreUpdate.check = async () => {
      // Sleep 10 seconds to allow OTA to finish
      await new Promise(resolve => setTimeout(resolve, 10000))

      const { currentNativeVersion, availableNativeVersion } = await appStoreUpdate.getVersions()

      const currentVersion = appVersion.default

      $log.debug('Current native version:', currentNativeVersion)
      $log.debug('Available native version:', availableNativeVersion)

      if (currentNativeVersion !== availableNativeVersion) {
        // If the semver string a is greater than b, return 1.
        // If the semver string b is greater than a, return -1.
        // If a equals b, return 0;
        const versionCompare = semverCompare(currentVersion, availableNativeVersion)

        // We don't want to show a newer version if the OTA is in the process of updating
        if (versionCompare === -1 && store.getters['overTheAirUpdates/isUpToDate']) {
          store.commit('device/setNewerAppStoreVersionCode', availableNativeVersion)
        } else {
          store.commit('device/setNewerAppStoreVersionCode', null)
        }
      }
    }

    appStoreUpdate.getVersions = async () => {
      let currentNativeVersion = null
      let availableNativeVersion = null

      try {
        const result = await AppUpdate.getAppUpdateInfo()

        if (platform === 'android') {
          availableNativeVersion = result.availableVersionCode
          currentNativeVersion = result.currentVersionCode
        } else {
          availableNativeVersion = result.availableVersionName
          currentNativeVersion = result.currentVersionName
        }
      } catch (error) {
        $log.error('Error getting app update info', error)
        return { currentNativeVersion: null, availableNativeVersion: null }
      }

      return { currentNativeVersion, availableNativeVersion }
    }

    appStoreUpdate.openAppStore = async () => {
      await AppUpdate.openAppStore()
    }

    // Re-check whenever the app comes back into focus
    App.addListener('appStateChange', appState => {
      if (appState.isActive) {
        appStoreUpdate.check()
      }
    })

    appStoreUpdate.check()
  }

  inject('appStoreUpdate', appStoreUpdate)
}
