import { render, staticRenderFns } from "./DropdownMenuList.vue?vue&type=template&id=302f7fac&scoped=true"
import script from "./DropdownMenuList.vue?vue&type=script&lang=js"
export * from "./DropdownMenuList.vue?vue&type=script&lang=js"
import style0 from "./DropdownMenuList.vue?vue&type=style&index=0&id=302f7fac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "302f7fac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Flag: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/geo/flag/Flag.vue').default})
