import { ExchangeApi } from '~/services/api/exchange'

export class FoodProductionService extends ExchangeApi {
  async createFoodProductionForFarm(farmId, data) {
    await this.post(`/food-production/farm/${farmId}`, data)
  }

  async fetchFoodProduceItemsForFarm(farmId) {
    return await this.get(`/food-production/farm/${farmId}`)
  }
}
