
export default {
  name: 'LayoutDefault',

  data() {
    return {
      isClientMounted: false
    }
  },

  computed: {
    compShouldShowAppLoader() {
      return (
        (this.$app.isNative && !this.$app.isReady && (!this.$app.isOnline || this.$app.hasFailedToLoad)) ||
        (!this.$app.isNative && !this.$app.isReady)
      )
    }
  },

  created() {
    if (this.$store.state.app.statusApi.response.code === 500 && this.$app.isOnline) {
      this.$log.error('Fatal error in status API')

      this.$redirect.to('/auth/login')
    }

    this.runInitialLoadChecks()
  },

  methods: {
    async runInitialLoadChecks() {
      // On native if we've loaded the app before we skip the initial blocking status fetch in the default router middleware
      // in order to hide the fixed generic splashscreen and show our own branding loading screen as quickly as possible.
      // Since we still need the info from status, we will make the call non-blocking here instead while the custom splash screen renders
      if (this.$app.isNative && !this.$app.isReady) {
        console.log('Native not ready in default layout, fetching status')
        await this.$store.dispatch('app/fetchStatus')
      }
    }
  }
}
