import { GraphVertex, LayeredGraphVertex } from './graphVertex.js'

/**
 * Represents a store for vertices in a graph.
 */
export class VertexStore {
  /**
   * Mapping from vertex key to vertex object
   * @type {Map<string|number, GraphVertex>}
   * @private
   */
  _vertexMap

  /**
   * The next vertex generated will be given this id
   * @type number
   * @private
   */
  _nextVertexId

  constructor() {
    this._vertexMap = new Map()
    this._nextVertexId = 1
  }

  /**
   * Retrieves all vertices from the graph.
   *
   * @return {Array<GraphVertex>} An array containing all the vertices in the graph.
   */
  getAllVertices = () => {
    return [...this._vertexMap.values()]
  }

  /**
   * Adds a new vertex to the graph.
   *
   * @param {string|number} vertexKey - The unique key of the vertex.
   * @param {*} vertexData - The data associated with the vertex.
   * @returns {GraphVertex} - The newly added vertex.
   */
  addVertex(vertexKey, vertexData) {
    const vertex = new GraphVertex(this._nextVertexId, vertexKey, vertexData)
    this._nextVertexId++
    this._vertexMap.set(vertex.getVertexKey(), vertex)
    return vertex
  }

  /**
   * Retrieves the vertex with the given key from the vertex store.
   *
   * @param {string|number} vertexKey - The key of the vertex to retrieve.
   * @returns {GraphVertex} The vertex with the given key, or undefined if not found.
   */
  getVertex = vertexKey => {
    return this._vertexMap.get(vertexKey)
  }

  /**
   * Removes a vertex from the vertex store.
   *
   * @param {GraphVertex} vertex - The vertex to be removed from the graph.
   */
  removeVertex(vertex) {
    // Remove all edges to and from this vertex
    for (const vertexEdge of vertex.getAllEdges()) {
      vertexEdge.targetVertex.removeEdge(vertex)
      vertex.removeEdge(vertexEdge.targetVertex)
    }
    this._vertexMap.delete(vertex.getVertexKey())
  }
}

/**
 * Represents the storage of vertices within layers for use in a graph
 */
export class LayeredVertexStore {
  /**
   * A mapping from layerId to a mapping from featureId to layeredGraphVertex
   * @type {Map<string|number,Map<string|number,LayeredGraphVertex>>}
   * @private
   */
  _vertexLayerMap

  /**
   * The next vertex generated will be given this id
   * @type number
   * @private
   */
  _nextVertexId

  constructor() {
    this._vertexLayerMap = new Map()
    this._nextVertexId = 1
  }

  /**
   * Retrieves all vertices from the graph.
   *
   * @return {Array<LayeredGraphVertex>} An array containing all the vertices in the graph.
   */
  getAllVertices = () => {
    let vertices = []
    for (const layerId of this._vertexLayerMap.keys()) {
      vertices = [...vertices, ...this._vertexLayerMap.get(layerId).values()]
    }
    return vertices
  }

  /**
   * Retrieves all vertices within the given layers from this vertex store
   *
   * @param {Array<string | number>} targetLayerIds - An array of layer IDs for which edges are to be retrieved.
   * @return {Array<LayeredGraphVertex>} An array containing all the vertices in the graph.
   */
  getAllVerticesWithinLayers = targetLayerIds => {
    let vertices = []
    for (const layerId of targetLayerIds) {
      const layer = this._vertexLayerMap.get(layerId)
      if (layer) {
        vertices = [...vertices, ...layer.values()]
      }
    }
    return vertices
  }

  /**
   * Adds a new vertex to the graph.
   *
   * @param {Array<string|number>} vertexKey - The unique key of the vertex.
   * @param {*} vertexData - The data associated with the vertex.
   * @returns {LayeredGraphVertex} - The newly added vertex.
   */
  addVertex(vertexKey, vertexData) {
    const [layerId, featureId] = vertexKey
    const vertex = new LayeredGraphVertex(this._nextVertexId, layerId, featureId, vertexData)
    this._nextVertexId++
    let vertexMap = this._vertexLayerMap.get(layerId)
    if (!vertexMap) {
      vertexMap = new Map()
      this._vertexLayerMap.set(layerId, vertexMap)
    }
    vertexMap.set(featureId, vertex)
    return vertex
  }

  /**
   * Retrieves the vertex with the given key from the vertex store.
   *
   * @param {Array<string|number>} vertexKey - The key of the vertex to retrieve.
   * @returns {LayeredGraphVertex} The vertex with the given key, or undefined if not found.
   */
  getVertex = vertexKey => {
    const [layerId, featureId] = vertexKey
    let vertexMap = this._vertexLayerMap.get(layerId)
    if (vertexMap) {
      return vertexMap.get(featureId)
    }
    return undefined
  }

  /**
   * Removes a vertex from the vertex store.
   *
   * @param {LayeredGraphVertex} vertex - The vertex to be removed from the graph.
   */
  removeVertex(vertex) {
    // Remove all edges to and from this vertex
    for (const vertexEdge of vertex.getAllEdges()) {
      vertexEdge.targetVertex.removeEdge(vertex)
      vertex.removeEdge(vertexEdge.targetVertex)
    }
    this._vertexLayerMap.get(vertex.getLayerId()).delete(vertex.getFeatureId())
  }
}
