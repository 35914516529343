import { clustersDbscan } from '@turf/clusters-dbscan'
import { distance } from '@turf/distance'
import { point } from '@turf/helpers'
import { groupBy } from 'lodash'

export const maxDistanceWaterPlannedLocationKm = 0.1

export function getSamplesGroupedByUniqueLocation(samples) {
  const featureCollection = {
    type: 'FeatureCollection',
    features: samples.map(sample => point(sample.location.coordinates, sample))
  }

  // Group samples by location, clustering at 20 meters
  const clusteredCollection = clustersDbscan(featureCollection, 0.02, { units: 'kilometers', minPoints: 1 })

  // Map to original samples object as we don't want to destroy the class binding
  clusteredCollection.features.forEach(feature => {
    // Find sample by id
    const sample = samples.find(sample => sample.id === feature.properties.id)

    if (sample) {
      sample.cluster = feature.properties.cluster
    } else {
      this.$log.error(`Couldn't find cluster sample match`, feature)
    }
  })

  const groupedSamples = groupBy(samples, ({ cluster }) => cluster)

  // Order items in each group by recordedAt
  Object.keys(groupedSamples).forEach(cluster => {
    groupedSamples[cluster] = groupedSamples[cluster].sort((a, b) => {
      return new Date(a.recordedAt) - new Date(b.recordedAt)
    })
  })

  return groupedSamples
}

export function getSamplesGroupedByField(samples) {
  return groupBy(samples, sample => sample.location.parcel || 'uncategorised')
}

export function getSamplesGroupedByFieldAndLocation(samples) {
  const groupedByField = getSamplesGroupedByField(samples)

  return Object.keys(groupedByField).reduce((acc, fieldId) => {
    acc[fieldId] = getSamplesGroupedByUniqueLocation(groupedByField[fieldId])
    return acc
  }, {})
}

// TODO: V3: delete ???
export function findNoNewSpeciesForLocation({ samples, coordinates }) {
  // NB: this is here because the number of decimal places stored in db for location is lower than
  // the number captured by the input for location in the client. This should probably have some
  // more thought given to what the right approach is
  const fixedCoords = [coordinates[0].toFixed(9), coordinates[1].toFixed(9)]

  return samples.find(
    sample => sample.location.coordinates.toString() === fixedCoords.toString() && sample.noNewSpeciesDetected
  )
}

// TODO: V3 delete ???
export function getSamplesForLocation({ coordinates, maxDistanceKm = 0.02, samples }) {
  if (!coordinates || !coordinates.length === 0) {
    return []
  }

  const originatingPoint = point(coordinates)

  return samples.filter(sample => {
    const samplePoint = point(sample.location.coordinates)

    const distanceBetweenPoints = distance(originatingPoint, samplePoint, { units: 'kilometers' })

    return distanceBetweenPoints <= maxDistanceKm
  })
}
