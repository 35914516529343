export const fileTypes = {
  spreadsheet: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'
  ],

  document: [
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],

  image: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'],

  video: [
    'video/mp4',
    'application/x-mpegURL',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/webm',
    'video/x-msvideo',
    'video/mpeg'
  ],

  csv: [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'
  ],

  pdf: ['application/pdf'],

  zip: ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip']
}

export const fileExtensions = {
  spreadsheet: ['xls'],

  document: ['doc', 'docx'],

  image: ['jpg', 'jpeg', 'png', 'gif', 'webp'],

  csv: ['csv'],

  pdf: ['pdf'],

  video: ['mp4', 'mov', 'webm', 'mpeg', 'm4v', 'qt', 'flv', 'ogg', 'avi', 'wmv'],

  zip: ['zip']
}

export const categories = {
  AVATAR: 'AVATAR',
  CONTENT: 'CONTENT',
  PARTNER_FARMS: 'PARTNER_FARMS',
  SHAPEFILE: 'SHAPEFILE',
  ACTIONS: 'ACTIONS',
  LABFILE: 'LABFILE',
  ARTICLE_IMAGE: 'ARTICLE_IMAGE',
  ARTICLE_PDF: 'ARTICLE_PDF',
  ARTICLE_SUPPORTING_FILES: 'ARTICLE_SUPPORTING_FILES',
  SAMPLING_PICTURE: 'SAMPLING_PICTURE',
  OTA: 'OTA',
  DATA_ENTRY_SUPPORTING_FILES: 'DATA_ENTRY_SUPPORTING_FILES',
  JOURNAL_FILE: 'JOURNAL_FILE'
}

export function getCategoryEnum() {
  return Object.values(categories)
}

export const categoryValidation = {
  [categories.AVATAR]: {
    isPublic: true,
    bucketPrefix: 'avatar',
    mimeTypes: fileTypes.image,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.CONTENT]: {
    isPublic: true,
    bucketPrefix: 'content',
    mimeTypes: fileTypes.image,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.SHAPEFILE]: {
    isPublic: false,
    bucketPrefix: 'shapefile',
    mimeTypes: fileTypes.zip,
    maxSizeBytes: 20 * 1024 * 1024
  },
  [categories.ACTIONS]: {
    isPublic: true,
    bucketPrefix: 'actions',
    mimeTypes: [...fileTypes.document, ...fileTypes.pdf, ...fileTypes.image, ...fileTypes.spreadsheet],
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.LABFILE]: {
    isPublic: false,
    bucketPrefix: 'labs',
    mimeTypes: fileTypes.csv,
    maxSizeBytes: 5 * 1024 * 1024
  },
  [categories.ARTICLE_IMAGE]: {
    isPublic: true,
    bucketPrefix: 'article',
    mimeTypes: fileTypes.image,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.ARTICLE_PDF]: {
    isPublic: true,
    bucketPrefix: 'article',
    mimeTypes: fileTypes.pdf,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.ARTICLE_SUPPORTING_FILES]: {
    isPublic: true,
    bucketPrefix: 'article',
    mimeTypes: fileTypes.pdf,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.SAMPLING_PICTURE]: {
    isPublic: true,
    bucketPrefix: 'sampling',
    mimeTypes: fileTypes.image,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.OTA]: {
    isPublic: true,
    bucketPrefix: 'ota',
    mimeTypes: fileTypes.zip,
    maxSizeBytes: 20 * 1024 * 1024
  },
  [categories.DATA_ENTRY_SUPPORTING_FILES]: {
    isPublic: true,
    bucketPrefix: 'data_entry',
    mimeTypes: fileTypes.pdf,
    maxSizeBytes: 10 * 1024 * 1024
  },
  [categories.JOURNAL_FILE]: {
    isPublic: true,
    bucketPrefix: 'journal',
    mimeTypes: [
      ...fileTypes.document,
      ...fileTypes.pdf,
      ...fileTypes.image,
      ...fileTypes.spreadsheet,
      ...fileTypes.csv
    ],
    maxSizeBytes: 10 * 1024 * 1024
  }
}

export function getFileExtensionFromString(targetString, upperCase = false) {
  let result = targetString.split('.')[targetString.split('.').length - 1]

  if (upperCase) {
    result = result.toUpperCase()
  }

  return result
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
