import { ExchangeApi } from '~/services/api/exchange'

export class NotificationService extends ExchangeApi {
  async getActivityById(notificationId) {
    return await this.get(`/notification/${notificationId}`)
  }

  async savePushToken(token) {
    return await this.post('/notification/token', {
      type: 'push',
      token
    })
  }
}
