/**
 * Given a source layer and list of featureIds, and a target layer and list of target featureIds
 * For each target layer generate lists of:
 *    vertices in the target layer that are within a proximityDistance of the source features
 *    vertices in the source layer that are within a proximityDistance of any feature in the target layer
 *
 * Only the supplied featureIds for the source layer and the target layer will be reported upon
 *
 * @param {LayeredNonDirectedGraph} proximityGraph
 * @param {string|number} sourceLayerId
 * @param {Array<string|number>} sourceFeatureIds
 * @param {string|number} targetLayerId
 * @param {Array<string|number>} targetFeatureIds
 * @param {number} proximityDistanceMeters
 * @returns {{sourceVertices:LayeredGraphVertex[], targetVertices:LayeredGraphVertex[]}}
 */
export const verticesWithinDistance = (
  proximityGraph,
  sourceLayerId,
  sourceFeatureIds,
  targetLayerId,
  targetFeatureIds,
  proximityDistanceMeters
) => {
  /**
   *
   * @type  Set<LayeredGraphVertex>
   */
  const sourceVerticesSet = new Set()

  /**
   *
   * @type  Set<LayeredGraphVertex>
   */
  const targetVerticesSet = new Set()

  for (const sourceFeatureId of sourceFeatureIds) {
    const sourceVertex = proximityGraph.getVertex([sourceLayerId, sourceFeatureId])

    if (sourceVertex) {
      const neighbors = proximityGraph.neighborsWithinLayers(
        sourceVertex,
        [targetLayerId],
        edgeData => edgeData.proximityMeters <= proximityDistanceMeters
      )

      for (const neighbor of neighbors) {
        const { vertex } = neighbor
        if (targetFeatureIds.includes(vertex.getFeatureId())) {
          sourceVerticesSet.add(sourceVertex)
          targetVerticesSet.add(vertex)
        }
      }
    }
  }

  return {
    sourceVertices: [...sourceVerticesSet.values()],
    targetVertices: [...targetVerticesSet.values()]
  }
}

export class ParcelOverlapInfo {
  /**
   * @type {string}
   */
  parcelId

  /**
   * The area of the parcel as defined within the graph
   * This area works well with the graph overlap areas (same calculation method and rounding)
   * @type {number}
   */
  parcelAreaHectares

  /**
   * The closest proximity to this parcel for any feature in the layers
   * null => nothing found within proximity
   * 0 => touching or overlap
   * 44 => 44 meters to feature closest to this parcel (in any of the supplied source layers)
   * @type {number}
   */
  closestProximityDistanceMeters

  /**
   * @type {Map<string, OverlapFeatureAggregate>}
   */
  overlapFeatureAggregateMap
}

export class OverlapFeatureAggregate {
  /**
   * @type {string | number}
   */
  proximalFeaturePropertyValue

  /**
   * @type {string | number}
   */
  proximalFeaturePropertySortableValue

  /**
   * @type {Set}
   */
  layerIdSet

  /**
   * Total overlap area (hectares) for this aggregate
   * @type {number}
   */
  totalOverlapAreaHectares
}

/**
 * Build a parcelOverlapInfoMap structure for each parcel against particular layers in the contextualDataProximityGraph
 * Each parcel will be mapped to a structure containing a good estimate of overlaps aggregated by the feature property value
 * Unclassified areas are inferred by parcels that are not fully covered (by largest overlap)
 *
 * @param {Array<string>} parcelIds Only provide aggregate information for parcels within this list of parcel ids
 * @param {LayeredNonDirectedGraph} contextualDataProximityGraph Contextual data proximity graph
 * @param {Array<{layerId:string;propertyName:string;sortablePropertyName:string}>} layersAnProperties of layerIds to include in the calculation
 * @param {{maxProximityDistanceMeters: number}} options
 * @returns {Map<string,ParcelOverlapInfo>} Returns a mapping from parcelId to an object with parcel proximal data
 */
export const getParcelLayerAggregateOverlapInformation = (
  parcelIds,
  contextualDataProximityGraph,
  layersAnProperties,
  options
) => {
  const { maxProximityDistanceMeters = 0 } = options
  const layerIds = layersAnProperties.map(item => item.layerId)
  const layerPropertyNamesMap = new Map()
  for (const layerPropertyInfo of layersAnProperties) {
    layerPropertyNamesMap.set(layerPropertyInfo.layerId, layerPropertyInfo)
  }

  /**
   * @type {Map<string, ParcelOverlapInfo>}
   */
  const parcelOverlapInfoMap = new Map()
  for (const parcelId of parcelIds) {
    /**
     *
     * @type {ParcelOverlapInfo}
     */
    const parcelOverlapInfo = {
      parcelId,
      parcelAreaHectares: null,
      closestProximityDistanceMeters: null,
      overlapFeatureAggregateMap: new Map()
    }

    const parcelVertex = contextualDataProximityGraph.getVertex(['parcel', parcelId])
    if (parcelVertex) {
      // Use the graphs version of areaHectares. It has been rounded to align with the edge areas
      parcelOverlapInfo.parcelAreaHectares = parcelVertex.getVertexData().areaHectares

      parcelOverlapInfo.overlapFeatureAggregateMap.set(null, {
        proximalFeaturePropertyValue: null,
        proximalFeaturePropertySortableValue: null,
        layerIdSet: new Set(),
        totalOverlapAreaHectares: parcelVertex.getVertexData().areaHectares
      })

      // Note that the contextualDataProximityGraph will need to have been built with the correct maxProximityDistanceMeters set,
      // otherwise the proximity data won't be in it
      const neighbors = contextualDataProximityGraph.neighborsWithinLayers(
        parcelVertex,
        layerIds,
        edgeData => edgeData.proximityMeters <= maxProximityDistanceMeters
      )
      if (neighbors.length > 0) {
        // Find distance to nearest neighbor
        for (const { vertex, edgeData } of neighbors) {
          if (edgeData.hasIntersection) {
            parcelOverlapInfo.closestProximityDistanceMeters = 0

            const propertyName = layerPropertyNamesMap.get(vertex.getLayerId()).propertyName
            const sortablePropertyName = layerPropertyNamesMap.get(vertex.getLayerId()).sortablePropertyName

            const proximalFeaturePropertyValue = vertex.getVertexData().featureProperties[propertyName]
            const proximalFeaturePropertySortableValue =
              vertex.getVertexData().featureProperties[sortablePropertyName]
            /**
             * @type {OverlapFeatureAggregate}
             */
            let overlapFeatureAggregate =
              parcelOverlapInfo.overlapFeatureAggregateMap.get(proximalFeaturePropertyValue)
            if (!overlapFeatureAggregate) {
              /**
               * @type {OverlapFeatureAggregate}
               */
              overlapFeatureAggregate = {
                proximalFeaturePropertyValue: proximalFeaturePropertyValue,
                proximalFeaturePropertySortableValue: proximalFeaturePropertySortableValue,
                layerIdSet: new Set(),
                totalOverlapAreaHectares: 0
              }
              parcelOverlapInfo.overlapFeatureAggregateMap.set(
                proximalFeaturePropertyValue,
                overlapFeatureAggregate
              )
            }
            overlapFeatureAggregate.layerIdSet.add(vertex.getLayerId())
            // This algorithm takes the largest overlap area of all features sharing a featureValue
            let overlapAreaDifference = 0
            if (edgeData.intersectionAreaHectares > overlapFeatureAggregate.totalOverlapAreaHectares) {
              overlapAreaDifference =
                edgeData.intersectionAreaHectares - overlapFeatureAggregate.totalOverlapAreaHectares
              overlapFeatureAggregate.totalOverlapAreaHectares = edgeData.intersectionAreaHectares
            }

            // Adjust the unclassified area
            const unclassifiedFeatureAggregate = parcelOverlapInfo.overlapFeatureAggregateMap.get(null)
            unclassifiedFeatureAggregate.totalOverlapAreaHectares -= overlapAreaDifference
            if (unclassifiedFeatureAggregate.totalOverlapAreaHectares < 0) {
              unclassifiedFeatureAggregate.totalOverlapAreaHectares = 0
            }
          } else {
            // No intersection, but might be closer than the feature currently closest to this parcel
            if (
              parcelOverlapInfo.closestProximityDistanceMeters === null ||
              parcelOverlapInfo.closestProximityDistanceMeters > edgeData.proximityMeters
            ) {
              parcelOverlapInfo.closestProximityDistanceMeters = edgeData.proximityMeters
            }
          }
        }
      }
    }

    // Remove unclassified area if area is 0
    const unclassifiedFeatureAggregate = parcelOverlapInfo.overlapFeatureAggregateMap.get(null)
    if (unclassifiedFeatureAggregate.totalOverlapAreaHectares <= 0) {
      parcelOverlapInfo.overlapFeatureAggregateMap.delete(null)
    }
    parcelOverlapInfoMap.set(parcelOverlapInfo.parcelId, parcelOverlapInfo)
  }

  return parcelOverlapInfoMap
}

export class ProximalFeatureAggregate {
  /**
   *
   * @type {string|number}
   */
  proximalFeaturePropertyValue

  /**
   *
   * @type {string|number}
   */
  proximalFeaturePropertySortableValue

  /**
   *
   * @type {number}
   */
  totalOverlapAreaHectares

  /**
   *
   * @type {number}
   */
  totalOverlapPercentageOfAllParcels

  /**
   *
   * @type {number}
   */
  parcelCount

  /**
   *
   * @type {Set<string>}
   */
  layerIdSet
}

/**
 * Aggregate overlap feature areas by the features property value
 * Guarantees that the sum of totalOverlapPercentageOfAllParcels will be 100.00
 * @param {Map<string,ParcelOverlapInfo>} parcelOverlapInfoMap
 * @returns {Map<string|number, ProximalFeatureAggregate>}
 */
export const getOverlapFeatureAggregateMap = parcelOverlapInfoMap => {
  /**
   *
   * @type {Map<string | number, ProximalFeatureAggregate>}
   */
  const overlapFeatureAggregateMap = new Map()
  for (const parcelOverlapInfoItem of parcelOverlapInfoMap.values()) {
    for (const parcelOverlapFeatureAggregate of parcelOverlapInfoItem.overlapFeatureAggregateMap.values()) {
      let overlapFeatureAggregate = overlapFeatureAggregateMap.get(
        parcelOverlapFeatureAggregate.proximalFeaturePropertyValue
      )
      if (!overlapFeatureAggregate) {
        overlapFeatureAggregate = {
          proximalFeaturePropertyValue: parcelOverlapFeatureAggregate.proximalFeaturePropertyValue,
          proximalFeaturePropertySortableValue: parcelOverlapFeatureAggregate.proximalFeaturePropertySortableValue,
          totalOverlapAreaHectares: 0,
          totalOverlapPercentageOfAllParcels: 0,
          parcelCount: 0,
          layerIdSet: new Set()
        }
        overlapFeatureAggregateMap.set(
          parcelOverlapFeatureAggregate.proximalFeaturePropertyValue,
          overlapFeatureAggregate
        )
      }
      overlapFeatureAggregate.totalOverlapAreaHectares += parcelOverlapFeatureAggregate.totalOverlapAreaHectares
      overlapFeatureAggregate.parcelCount += 1
      for (const layerId of parcelOverlapFeatureAggregate.layerIdSet.values()) {
        overlapFeatureAggregate.layerIdSet.add(layerId)
      }
    }
  }

  // Post process to calculate percentageOfAllParcels

  // Calculate the total area of all the parcels
  let totalAreaOfAllParcelsHectares = 0
  for (const parcelOverlapInfo of parcelOverlapInfoMap.values()) {
    for (const overlapFeatureAggregate of parcelOverlapInfo.overlapFeatureAggregateMap.values()) {
      totalAreaOfAllParcelsHectares += overlapFeatureAggregate.totalOverlapAreaHectares
    }
  }

  let totalPercentage = 0
  for (const overlapFeatureAggregate of overlapFeatureAggregateMap.values()) {
    const aggregatePercentage = +(
      (100 * overlapFeatureAggregate.totalOverlapAreaHectares) /
      totalAreaOfAllParcelsHectares
    ).toFixed(2)
    totalPercentage += aggregatePercentage
    overlapFeatureAggregate.totalOverlapPercentageOfAllParcels = aggregatePercentage
  }

  if (totalPercentage > 100) {
    // Alter the percentage on the largest aggregate area
    const sortedAggregateAreas = [...overlapFeatureAggregateMap.values()].sort(
      (a, b) => b.totalOverlapAreaHectares - a.totalOverlapAreaHectares
    )
    sortedAggregateAreas[0].totalOverlapPercentageOfAllParcels =
      sortedAggregateAreas[0].totalOverlapPercentageOfAllParcels - (totalPercentage - 100)
  }

  return overlapFeatureAggregateMap
}

export class FeatureProximitySummary {
  /**
   *
   * @type {{totalParcelAreaHectares: number, totalParcelAreaCoveredHectares: number, parcelCount:number}}
   */
  parcelsCoveredByFeatures

  /**
   *
   * @type {{totalParcelAreaHectares: number, totalParcelAreaCoveredHectares: number, parcelCount:number}}
   */
  parcelsNotCoveredByFeaturesButWithinProximity
}
/**
 *
 * @param parcelOverlapInfoMap {Map<string,ParcelOverlapInfo>}
 * @returns FeatureProximitySummary
 */
export const getProximitySummary = parcelOverlapInfoMap => {
  /**
   *
   * @type FeatureProximitySummary
   */
  const summaryData = {
    parcelsCoveredByFeatures: { totalParcelAreaHectares: 0, totalParcelAreaCoveredHectares: 0, parcelCount: 0 },
    parcelsNotCoveredByFeaturesButWithinProximity: {
      totalParcelAreaHectares: 0,
      totalParcelAreaCoveredHectares: 0,
      parcelCount: 0
    }
  }

  for (const parcelOverlapInfoItem of parcelOverlapInfoMap.values()) {
    let totalParcelOverlapHectares = 0
    let nonOverlappingParcelWithinProximity = false
    let parcelHasOverlap = false
    for (const parcelOverlapFeatureAggregate of parcelOverlapInfoItem.overlapFeatureAggregateMap.values()) {
      if (parcelOverlapFeatureAggregate.layerIdSet.size > 0) {
        // Overlapping with features
        if (totalParcelOverlapHectares < parcelOverlapFeatureAggregate.totalOverlapAreaHectares) {
          totalParcelOverlapHectares = parcelOverlapFeatureAggregate.totalOverlapAreaHectares
        }
        parcelHasOverlap = true
      } else {
        // Non overlapping parcel with features
        if (parcelOverlapInfoItem.closestProximityDistanceMeters !== null) {
          nonOverlappingParcelWithinProximity = true
        }
      }
    }
    if (parcelHasOverlap) {
      summaryData.parcelsCoveredByFeatures.parcelCount += 1
      summaryData.parcelsCoveredByFeatures.totalParcelAreaHectares += parcelOverlapInfoItem.parcelAreaHectares
      summaryData.parcelsCoveredByFeatures.totalParcelAreaCoveredHectares += totalParcelOverlapHectares
    } else {
      if (nonOverlappingParcelWithinProximity) {
        summaryData.parcelsNotCoveredByFeaturesButWithinProximity.parcelCount += 1
        summaryData.parcelsNotCoveredByFeaturesButWithinProximity.totalParcelAreaHectares +=
          parcelOverlapInfoItem.parcelAreaHectares
      }
    }
  }

  return summaryData
}
