import 'dayjs/locale/en-gb'

import dayjs from 'dayjs'
import calendarFormat from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#relativetime
dayjs.extend(relativeTime)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#localizedFormat
dayjs.extend(localizedFormat)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#calendar
dayjs.extend(calendarFormat)

// DOCS: https://day.js.org/docs/en/plugin/duration
dayjs.extend(duration)

// DOCS: https://day.js.org/docs/en/plugin/timezone
dayjs.extend(utc)
dayjs.extend(timezone)

// DOCS: https://day.js.org/docs/en/plugin/is-between
dayjs.extend(isBetween)

// DOCS: https://day.js.org/docs/en/plugin/is-today
dayjs.extend(isToday)

export const date = dayjs

export default ({ app, $i18n }, inject) => {
  date.relative = dateString => {
    return date.utc(dateString).local().calendar(null, {
      sameDay: '[Today] [at] HH:mm', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow] [at] HH:mm', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'llll', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday] [at] HH:mm', // The day before ( Yesterday at 2:30 AM )
      lastWeek: 'llll', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'llll' // Everything else ( 7/10/2011 )
    })
  }

  date.relativeShort = dateString => {
    if (!dateString) {
      return ''
    }

    return date.utc(dateString).local().calendar(null, {
      sameDay: '[Today] [at] HH:mm', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow] [at] HH:mm', // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'll', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday] [at] HH:mm', // The day before ( Yesterday at 2:30 AM )
      lastWeek: 'll', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'MMM D, YYYY' // Everything else ( 7/10/2011 )
    })
  }

  date.utcToLocal = utcString => {
    return date.utc(utcString).local().toISOString()
  }

  date.localToUtc = localTimeString => {
    return date(localTimeString).utc().toISOString()
  }

  date.msToTime = duration => {
    const seconds = Math.floor((duration / 1000) % 60)
    const minutes = Math.floor((duration / (1000 * 60)) % 60)
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    return {
      hours,
      minutes,
      seconds
    }
  }

  // Add to Nuxt context as $date
  inject('date', dayjs)
}
