import { storePersist } from '~/lib/storePersist'
import { isNative } from '~/plugins/native/capacitor'

function constructAdminRoute(app) {
  if (app.isStaffAdvisor || app.isStaffTechnician) {
    return '/admin/access-organisations'
  }

  return '/admin'
}

export default async function ({ $log, $ability, store, route, $native, $network, req, redirect }) {
  // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
  if (route.path.includes('/maintenance')) {
    $native.hideSplashScreen()

    return
  }

  if (route.query.accessToken) {
    $log.debug('Auth: Access token in URL')
    store.dispatch('auth/handleAccessToken', route.query.accessToken)
    store.commit('auth/setSocialProviderRedirectingTo', null)

    return redirect({ ...route, query: { ...route.query, accessToken: undefined } })
  }

  let app = store.getters['app/getApp']

  if (!app.isClientLoaded && isNative) {
    await storePersist.load(store)
    await store.dispatch('auth/loadExistingTokens')
  }

  if (!app.isReady) {
    try {
      const pingResponse = await $network.checkConnection(true)

      // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
      if (pingResponse.isMaintenanceModeEnabled) {
        return
      }
    } catch (error) {
      // Stub - internet offline
      $log.debug('Internet offline during boot')
    }

    if (!isNative) {
      await store.dispatch('auth/loadExistingTokens')
      await store.dispatch('auth/loadExistingOrganisationId')
    }

    await store.dispatch('app/fetchStatus')

    // Re-fetch the app in case it was updated
    app = store.getters['app/getApp']
  }

  // Have we fetched our system status yet? Usually page load only
  // On native we cache the org details for a faster startup time
  if (!app.isReady) {
    await store.dispatch('app/fetchStatus')

    app = store.getters['app/getApp']
  }

  // Don't change the status bar colour if we are still on the login page
  if (isNative && app.isAuth) {
    store.dispatch('device/changeStatusBarColorPrimary', { root: true })
  }

  $native.hideSplashScreen()

  const authedPaths = ['/platform', '/admin']

  // If user isn't logged in redirect them to login page if the current path isn't a guest path
  // Don't log out if offline!
  if (app.isOnline && !app.isAuth && authedPaths.find(authPath => route.path.startsWith(authPath))) {
    store.commit('navigation/setUnauthenticatedPath', route.fullPath)

    return redirect('/auth/logout')
  }

  if (app.isAuth && store.state.navigation.originalUnauthenticatedPath) {
    // If the user was previously trying to get somewhere let's redirect them to that path
    const originalPath = store.state.navigation.originalUnauthenticatedPath

    store.commit('navigation/setUnauthenticatedPath', null)

    return redirect(originalPath)
  }

  // Admin visiting dashboard by accident
  if (route.path.startsWith('/platform') && app.isAuth && app.isStaffOrganisation) {
    return redirect(constructAdminRoute(app))
  }

  // User visiting admin
  if (route.path.startsWith('/admin') && app.isAuth && !app.isStaffOrganisation) {
    return redirect('/platform')
  }

  if (route.path === '/') {
    if (app.isAuth || (!app.isAuth && app.isNative)) {
      return app.isStaffOrganisation ? redirect(constructAdminRoute(app)) : redirect('/platform')
    }
  }
}
