import { ExchangeApi } from '~/services/api/exchange'

export class ReportingService extends ExchangeApi {
  async getAdminStats() {
    return await this.get(`/reporting/admin/stats`)
  }

  async getV2ReportSummary(farmId) {
    return await this.get(`/annual-report`)
  }

  async getReportSummary(farmId) {
    return await this.get(`/reporting/benchmark/summary?farmId=${farmId}`)
  }

  async downloadReport(fileType = 'excel') {
    return await this.get(`/annual-report/download/${fileType}`)
  }

  async getFarmProgress(farmId) {
    return await this.get(`/reporting/user/farm/${farmId}/progress`)
  }

  async getRawData(farmId, metricId) {
    if (!farmId) {
      return []
    }

    return await this.get(`/reporting/raw-data?farmId=${farmId}&metricId=${metricId}`)
  }

  async fetchBenchmarkData(farmId, metricId) {
    if (!farmId) {
      return []
    }

    return await this.get(`/reporting/benchmark/detail?farmId=${farmId}&metricId=${metricId}`)
  }

  async fetchIndustryScoreData(farmId, metricId) {
    if (!farmId) {
      return []
    }

    return await this.get(`/reporting/industry-scoring?farmId=${farmId}&metricId=${metricId}`)
  }
}
