
// The main goal of this component is to auto handle image loading states and
// provide easy access to aspect ratio/cover image needs

// Note due to nuxt-lazy-load all images/videos/iframes are lazy loaded by default
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'Img',

  props: {
    src: {
      type: String,
      default: ''
    },

    // User generated content
    fileRef: {
      type: String,
      default: ''
    },

    alt: {
      type: String,
      default: '',
      required: true
    },

    lazy: {
      type: Boolean,
      default: true
    },

    width: {
      type: Number,
      default: null
    },

    height: {
      type: Number,
      default: null
    },

    cover: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: false
    },

    errorImage: {
      type: String,
      default: require('~/assets/images/placeholders/image.png')
    }
  },

  data() {
    return {
      hasLoaded: false,
      hasFailedToLoad: false,
      faSpinner
    }
  },

  computed: {
    compImageObject() {
      return {
        src: this.compSrc,
        error: this.errorImage
      }
    },

    compSrc() {
      if (!this.width && !this.height) {
        return this.src
      }

      const url = new URL(this.src)

      if (this.width) {
        url.searchParams.append('width', this.width)
      }

      if (this.height) {
        url.searchParams.append('height', this.height)
      }

      url.searchParams.append('fit', 'crop')

      return url.toString()
    }
  },

  mounted() {
    this.$Lazyload.$on('loaded', this.handleLazyLoad)
  },

  beforeDestroy() {
    this.$Lazyload.$off('loaded', this.handleLazyLoad)
  },

  methods: {
    handleLoad() {
      this.hasFailedToLoad = false
      this.hasLoaded = true
      this.$emit('loaded')
    },

    handleLazyLoad({ _el, src }) {
      if (src === this.compSrc) {
        this.hasLoaded = true
      }
    },

    handleError() {
      this.hasFailedToLoad = true
      this.$emit('failed')
    }
  }
}
