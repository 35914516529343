import axios from 'axios'
import { isEqual } from 'lodash'

import { isNative } from '~/plugins/native/capacitor'

// On the client we want to check if we are online every x seconds
// We do this to check for new notification or global system messages
export default function ({ store, $log, app }, inject) {
  const network = {}
  let offlineRetryTimer = null
  let maintenanceModeRetryTimer = null
  let lastCheckedNetworkTimestamp = 0

  // Extra automated listeners for browser online/offline events to re-trigger our checks
  // Since we only check before/after API calls by default
  window.addEventListener('online', () => {
    $log.debug('Browser online event')
    network.checkConnection(true)
  })

  window.addEventListener('offline', () => {
    $log.debug('Browser offline event')
    network.checkConnection(true)
  })

  if (isNative) {
    const setupNativeEventListeners = async () => {
      const { Network } = await import('@capacitor/network')

      Network.addListener('networkStatusChange', status => {
        $log.debug('Native network event', status)

        network.checkConnection(true)
      })
    }

    setupNativeEventListeners()
  }

  network.checkConnection = async function (force = false) {
    let isMaintenanceModeEnabled = false
    const secondsSinceLastCheck = Math.ceil((Date.now() - lastCheckedNetworkTimestamp) / 1000)

    // force allows us to skip the timer lockout
    if (secondsSinceLastCheck < 10 && !force) {
      return
    }

    lastCheckedNetworkTimestamp = Date.now()

    try {
      const pingResult = await axios.get(`${process.env.PING_URL}/?time=${Date.now()}`, {
        timeout: 5000
      })

      if (typeof pingResult.data?.maintenanceMode?.enabled !== 'undefined') {
        // No need to keep hammering store mutations if nothing has changed
        if (!isEqual(pingResult.data.maintenanceMode, store.state.app.maintenanceMode)) {
          if (pingResult.data.maintenanceMode.enabled && !pingResult.data.maintenanceMode.bypassed) {
            $log.warn('Entering maintenance mode', maintenanceModeRetryTimer)

            isMaintenanceModeEnabled = true

            app.router.push('/maintenance')

            // Once offline has been detected, let's check every x seconds since the
            // online / offline events aren't reliable enough
            if (!maintenanceModeRetryTimer) {
              maintenanceModeRetryTimer = setInterval(network.checkConnection, 5000)
            }
          } else if (maintenanceModeRetryTimer) {
            // If we were previously polling for a connection while in maintenance mode then let's clear that timer
            clearInterval(maintenanceModeRetryTimer)

            app.router.push('/')
          }

          store.commit('app/setMaintenanceMode', pingResult.data.maintenanceMode)
        }
      }

      // If we were previously offline and the app hadn't loaded let's call /status
      if (!store.state.device.network.isOnline && !store.state.app.hasFetchedInitialStatus) {
        $log.debug('App started offline, now online, fetching system status')
        store.dispatch('app/fetchStatus')
      }

      // No need to keep hammering store mutations if nothing has changed
      if (!store.state.device.network.isOnline) {
        store.commit('device/setIsOnline', true)

        const organisation = store.getters['auth/getCurrentOrganisation']

        store.dispatch('app/populateStoresForOrganisation', organisation, { root: true })
      }

      // If we were previously polling for a connection while offline then let's clear that timer
      if (offlineRetryTimer) {
        clearInterval(offlineRetryTimer)
      }

      return { isMaintenanceModeEnabled }
    } catch (error) {
      $log.warn('Internet is offline', error)

      // Once offline has been detected, let's check every x seconds since the
      // online / offline events aren't reliable enough
      if (!offlineRetryTimer) {
        offlineRetryTimer = setInterval(network.checkConnection, 5000)
      }

      // No need to keep hammering store mutations if nothing has changed
      if (store.state.device.network.isOnline) {
        store.commit('device/setIsOnline', false)
      }
    }
  }

  // Inject into Nuxt context as $network
  inject('network', network)
}
