import { GraphEdge } from './graphEdge.js'

/**
 * Represents a store for edges in a vertex of a graph
 */
export class EdgeStore {
  /**
   * Maps a target vertex key to the edge
   * @type {Map<number|string,GraphEdge>}
   * @private
   */
  _edgeMap

  constructor() {
    this._edgeMap = new Map()
  }

  /**
   * Returns an array of all the edges in the store
   * There is a store for each vertex
   *
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {GraphEdge[]} - An array containing all the edges from the vertex that ows this store
   */
  getAllEdges(edgeDataFilterFn) {
    const returnEdges = []
    for (const edge of this._edgeMap.values()) {
      if (!edgeDataFilterFn || edgeDataFilterFn(edge.edgeData)) {
        returnEdges.push(edge)
      }
    }
    return returnEdges
  }

  /**
   * Retrieves the edge that connects the vertex owning this store to the specified target vertex.
   *
   * @param {GraphVertex} targetVertex - The target vertex to retrieve the edge for.
   * @return {GraphEdge} - The edge that connects the owner of this store to the specified target vertex,
   *                       or undefined if no such edge exists.
   */
  getEdge(targetVertex) {
    return this._edgeMap.get(targetVertex.getVertexKey())
  }

  /**
   * Adds an edge to this store between the source and target vertices.
   *
   * @param {GraphVertex} sourceVertex - The source vertex of the edge.
   * @param {GraphVertex} targetVertex - The target vertex of the edge.
   * @param {*} edgeData - The data associated with the edge.
   */
  addEdge(sourceVertex, targetVertex, edgeData) {
    const edge = new GraphEdge(sourceVertex, targetVertex, edgeData)
    this._edgeMap.set(targetVertex.getVertexKey(), edge)
  }

  /**
   * Removes an edge from this store
   *
   * @param {GraphVertex} targetVertex - The vertex to remove the edge to.
   * @return {undefined}
   */
  removeEdge(targetVertex) {
    this._edgeMap.delete(targetVertex.getVertexKey())
  }
}

/**
 * Represents a layered store for edges belonging to a vertex in a graph
 */
export class LayeredEdgeStore {
  /**
   * A mapping from the layerId to a mapping from targetVertex to an edge
   * @type {Map<number|string, Map<number|string,GraphEdge>>}
   * @private
   */
  _edgeLayerMap

  constructor() {
    this._edgeLayerMap = new Map()
  }

  /**
   * Returns an array of all the edges in the store
   * There is a store for each vertex
   *
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {GraphEdge[]} - An array containing all the edges from the vertex that ows this store
   */
  getAllEdges(edgeDataFilterFn) {
    let edges = []
    for (const layerId of this._edgeLayerMap.keys()) {
      for (const edge of this._edgeLayerMap.get(layerId).values()) {
        if (!edgeDataFilterFn || edgeDataFilterFn(edge.edgeData)) {
          edges.push(edge)
        }
      }
    }
    return edges
  }

  /**
   * Returns a list of all the edges from the vertex.
   * Limits to edges from this vertex owning this store to target vertices in given list of layerId's
   *
   * @param {Array<string|number>} targetLayerIds - An array of layer IDs for which edges are to be retrieved.
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {Array<GraphEdge>}} - An array containing all the edges for the specified layer IDs.
   */
  getAllEdgesForLayers(targetLayerIds, edgeDataFilterFn) {
    let edges = []
    for (const targetLayerId of targetLayerIds) {
      const layer = this._edgeLayerMap.get(targetLayerId)
      if (layer) {
        for (const edge of layer.values()) {
          if (!edgeDataFilterFn || edgeDataFilterFn(edge.edgeData)) {
            edges.push(edge)
          }
        }
      }
    }
    return edges
  }

  /**
   * Retrieves the edge that connects the vertex owning this store to the specified target vertex.
   *
   * @param {LayeredGraphVertex} targetVertex - The target vertex to retrieve the edge for.
   * @return {GraphEdge} - The edge that connects the owner of this store to the specified target vertex,
   *                       or undefined if no such edge exists.
   */
  getEdge(targetVertex) {
    return this._edgeLayerMap.get(targetVertex.getLayerId()).get(targetVertex.getFeatureId())
  }

  /**
   * Adds an edge to this store between the source and target vertices.
   *
   * @param {LayeredGraphVertex} sourceVertex - The source vertex of the edge.
   * @param {LayeredGraphVertex} targetVertex - The target vertex of the edge.
   * @param {*} edgeData - The data associated with the edge.
   */
  addEdge(sourceVertex, targetVertex, edgeData) {
    const layerId = targetVertex.getLayerId()
    if (!this._edgeLayerMap.has(layerId)) {
      this._edgeLayerMap.set(layerId, new Map())
    }
    const edge = new GraphEdge(sourceVertex, targetVertex, edgeData)
    this._edgeLayerMap.get(layerId).set(targetVertex.getFeatureId(), edge)
  }

  /**
   * Removes an edge from this store
   *
   * @param {LayeredGraphVertex} targetVertex - The vertex to remove the edge to.
   * @return {undefined}
   */
  removeEdge(targetVertex) {
    const layerId = targetVertex.getLayerId()
    this._edgeLayerMap.get(layerId).delete(targetVertex.getFeatureId())
  }
}
