import { ExchangeApi } from '~/services/api/exchange'

export class ContentService extends ExchangeApi {
  async getAllTags() {
    await this.get(`/content/tags`)
  }

  async createPrimary() {
    await this.post(`/content`)
  }

  async createFunding() {
    await this.post(`/content/funding`)
  }

  async getById(id) {
    await this.get(`/content/${id}`)
  }

  async getByFundingId(id) {
    await this.get(`/content/funding/${id}`)
  }

  async update(id) {
    await this.put(`/content/${id}`)
  }

  async unpublish(id) {
    await this.put(`/content/${id}`, {
      ...this.state.model,
      isPublished: false
    })
  }

  async publish(id) {
    await this.put(`/content/${id}`, {
      ...this.state.model,
      isPublished: true
    })
  }

  async updateFunding(id) {
    await this.put(`/content/funding/${id}`)
  }

  async unpublishFunding(id) {
    await this.put(`/content/funding/${id}`, {
      ...this.state.model,
      isPublished: false
    })
  }

  async publishFunding(id) {
    await this.put(`/content/funding/${id}`, {
      ...this.state.model,
      isPublished: true
    })
  }

  async fetchFundingAutocomplete() {
    await this.get(`/content/funding/autocomplete`)
  }

  async fetchTotals() {
    await this.get(`/content/totals`)
  }

  async fetchActiveFunding() {
    await this.get(`/content/funding/active`)
  }

  async fetchActiveArticles() {
    return await this.get(`/content/articles/active`)
  }

  async fetchActiveArticlesByTags(tags) {
    return await this.get(`/content/articles/active/search/tags/${tags}`)
  }
}
