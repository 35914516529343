import { verticesWithinDistance } from './graphProximityFunctions.js'
import { ContextualDataDefinitionContainer } from './contextualDataDefinitionContainer.js'

/**
 * Contains contextual data proximity graph and layer information
 * Has utility functions to query the dataset
 */
export class ContextualDataContainer {
  /**
   * @type LayeredNonDirectedGraph
   * @private
   */
  _contextualDataProximityGraph

  /**
   *
   * @type ContextualDataDefinitionContainer
   * @private
   */
  _contextualDataDefinitionContainer

  /**
   *
   * @param {LayeredNonDirectedGraph} contextualDataProximityGraph
   * @param {ContextualDataDefinitionContainer} contextualDataDefinitionContainer
   */
  constructor(contextualDataProximityGraph, contextualDataDefinitionContainer) {
    this._contextualDataProximityGraph = contextualDataProximityGraph
    this._contextualDataDefinitionContainer = contextualDataDefinitionContainer
  }

  /**
   *
   * @returns {LayeredNonDirectedGraph}
   */
  getContextualDataProximityGraph() {
    return this._contextualDataProximityGraph
  }

  /**
   *
   * @returns {ContextualDataDefinitionContainer}
   */
  get contextualDataDefinitionContainer() {
    return this._contextualDataDefinitionContainer
  }
}
