import { JournalContainer } from 'exchange-common/journal/journalContainer.js'

// import { JournalEntryType, JournalSaveManifestType, JournalSaveType, JournalType } from '#services/journal/types'
import { buildMapModificationActions } from 'exchange-common/utility/dataHandling/listComparison.js'

/**
 *
 * @param {JournalEntryType} a
 * @param {JournalEntryType} b
 * @returns {boolean}
 */
export function isJournalEntryEqual(a, b) {
  return a.journalNote === b.journalNote && a.id === b.id && a.fileData?.fileId === b.fileData?.fileId
}

/**
 * Processes initial and current journal containers, identifying new, updated,
 * and deleted journal entries, and returns a manifest of entries to save or delete journalEntries.
 *
 * @param {JournalContainer} initialJournalContainer - The initial state of the journal container.
 * @param {JournalContainer} currentJournalContainer - The current state of the journal container.
 * @return {Object} An object containing lists of journal entries to be saved and IDs of entries to be deleted.
 */
function getJournalEntryManifestData(initialJournalContainer, currentJournalContainer) {
  // Map<string, JournalEntryType>
  const initialJournalEntriesMap = new Map()
  // Map<string, JournalEntryType>
  const currentJournalEntriesMap = new Map()

  if (initialJournalContainer) {
    initialJournalContainer.getJournalEntries().forEach(entry => {
      initialJournalEntriesMap.set(entry.id, entry)
    })
  }

  if (currentJournalContainer) {
    currentJournalContainer.getJournalEntries().forEach(entry => {
      currentJournalEntriesMap.set(entry.id, entry)
    })
  }

  const currentJournalEntries = currentJournalContainer.getJournalEntries()

  const { newIds, removedIds, updatedIds } = buildMapModificationActions(
    {
      currentMap: initialJournalEntriesMap,
      desiredMap: currentJournalEntriesMap
    },
    isJournalEntryEqual
  )

  //: JournalEntryType[]
  const journalEntriesToSave = []

  // Handle new journal entries
  for (const journalEntry of currentJournalEntries) {
    if (newIds.includes(journalEntry.id)) {
      journalEntriesToSave.push({
        ...journalEntry,
        fileId: journalEntry.fileData?.fileId,
        fileData: undefined,
        id: null
      })
    }
  }

  // Handle updated journal entries
  for (const journalEntry of currentJournalEntries) {
    if (updatedIds.includes(journalEntry.id)) {
      journalEntriesToSave.push({
        ...journalEntry,
        fileId: journalEntry.fileData?.fileId || null, // this needs to be null if there is no fileData so that api will remove the fileId when saved
        fileData: undefined
      })
    }
  }

  // Handle deleted journal entries
  const journalEntryIdsToDelete = removedIds
  return { journalEntriesToSave, journalEntryIdsToDelete }
}

/**
 * Processes and retrieves the journal manifest data based on the initial and current journal containers.
 *
 * @param {JournalContainer} initialJournalContainer - The initial state of the journal container.
 * @param {JournalContainer} currentJournalContainer - The current state of the journal container.
 * @return {Object} The journal manifest data containing the journal and the modification status.
 */
function getJournalManifestData(initialJournalContainer, currentJournalContainer) {
  //: JournalType
  let currentJournal = undefined
  //: boolean
  let journalIsModified
  //: string | null
  let journalIdToDelete = null

  if (initialJournalContainer === undefined) {
    if (currentJournalContainer === undefined) {
      // No current journal or initial journal, so it has not been modified
      journalIsModified = false
    } else {
      currentJournal = currentJournalContainer.getJournal()
      if (currentJournalContainer.getJournalEntries().length === 0) {
        // New journal with no journal entries
        journalIsModified = false
      } else {
        // New journal with one or more journal entries
        journalIsModified = true
      }
    }
  } else {
    // We have an initial journal
    if (currentJournalContainer) {
      // We have an initial journal and a current journal

      // We will probably need an equality check here to determine if the journal has changed
      // Currently we have no properties on a journal, so always false modified state
      journalIsModified = false
      currentJournal = currentJournalContainer.getJournal()
    } else {
      // No current journal, so must be a delete of the journal
      journalIsModified = false
      journalIdToDelete = initialJournalContainer.getJournal().id
    }
  }
  return { currentJournal, journalIsModified, journalIdToDelete }
}

/**
 * Builds a manifest object representing the changes to be saved between the initial and current journal containers.
 *
 * @param {JournalContainer} initialJournalContainer - The initial state of the journal container.
 * @param {JournalContainer} currentJournalContainer - The current state of the journal container.
 * @return {{ journalIdToDelete: string | null; journalSaveManifest: JournalSaveType }} A manifest object containing the lists of journal IDs to be deleted and journals to be saved.
 */
export function buildSaveManifestForSingleJournal(initialJournalContainer, currentJournalContainer) {
  let journalSaveManifest = null

  const { currentJournal, journalIsModified, journalIdToDelete } = getJournalManifestData(
    initialJournalContainer,
    currentJournalContainer
  )

  if (currentJournal !== undefined) {
    const { journalEntriesToSave, journalEntryIdsToDelete } = getJournalEntryManifestData(
      initialJournalContainer,
      currentJournalContainer
    )

    if (journalEntriesToSave.length > 0 || journalEntryIdsToDelete.length > 0 || journalIsModified) {
      journalSaveManifest = {
        journal: currentJournal,
        _isModified: journalIsModified,
        _journalEntriesToSave: journalEntriesToSave,
        _journalEntryIdsToDelete: journalEntryIdsToDelete
      }
    }
  }

  return { journalIdToDelete, journalSaveManifest }
}

/**
 * Builds a save manifest for the given journal container pairs.
 *
 * @param {Array<{ initialJournalContainer: JournalContainer, currentJournalContainer: JournalContainer }>} journalContainerPairs - An array of pairs of initial and current journal containers.
 * @return {JournalSaveManifestType} The save manifest containing ids to delete and journals to save.
 */
export function buildSaveManifest(journalContainerPairs) {
  // Empty manifest
  const manifest = {
    journalIdsToDelete: [],
    journalsToSave: []
  }

  for (const journalContainerPair of journalContainerPairs) {
    const { journalIdToDelete, journalSaveManifest } = buildSaveManifestForSingleJournal(
      journalContainerPair.initialJournalContainer,
      journalContainerPair.currentJournalContainer
    )
    if (journalIdToDelete) {
      manifest.journalIdsToDelete.push(journalIdToDelete)
    }
    if (journalSaveManifest) {
      manifest.journalsToSave.push(journalSaveManifest)
    }
  }

  return manifest
}
