import reduce from 'image-blob-reduce'

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const shrinkImageBlob = async (blobIn, maxDimension = 2000) => {
  const reducer = reduce()

  try {
    // return blobIn
    return await reducer.toBlob(blobIn, { max: maxDimension, alpha: true })
  } catch (error) {
    throw new Error(error)
  }
}

export const blobToArrayBuffer = async blob => {
  return await new Response(blob).arrayBuffer()
}

export const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result.toString())

    reader.onerror = error => reject(error)

    reader.readAsDataURL(blob)
  })
}

export const base64ToArrayBuffer = base64 => {
  return Uint8Array.from(atob(base64), c => c.charCodeAt(0))
}

export const base64ToBlob = base64String => {
  // Decode the base64 string
  const byteString = atob(base64String.split(',')[1])

  // Define the MIME type
  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0]

  // Create an array for the byte values
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const intArray = new Uint8Array(arrayBuffer)

  // Set the byte values to the array
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i)
  }

  // Create a `Blob` with the byte values
  return new Blob([arrayBuffer], { type: mimeString })
}
