/**
 * Compares the desiredMap and currentMap and returns keys that indicate newIds, updatedIds, removedIds
 * It returns the modifications that should be applied to the currentMap to make it equal the desiredMap
 * NOTE: There is a typescript version of this in server/utilities/dataHandling
 * TODO: When converting to typescript use the server version of this file, and update the server to use the common version
 * @param dataMaps
 * @param equalityFunction Compares a,b and returns true if considered to be equal
 */
export function buildMapModificationActions(dataMaps, equalityFunction) {
  const { desiredMap, currentMap } = dataMaps
  const newIdSet = new Set()
  const updatedIdSet = new Set()
  const removedIdSet = new Set()

  // Check for new and updated parcels
  for (const [sourceKey, sourceData] of desiredMap.entries()) {
    const destinationData = currentMap.get(sourceKey)
    if (destinationData) {
      // Data exists in the destination
      // Run the comparison function to determine if it has changed
      if (!equalityFunction(sourceData, destinationData)) {
        updatedIdSet.add(sourceKey)
      }
    } else {
      // Destination does not include the source id
      newIdSet.add(sourceKey)
    }
  }

  // Check from removed parcels
  for (const key of currentMap.keys()) {
    if (!desiredMap.has(key)) {
      // Missing from the sourceMap, so add to remove list
      removedIdSet.add(key)
    }
  }

  return {
    newIds: [...newIdSet],
    updatedIds: [...updatedIdSet],
    removedIds: [...removedIdSet]
  }
}
