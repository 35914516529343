import { GraphVertex } from './graphVertex.js'
import { GraphEdge } from './graphEdge.js'
import { LayeredVertexStore, VertexStore } from './vertexStore.js'

/**
 * Represents a non-directed graph.
 */
class NonDirectedGraphAbstract {
  /**
   * @type VertexStore
   * @private
   */
  _vertexStore

  /**
   * All edges will have the same edgeData structure
   * When the first edge is added to the graph, we record that structure for the export to json functionality
   * @type string[]
   * @private
   */
  _edgeDataPropertyNames

  /**
   * Is the graph directed
   * @type boolean
   * @private
   */
  _directed

  /**
   * Does the graph support layering of vertices
   * @type boolean
   * @private
   */
  _layered

  constructor(vertexStore, layered) {
    this._vertexStore = vertexStore
    this._edgeDataPropertyNames = null
    this._directed = false
    this._layered = layered
  }

  /**
   * Returns whether the graph is directed or layered
   * @returns {{directed: boolean, layered: boolean}}
   */
  getGraphStructure() {
    return { directed: this._directed, layered: this._layered }
  }

  /**
   * Retrieves the names of the data properties of the edge.
   *
   * @returns {Array<string>} An array containing the names of the data properties.
   */
  getEdgeDataPropertyNames() {
    if (!this._edgeDataPropertyNames) {
      return []
    }
    return this._edgeDataPropertyNames
  }

  /**
   * Get a list of all the vertices in the graph
   * @returns {Array<GraphVertex | LayeredGraphVertex>}
   */
  getAllVertices = () => {
    return this._vertexStore.getAllVertices()
  }

  /**
   * Get a list of all the edges in the graph
   * @returns {Array<GraphEdge>}
   */
  getAllEdges = () => {
    const edges = []
    for (const vertex of this.getAllVertices()) {
      const vertexEdges = vertex.getAllEdges()
      if (vertexEdges.length) {
        edges.push(...vertexEdges)
      }
    }
    return edges
  }

  /**
   * Remove a vertex from the graph
   * @param {GraphVertex | LayeredGraphVertex} vertex
   */
  removeVertex = vertex => {
    this._vertexStore.removeVertex(vertex)
  }

  /**
   * Adds an edge between the vertices.
   *
   * @param {GraphVertex | LayeredGraphVertex} vertex1 - vertex 1 of the edge.
   * @param {GraphVertex | LayeredGraphVertex} vertex2 - vertex 2 of the edge.
   * @param {any} edgeData - The data associated with the edge.
   *
   * @returns {undefined}
   */
  addEdge = (vertex1, vertex2, edgeData) => {
    // This is a non-directed graph, so add edge in both directions
    vertex1.addEdge(vertex2, edgeData)
    vertex2.addEdge(vertex1, edgeData)

    // All edgeData should have the same property names, we record this on the first edge added to the graph
    if (!this._edgeDataPropertyNames) {
      this._edgeDataPropertyNames = Object.keys(edgeData)
    }
  }

  /**
   * Removes any edges between two vertices
   *
   * @param {GraphVertex | LayeredGraphVertex} vertex1 - The first vertex of the edge.
   * @param {GraphVertex | LayeredGraphVertex} vertex2 - The second vertex of the edge.
   * @returns {void}
   */
  removeEdge = (vertex1, vertex2) => {
    // This is a non-directed graph, so remove the edge in both directions
    vertex1.removeEdge(vertex2)
    vertex2.removeEdge(vertex1)
  }

  /**
   * Determines if an edge exists between vertex1 and vertex2
   * The edge may in either direction
   *
   * @param {GraphVertex | LayeredGraphVertex} vertex1 - The first vertex.
   * @param {GraphVertex | LayeredGraphVertex} vertex2 - The second vertex.
   * @returns {boolean} - True if the vertices are adjacent, false otherwise.
   */
  adjacent = (vertex1, vertex2) => {
    if (vertex1.getEdge(vertex2)) {
      return true
    } else if (vertex2.getEdge(vertex1)) {
      return true
    }
    return false
  }
}

/**
 * A non-directed graph
 */
export class NonDirectedGraph extends NonDirectedGraphAbstract {
  constructor() {
    super(new VertexStore(), false)
  }

  /**
   * Adds a vertex to the graph.
   *
   * @param {string|number} vertexKey - The unique identifier of the vertex.
   * @param {object} vertexData - The data associated with the vertex.
   * @return {GraphVertex} - The added vertex.
   */
  addVertex = (vertexKey, vertexData) => {
    return this._vertexStore.addVertex(vertexKey, vertexData)
  }

  /**
   * Retrieves the vertex associated with the specified vertexKey.
   *
   * @param {string|number} vertexKey - The unique identifier of the vertex.
   * @returns {GraphVertex} - The vertex object associated with the vertexKey.
   */
  getVertex = vertexKey => {
    return this._vertexStore.getVertex(vertexKey)
  }

  /**
   * Get the neighbors of a given vertex, along with the edge connection
   *
   * @param {GraphVertex} vertex - The vertex for which to get the neighbors.
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {Array<{vertex:GraphVertex, edgeData:any}>} - An array of vertices that are neighbors of the given vertex.
   */
  neighbors = (vertex, edgeDataFilterFn = undefined) => {
    const neighbors = []
    const edges = vertex.getAllEdges(edgeDataFilterFn)
    for (const edge of edges) {
      neighbors.push({ vertex: edge.targetVertex, edgeData: edge.edgeData })
    }
    return neighbors
  }
}

/**
 * A non-directed graph with layers
 * Vertices must have an associated layer
 * We can look for neighbors on particular layers
 */
export class LayeredNonDirectedGraph extends NonDirectedGraphAbstract {
  constructor() {
    super(new LayeredVertexStore(), true)
  }

  /**
   * Adds a vertex to a layer of the graph.
   *
   * @param {Array<string|number>} vertexKey - The unique key for the vertex. An array for [layerId, featureId]
   * @param {object} vertexData - The data associated with the vertex.
   * @return {LayeredGraphVertex} - The added vertex.
   */
  addVertex = (vertexKey, vertexData) => {
    return this._vertexStore.addVertex(vertexKey, vertexData)
  }

  /**
   * Retrieves the vertex associated with the specified vertexKey.
   *
   * @param {Array<string|number>} vertexKey - The unique key for the vertex. An array for [layerId, featureId]
   * @returns {LayeredGraphVertex} - The vertex object associated with the vertexKey.
   */
  getVertex = vertexKey => {
    return this._vertexStore.getVertex(vertexKey)
  }

  /**
   * Get a list of all the vertices in the graph
   *
   * @param {Array<string | number>} targetLayerIds - An array of layer IDs for which edges are to be retrieved.
   * @returns {Array<GraphVertex | LayeredGraphVertex>}
   */
  getAllVerticesWithinLayers = targetLayerIds => {
    return this._vertexStore.getAllVerticesWithinLayers(targetLayerIds)
  }

  /**
   * Get the neighbors of a given vertex, along with the edge connection
   *
   * @param {LayeredGraphVertex} vertex - The vertex for which to get the neighbors.
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {Array<{vertex:LayeredGraphVertex, edgeData:any}>} - An array of vertices that are neighbors of the given vertex.
   */
  neighbors = (vertex, edgeDataFilterFn = undefined) => {
    const neighbors = []
    const edges = vertex.getAllEdges(edgeDataFilterFn)
    for (const edge of edges) {
      neighbors.push({ vertex: edge.targetVertex, edgeData: edge.edgeData })
    }
    return neighbors
  }

  /**
   * Get the neighbors of a given vertex, along with the edge connection
   * Limit neighbors to vertices in the supplied layer ids
   *
   * @param {LayeredGraphVertex} vertex - The vertex for which to get the neighbors.
   * @param {Array<string | number>} targetLayerIds - An array of layer IDs for which edges are to be retrieved.
   * @param edgeDataFilterFn - A function that takes the edgeData of an edge and returns true if that edge should be included in the output
   * @returns {Array<{vertex:LayeredGraphVertex, edgeData:any}>} - An array of vertices that are neighbors of the given vertex.
   */
  neighborsWithinLayers = (vertex, targetLayerIds, edgeDataFilterFn = undefined) => {
    const neighbors = []
    const edges = vertex.getAllEdgesForLayers(targetLayerIds, edgeDataFilterFn)
    for (const edge of edges) {
      neighbors.push({ vertex: edge.targetVertex, edgeData: edge.edgeData })
    }
    return neighbors
  }
}
