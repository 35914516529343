import { testAlbumWritePermissions } from '~/lib/photos/photoAlbum'
import { storePersist } from '~/lib/storePersist'
import { isNative, loadCapacitor, platform } from '~/plugins/native/capacitor'

import('@capacitor/core')

// We don't want capacitor code running server side so we only execute this client side, if runnning native app
export default async function ({ store, app, $log }, inject) {
  await loadCapacitor()

  let launchRedirectRoute = null

  if (isNative) {
    store.dispatch('device/changeStatusBarColorPrimary', { root: true })

    const { App } = await import('@capacitor/app')

    // Handle deep links (universal links) coming into the app and redirect appropriately
    App.addListener('appUrlOpen', async openListenerEvent => {
      $log.debug('Deep link launch:', openListenerEvent.url)

      try {
        const url = new URL(openListenerEvent.url)
        const params = url.searchParams

        if (url?.href?.startsWith(process.env.NATIVE_CALLBACK_URL)) {
          $log.debug('Auth: Handling auth callback')

          store.commit('auth/setSocialProviderRedirectingTo', null)

          const accessToken = params.get('accessToken')

          if (accessToken) {
            await store.dispatch('auth/handleAccessToken', accessToken)

            await store.dispatch('app/fetchStatus')

            $log.debug('Auth: Native user logged in via social')

            return app.router.push('/platform')
          }
        }

        const route = { path: url.pathname + url.search }

        if (store.state.app.isClientLoaded) {
          app.router.push(route)
        } else {
          launchRedirectRoute = route
        }
      } catch (error) {
        $log.error(`Failed to launch deep link - ${openListenerEvent.url}`, error)
      }
    })

    window.onNuxtReady(() => {
      $log.debug('Native Nuxt ready')

      if (launchRedirectRoute) {
        app.router.push(launchRedirectRoute)
      }

      store.dispatch('organisations/checkPopulatedOrganisations')
    })

    storePersist.setup(store)
  }

  const { SecureStoragePlugin } = await import('capacitor-secure-storage-plugin')

  // Generic small native functions
  const native = {
    openAppSettings: async () => {
      const { NativeSettings, AndroidSettings, IOSSettings } = await import('capacitor-native-settings')

      $log.debug('Opening native app settings', platform)

      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      })
    },

    /**
     * Asynchronously retrieves the current state of permissions for geolocation and camera access,
     * and returns an object containing the boolean status of each permission.
     *
     * Note: A null permission indicates permission not yet requested
     *
     * Note: On Android, a 'saveGallery' permission is provided, but this is not available on iOS,
     * resulting in 'saveGallery' being set to null for iOS devices.
     *
     * @returns {Promise<Object>} A promise that resolves to an object with the following properties:
     * - location: {boolean | null} - The permission status for geolocation. null indicates permission not yet requested
     * - camera: {boolean | null} - The permission status for camera access. null indicates permission not yet requested
     * - photos: {boolean | null} - The permission status for accessing photos. null indicates permission not yet requested
     * - saveGallery: {boolean | null} - The permission status for saving to the gallery. null indicates permission not yet requested
     */
    getCurrentPermissions: async requiredPermissions => {
      const getBooleanPermission = permissionText => {
        if (permissionText === 'granted') {
          return true
        } else if (permissionText === 'denied') {
          return false
        }

        return null
      }
      const requiredPermissionsMap = new Map()

      for (const permission of requiredPermissions) {
        requiredPermissionsMap.set(permission.permissionType, permission)
      }

      const geoLocationPlugin = await import('@capacitor/geolocation')
      const geoLocationPermissions = await geoLocationPlugin.Geolocation.checkPermissions()

      $log.debug('geoLocationPermissions', geoLocationPermissions)

      // Camera permissions
      // NOTE: Android supplies a 'saveGallery' permission, but iOS does not, so saveGallery will be set to null
      // NOTE on iOS the photos permission is set to 'limited' and this does not give access to write a gallery, but 'granted appear to'
      const cameraPlugin = await import('@capacitor/camera')
      const cameraPermissions = await cameraPlugin.Camera.checkPermissions()

      $log.debug('cameraPermissions', cameraPermissions)

      // const capacitorCore = await import('@capacitor/core')
      // const { Plugins } = capacitorCore
      // const { Permissions } = Plugins

      const currentPermissionsMap = new Map()

      currentPermissionsMap.set('location', getBooleanPermission(geoLocationPermissions.location))
      currentPermissionsMap.set('camera', getBooleanPermission(cameraPermissions.camera))
      currentPermissionsMap.set('photos', getBooleanPermission(cameraPermissions.photos))
      currentPermissionsMap.set('saveGallery', getBooleanPermission(cameraPermissions.saveGallery))

      $log.debug('permissions', currentPermissionsMap)

      let satisfiesEssentialPermissions = true
      let satisfiesNonEssentialPermissions = true

      for (const permissionKey of requiredPermissionsMap.keys()) {
        if (currentPermissionsMap.get(permissionKey) === false) {
          if (requiredPermissionsMap.get(permissionKey).essentialPermission) {
            satisfiesEssentialPermissions = false
          } else {
            satisfiesNonEssentialPermissions = false
          }
        }

        if (currentPermissionsMap.get(permissionKey) === null) {
          if (requiredPermissionsMap.get(permissionKey).essentialPermission) {
            satisfiesEssentialPermissions = false
          } else {
            satisfiesNonEssentialPermissions = false
          }
        }
      }

      return { currentPermissionsMap, satisfiesEssentialPermissions, satisfiesNonEssentialPermissions }
    },

    /**
     * Requests permissions for various features such as location, camera, photos, and save to gallery.
     *
     * @param currentPermissionsMap
     * @param requiredPermissions
     *
     * @returns {Promise<void>} A promise that resolves when the permission requests are completed.
     */
    requestPermissions: async (currentPermissionsMap, requiredPermissions) => {
      const requiredPermissionsMap = new Map()

      for (const permission of requiredPermissions) {
        requiredPermissionsMap.set(permission.permissionType, permission)
      }

      $log.debug('Requesting permissions', requiredPermissionsMap, currentPermissionsMap)

      if (!currentPermissionsMap.get('location') && requiredPermissionsMap.get('location')) {
        const geoLocationPlugin = await import('@capacitor/geolocation')

        await geoLocationPlugin.Geolocation.requestPermissions()
      }

      const cameraRequest = []

      if (!currentPermissionsMap.get('camera') && requiredPermissionsMap.get('camera')) {
        cameraRequest.push('camera')
      }

      if (!currentPermissionsMap.get('photos') && requiredPermissionsMap.get('photos')) {
        cameraRequest.push('photos')
      }

      if (cameraRequest.length > 0) {
        const cameraPlugin = await import('@capacitor/camera')

        await cameraPlugin.Camera.requestPermissions({ permissions: cameraRequest })
      }

      if (!currentPermissionsMap.get('saveGallery') && requiredPermissionsMap.get('saveGallery')) {
        await testAlbumWritePermissions({})
      }
    },

    keepScreenAwake: async (keepAwake = true) => {
      const { KeepAwake } = await import('@capacitor-community/keep-awake')

      if (keepAwake) {
        await KeepAwake.keepAwake()
      } else {
        await KeepAwake.allowSleep()
      }
    },

    hideSplashScreen: async () => {
      if (isNative && !store.state.device.hasHiddenSplash) {
        const { SplashScreen } = await import('@capacitor/splash-screen')

        SplashScreen.hide()
        store.commit('device/setHasHiddenSplash')
      }
    },

    secureStorage: SecureStoragePlugin,

    vibrate: async impactStyle => {
      const { Haptics, ImpactStyle } = await import('@capacitor/haptics')

      if (impactStyle === 'notification') {
        // Several quick pulses
        await Haptics.notification()
      } else if (impactStyle) {
        // Short pulse
        const impactConfig = {
          light: ImpactStyle.Light,
          medium: ImpactStyle.Medium,
          heavy: ImpactStyle.Heavy
        }

        await Haptics.impact({ style: impactConfig[impactStyle] })
      } else {
        // Longer continuous pulse
        await Haptics.vibrate()
      }
    }
  }

  // Inject into Nuxt context as $native
  inject('native', native)
}
