import { dimensionLabels, dimensions, livestockDimensions } from 'exchange-common/data/dimensions'
import { noteTypes } from 'exchange-common/notes/noteTypes'
import { emptyStateReport } from 'exchange-common/report/report.js'
import { set } from 'vue'

import { ApiModel } from '~/plugins/api/model'

// function processScores(data, dimension) {
//   const dimensionData = data[dimension]
//   let identifier = ''

//   if (dimension === dimensions.BIODIVERSITY) {
//     identifier = 'name'
//   } else {
//     identifier = 'label'
//   }

//   let subDimensionData = dimensionData?.level1?.general || []

//   if (dimension === dimensions.WATER) {
//     subDimensionData = [...subDimensionData, ...(dimensionData?.level1?.detail || [])]
//   }

//   // Adding this here as it's a proxy for a score for carbon stored in soils
//   if (dimension === dimensions.CLIMATE_CHANGE) {
//     const somScore = (data[dimensions.SOILS]?.level1?.general || []).find(
//       item => item.label === 'Soil organic matter'
//     )

//     subDimensionData = [...subDimensionData, somScore]
//   }

//   return {
//     overall: dimensionData.overall,
//     subdimensions: subDimensionData.reduce((acc, item) => {
//       acc[item[identifier]] = item.score
//       return acc
//     }, {})
//   }
// }

const initialState = () => {
  return {
    fetchReportSummaryApi: new ApiModel(),
    fetchNoteApi: new ApiModel(),
    report: {},
    scores: {},
    dimensionNotes: {},
    summaryNote: null
  }
}

export const state = () => initialState()

export const getters = {
  summaryNote(state, getters) {
    return state.summaryNote
  },

  scores(state, getters) {
    return state.scores || {}
  },

  dimensionOverviews(state, getters, rootState, rootGetters) {
    const pageUrl = '/platform/report/v3'

    const dimensionUrls = {
      [dimensions.ANIMAL_WELFARE]: `${pageUrl}/animal-welfare`,
      [dimensions.CLIMATE_CHANGE]: `${pageUrl}/carbon`,
      [dimensions.BIODIVERSITY]: `${pageUrl}/biodiversity`,
      [dimensions.SOCIAL]: `${pageUrl}/social`,
      [dimensions.SOILS]: `${pageUrl}/soils`,
      [dimensions.WATER]: `${pageUrl}/water`
    }

    const scores = {}

    Object.values(dimensions).forEach(dimension => {
      scores[dimension] = {
        label: dimensionLabels[dimension],
        dimension,
        score: getters.scores?.[dimension]?.score || 0,
        isReady: getters.scores?.[dimension]?.score > 0,
        isHidden: livestockDimensions.includes(dimension) && !rootGetters['app/getApp'].isAnimalFarmType,
        missingData: [],
        maxScore: 100,
        to: dimensionUrls[dimension]
      }
    })

    return scores
  },

  dimensionScores: (state, getters) => dimension => {
    return getters.scores?.[dimension]
  },

  reportSummaries(state, getters) {
    return state.report
  },

  reportOrEmptyStateData: (state, getters) => dimension => {
    const report = getters.reportSummaries?.[dimension]

    // console.log({ report })

    if (report) {
      if (report?.extra?.ready) {
        return report
      } else {
        return emptyStateReport[dimension]
      }
    }

    return null
  },

  reportIsReady: (state, getters) => dimension => {
    return getters.reportSummaries?.[dimension]?.extra?.ready
  },

  dimensionReadyStatus(state, getters) {
    return Object.values(dimensions).reduce((acc, dimension) => {
      acc[dimension] = getters.reportIsReady(dimension)
      return acc
    }, {})
  },

  getNoteByDimension: (state, getters) => dimension => {
    return state.dimensionNotes?.[dimension]
  },

  fetchReportSummaryApi(state, getters) {
    return state.fetchReportSummaryApi
  },

  fetchNoteApi(state, getters) {
    return state.fetchNoteApi
  },

  hasScoreForEveryDimension(state, getters, rootState, rootGetters) {
    let applicableDimensions = Object.values(dimensions).filter(
      dimension => !livestockDimensions.includes(dimension)
    )

    if (rootGetters['app/getApp'].isAnimalFarmType) {
      applicableDimensions = Object.values(dimensions)
    }

    // Do we have a score for each applicable dimension
    return applicableDimensions.every(dimension => getters.scores[dimension]?.score !== null)
  },

  isReportLoading(state, getters) {
    return getters.fetchReportSummaryApi?.isLoading
  }
}

export const actions = {
  async getReportSummary({ commit, getters, rootGetters }, { onlyFetchIfEmpty = false } = {}) {
    if (!rootGetters['device/isOnline'] || getters.isReportLoading) {
      return
    }

    if (onlyFetchIfEmpty && Object.keys(getters.reportSummaries).length > 0) {
      return
    }

    if (!rootGetters['farm/farm'].id) {
      commit('setScores', { scores: {} })

      return
    }

    try {
      const { response } = await this.$api
        .reporting(getters.fetchReportSummaryApi)
        .useStorePath(`report-v3.fetchReportSummaryApi`)
        .getReportSummary(rootGetters['farm/farm'].id)

      commit('setScores', { scores: response.data.dimensionScores })
    } catch (error) {
      this.$log.error('Error fetching report summary scores', error)
    }
  },

  async getDimensionNotes({ commit, getters, rootGetters }) {
    if (!rootGetters['device/isOnline']) {
      return
    }

    const { response } = await this.$api
      .note(getters.fetchNoteApi)
      .useStorePath(`report-v3.fetchNoteApi`)
      .getAllByType(noteTypes.REPORT_DIMENSION_SUMMARY)

    if (response.data && response.data.meta) {
      commit('setNotes', { notes: response.data.notes, organisationId: response.data.meta.organisationId })
    }
  }
}

export const mutations = {
  setReport(state, { summary }) {
    set(state, 'report', summary)
  },

  setScores(state, { scores }) {
    // Benchmarking didn't use the dimensions config so we have several that need manually lining up as it comes from the API
    if (scores.animalWelfare) {
      scores[dimensions.ANIMAL_WELFARE] = scores.animalWelfare
      delete scores.animalWelfare
    }

    if (scores.carbon) {
      scores[dimensions.CLIMATE_CHANGE] = scores.carbon
      delete scores.carbon
    }

    if (scores.soil) {
      scores[dimensions.SOILS] = scores.soil
      delete scores.soil
    }

    set(state, 'scores', scores)
  },

  setNotes(state, { notes }) {
    const keyedNotes = {}

    notes.forEach(note => {
      keyedNotes[note.dimension] = note
    })

    set(state, 'dimensionNotes', keyedNotes)
  },

  setSummaryNote(state, { summaryNote, organisationId }) {
    set(state, 'summaryNote', summaryNote)
  },

  reset(state) {
    Object.assign(state, initialState())
  }
}
