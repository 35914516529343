
export default {
  name: 'AppLoader',

  mounted() {
    if (this.$app.isNative) {
      this.$store.dispatch('device/changeStatusBarColorPrimary')
    }
  },

  methods: {
    retryConnection() {
      this.$store.dispatch('app/fetchStatus')

      this.$analytics.addEvent('Clicked retry connection from splash screen')
    }
  }
}
