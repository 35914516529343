export class SfiAgreementStoreManagement {
  /**
   * @type {{sfiAgreementRepository:{sfiAgreementPairs: Array<{id:string,initialAgreement:any, currentAgreement:any }>}}}
   */
  state

  constructor(state) {
    this.state = state
  }

  getSfiAgreementList() {
    return this.state.sfiAgreementRepository.sfiAgreementPairs.map(
      sfiAgreementPair => sfiAgreementPair.currentAgreement
    )
  }

  getSfiAgreementObjectFromId(sfiAgreementId) {
    console.log('Get sfi agreement object from id', sfiAgreementId)
    const sfiAgreementPair = this.state.sfiAgreementRepository.sfiAgreementPairs.find(
      sfiAgreementPair => sfiAgreementPair.currentAgreement.sfiAgreement.id === sfiAgreementId
    )

    if (sfiAgreementPair) {
      console.log('Found sfi agreement pair', sfiAgreementPair.currentAgreement)
      return sfiAgreementPair.currentAgreement
    }

    return undefined
  }

  getSfiAgreementObjectFromKey(sfiAgreementKey) {
    console.log('Get sfi agreement object from key', sfiAgreementKey)
    const sfiAgreementPair = this.state.sfiAgreementRepository.sfiAgreementPairs.find(
      sfiAgreementPair => sfiAgreementPair.currentAgreement.sfiAgreement._key === sfiAgreementKey
    )

    if (sfiAgreementPair) {
      console.log('Found sfi agreement pair', sfiAgreementPair.currentAgreement)
      return sfiAgreementPair.currentAgreement
    }

    return undefined
  }
}
