import { ExchangeApi } from '~/services/api/exchange'

export class FundingService extends ExchangeApi {
  async getProgrammes() {
    await this.get('funding/programme')
  }

  async getProgrammeById(id) {
    await this.get(`funding/programme/${id}`)
  }

  async createProgramme() {
    await this.post('funding/programme')
  }

  async updateProgramme(id) {
    await this.put(`funding/programme/${id}`)
  }

  async deleteProgramme(id) {
    await this.delete(`funding/programme/${id}`)
  }

  async getPractices() {
    await this.get('funding/practice')
  }

  async getPracticeById({ id, decorate, includeExpiredSchemes, countries }) {
    let url = `funding/practice/${id}`

    if (decorate) {
      url += '?decorate=true'
    }

    if (includeExpiredSchemes) {
      url += '?includeExpiredSchemes=true'
    }

    if (countries) {
      url += `&countries=${countries}`
    }

    await this.get(url)
  }

  async createPractice() {
    await this.post('funding/practice')
  }

  async updatePractice(id) {
    await this.put(`funding/practice/${id}`)
  }

  async deletePractice(id) {
    await this.delete(`funding/practice/${id}`)
  }

  async createScheme() {
    await this.post('funding/scheme')
  }

  async getSchemeById(id) {
    await this.get(`funding/scheme/${id}`)
  }

  async getSchemes(queryParams) {
    await this.get('funding/scheme', queryParams)
  }

  async updateScheme(id) {
    await this.put(`funding/scheme/${id}`)
  }

  async deleteScheme(id) {
    await this.delete(`funding/scheme/${id}`)
  }

  async getSfiProgrammeAndSchemes() {
    await this.get('funding/sfi')
  }

  async getLandTypes() {
    await this.get('funding/land-type')
  }

  async getLandTypeById(id) {
    await this.get(`funding/land-type/${id}`)
  }

  async createLandType() {
    await this.post('funding/land-type')
  }

  async updateLandType(id) {
    await this.put(`funding/land-type/${id}`)
  }

  async deleteLandType(id) {
    await this.delete(`funding/land-type/${id}`)
  }

  async getBodies() {
    await this.get('funding/body')
  }

  async getBodyById(id) {
    await this.get(`funding/body/${id}`)
  }

  async createBody() {
    await this.post('funding/body')
  }

  async updateBody(id) {
    await this.put(`funding/body/${id}`)
  }

  async deleteBody(id) {
    await this.delete(`funding/body/${id}`)
  }

  async sendEnquiry() {
    await this.post('funding/enquiry')
  }

  async sendFeedback() {
    await this.post('funding/feedback')
  }
}
