import reportTextJson from './reportText.json'

export const reportText = reportTextJson

export const emptyStateReport = {
  soils: {
    overall: 2.573104140329541,
    level1: {
      general: [
        {
          label: 'Soil organic matter',
          score: 1.6
        },
        {
          label: 'Soil structure',
          score: 3.9988540349810373
        },
        {
          label: 'Soil pH',
          score: 2.8
        },
        {
          label: 'Bulk density',
          score: 3.4
        },
        {
          label: 'Earthworms',
          score: 1.0666666666666667
        }
      ],
      soc: [
        {
          label: 'Total soil carbon stock (t)',
          value: '23,267'
        },
        {
          label: 'Average carbon density (t/ha)',
          value: '52'
        }
      ]
    },
    level2: {
      zonationChart: [
        {
          zone: '1',
          parcelIds: '6514',
          area: '169.88',
          farmSizePercentage: 35
        },
        {
          zone: '2',
          parcelIds: '3755',
          area: '49.76',
          farmSizePercentage: 10
        },
        {
          zone: '3',
          parcelIds: '3761',
          area: '56.11',
          farmSizePercentage: 11
        },
        {
          zone: '4',
          parcelIds: '4160',
          area: '78.07',
          farmSizePercentage: 16
        },
        {
          zone: '5',
          parcelIds: '3755',
          area: '96.26',
          farmSizePercentage: 20
        }
      ],
      vess: [
        {
          zone: '1',
          score: 3.5769230769230766,
          averageScore: 3.9988540349810373,
          percentageOfZonesSurveyed: '100%',
          notes: '#1 - Winter wheat'
        },
        {
          zone: '2',
          score: 3.5555555555555554,
          averageScore: 3.9988540349810373,
          percentageOfZonesSurveyed: '100%',
          notes:
            '#1 - Very hard\n#2 - Very hard\n#3 - Alternative zone 2 field as other is not now farmed by Barton place farms '
        },
        {
          zone: '3',
          score: 4.889855072463768,
          averageScore: 3.9988540349810373,
          percentageOfZonesSurveyed: '100%',
          notes: '#1 - Potatoes... well cultivated'
        },
        {
          zone: '4',
          score: 3.5606060606060606,
          averageScore: 3.9988540349810373,
          percentageOfZonesSurveyed: '100%',
          notes: ''
        },
        {
          zone: '5',
          score: 4.411330409356725,
          averageScore: 3.9988540349810373,
          percentageOfZonesSurveyed: '100%',
          notes: '#1 - Doing this field due to crop spraying in allocated field \n#2 - Sugar beet '
        }
      ],
      soilOrganicMatter: [
        {
          zone: 1,
          value: 2.6,
          score: 2,
          clayPercentage: '31%',
          rainfallAverageMM: '558mm',
          fieldType: 'arable',
          averageScore: 1.6,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 2,
          value: 1.4,
          score: 1,
          clayPercentage: '31%',
          rainfallAverageMM: '558mm',
          fieldType: 'arable',
          averageScore: 1.6,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 3,
          value: 1.6,
          score: 1,
          clayPercentage: '31%',
          rainfallAverageMM: '558mm',
          fieldType: 'arable',
          averageScore: 1.6,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 4,
          value: 2,
          score: 2,
          clayPercentage: '31%',
          rainfallAverageMM: '558mm',
          fieldType: 'arable',
          averageScore: 1.6,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 5,
          value: 2.2,
          score: 2,
          clayPercentage: '31%',
          rainfallAverageMM: '558mm',
          fieldType: 'arable',
          averageScore: 1.6,
          percentageOfZonesSurveyed: '100%'
        }
      ],
      ph: [
        {
          zone: 1,
          value: 7.5,
          score: 3,
          averageScore: 2.8,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 2,
          value: 8.3,
          score: 2,
          averageScore: 2.8,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 3,
          value: 8.1,
          score: 2,
          averageScore: 2.8,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 4,
          value: 7.4,
          score: 5,
          averageScore: 2.8,
          percentageOfZonesSurveyed: '100%'
        },
        {
          zone: 5,
          value: 8.2,
          score: 2,
          averageScore: 2.8,
          percentageOfZonesSurveyed: '100%'
        }
      ],
      earthworms: [
        {
          zone: '1',
          value: '0.00',
          score: 1,
          averageScore: 1.0666666666666667,
          percentageOfZonesSurveyed: '100%',
          fieldsScores: [
            {
              zone: '1',
              field: '6514',
              value: '0.00',
              score: 1,
              averageScore: 0,
              percentageOfZonesSurveyed: '100%'
            }
          ]
        },
        {
          zone: '2',
          value: '0.00',
          score: 1,
          averageScore: 1.0666666666666667,
          percentageOfZonesSurveyed: '100%',
          fieldsScores: [
            {
              zone: '2',
              field: '3755',
              value: '0.00',
              score: 1,
              averageScore: 0,
              percentageOfZonesSurveyed: '100%'
            }
          ]
        },
        {
          zone: '3',
          value: '1.00',
          score: 1.3333333333333333,
          averageScore: 1.0666666666666667,
          percentageOfZonesSurveyed: '100%',
          fieldsScores: [
            {
              zone: '3',
              field: '3761',
              value: '1.00',
              score: 1.3333333333333333,
              averageScore: 0,
              percentageOfZonesSurveyed: '100%'
            }
          ]
        },
        {
          zone: '4',
          value: '0.00',
          score: 1,
          averageScore: 1.0666666666666667,
          percentageOfZonesSurveyed: '100%',
          fieldsScores: [
            {
              zone: '4',
              field: '4160',
              value: '0.00',
              score: 1,
              averageScore: 0,
              percentageOfZonesSurveyed: '100%'
            }
          ]
        },
        {
          zone: '5',
          value: '0.25',
          score: 1,
          averageScore: 1.0666666666666667,
          percentageOfZonesSurveyed: '100%',
          fieldsScores: [
            {
              zone: '5',
              field: '3755',
              value: '0.25',
              score: 1,
              averageScore: 0,
              percentageOfZonesSurveyed: '100%'
            }
          ]
        }
      ],
      bulkDensity: [
        {
          zone: 4,
          value: 1.43,
          somPercentage: 2,
          score: 3,
          fieldType: 'arable',
          averageScore: 3.4,
          percentageOfZonesSurveyed: 100
        },
        {
          zone: 5,
          value: 1.38,
          somPercentage: 2.2,
          score: 3,
          fieldType: 'arable',
          averageScore: 3.4,
          percentageOfZonesSurveyed: 100
        },
        {
          zone: 1,
          value: 1.37,
          somPercentage: 2.6,
          score: 3,
          fieldType: 'arable',
          averageScore: 3.4,
          percentageOfZonesSurveyed: 100
        },
        {
          zone: 2,
          value: 1.33,
          somPercentage: 1.4,
          score: 4,
          fieldType: 'arable',
          averageScore: 3.4,
          percentageOfZonesSurveyed: 100
        },
        {
          zone: 3,
          value: 1.38,
          somPercentage: 1.6,
          score: 4,
          fieldType: 'arable',
          averageScore: 3.4,
          percentageOfZonesSurveyed: 100
        }
      ]
    },
    extra: {
      hidden: false,
      ready: true,
      overallVessScore: 3.9988540349810373,
      totalSoilOrganicCarbonStock: 23267,
      socByZone: [
        {
          zoneId: 4,
          parcelIds: [4160],
          tonsTotal: 3885.0754799999995,
          zoneAreaHectares: 78.07
        },
        {
          zoneId: 5,
          parcelIds: [3755],
          tonsTotal: 5085.069264000002,
          zoneAreaHectares: 96.26000000000003
        },
        {
          zoneId: 1,
          parcelIds: [6514],
          tonsTotal: 10528.958544000003,
          zoneAreaHectares: 169.88000000000005
        },
        {
          zoneId: 2,
          parcelIds: [3755],
          tonsTotal: 1612.1642880000002,
          zoneAreaHectares: 49.76000000000001
        },
        {
          zoneId: 3,
          parcelIds: [3761],
          tonsTotal: 2155.7013120000006,
          zoneAreaHectares: 56.110000000000014
        }
      ],
      totalZoneHectares: 450.07999999999964,
      averageFarmCarbonDensity: 52,
      missingAnswers: [],
      missingData: []
    }
  },
  water: {
    overall: 4.222222222222222,
    level1: {
      general: [
        {
          label: 'Surface runoff avoidance',
          score: 0
        },
        {
          label: 'Nutrient runoff',
          score: 3.6666666666666665
        },
        {
          label: 'Water usage',
          score: 4
        }
      ],
      detail: [
        {
          label: 'Farm Nitrate balance',
          score: 5,
          value: '-22.24'
        }
      ]
    },
    level2: {
      general: [
        {
          label: 'Overall resource availability',
          score: 2
        },
        {
          label: 'Groundwater levels resource availability',
          score: 2
        },
        {
          label: 'Surface water availability',
          score: 1
        },
        {
          label: 'Water Usage Actions',
          score: 3
        },
        {
          label: 'Water Usage Score',
          score: 4
        }
      ],
      floodpreventionscore: [
        {
          label: 'Connectivity Index',
          score: 5
        },
        {
          label: 'VESS',
          score: 3.9988540349810373
        },
        {
          label: 'Flood mitigation measures',
          score: 2,
          individualScores: [
            {
              id: 'ARABLE_FIELDS',
              score: 2
            },
            {
              id: 'CULTIVATION_SYSTEM',
              score: 3
            },
            {
              id: 'IN_FIELD_BUTTER',
              score: 1
            },
            {
              id: 'HEDGES_ON_YOUR_PROPER',
              score: 1
            },
            {
              id: 'ARTIFICIAL_WETLANDS',
              score: 0
            },
            {
              id: 'FLOODWATER_CREATION',
              score: 0
            },
            {
              id: 'RIPARIAN_BUFFER_STRIPS',
              score: 5
            },
            {
              id: 'RIVERS_STREAMS_ON_YOUR_LAND',
              score: 2
            },
            {
              id: 'WOODY_DEBRIS_DAMS',
              score: 0
            }
          ]
        }
      ],
      balance: [
        {
          label: 'Nitrate balance',
          score: 5,
          value: '-22.24'
        }
      ],
      nutrientrunoff: {
        averages: [
          {
            label: 'Nitrate measurement',
            score: 4,
            note: ''
          },
          {
            label: 'Phosphate',
            score: 2,
            note: ''
          },
          {
            label: 'Turbidity',
            score: 5,
            note: ''
          }
        ],
        nutrientRunoffScoresPerSample: [
          {
            id: 'd3db54e2-a0f7-47a3-84f1-30e2970a66ae',
            nitrateScore: 5,
            phosphateScore: 2,
            turbidityScore: 5
          },
          {
            id: '2244a0b1-663a-49db-a290-574a39c64cf6',
            nitrateScore: 3,
            phosphateScore: 2,
            turbidityScore: 5
          }
        ]
      },
      waterUsage: {
        cropIrigationIndividualScores: [
          {
            id: 'IRRIGATE_CROPS',
            score: 0,
            displayScore: 0
          },
          {
            id: 'ARABLE_FIELDS_USING_MAINS',
            score: 0,
            displayScore: 0
          },
          {
            id: 'APPLICATION_SYSTEM',
            score: 0,
            displayScore: 0
          },
          {
            id: 'CLEANLINESS_SANITY',
            score: 50,
            displayScore: 2.5
          }
        ]
      }
    },
    extra: {
      hidden: false,
      ready: true,
      missingAnswers: ['PERCENTAGE_ARABLE', 'PERMANENT_WOODLANDS_AREA', 'CONVERTED_TO_GRAZING_AREA'],
      missingData: []
    }
  },
  social: {
    overall: 1,
    level1: {
      general: [
        {
          label: 'Total miles of public footpath on farm',
          value: '0 miles',
          score: 1
        }
      ]
    },
    extra: {
      hidden: false,
      ready: true,
      missingAnswers: [],
      missingData: []
    }
  },
  'climate-change': {
    overall: 1.6666666666666667,
    level1: {
      general: [
        {
          label: 'Total emissions',
          totalTons: 1661.9599999999998,
          tonsPerHectare: 3,
          score: 3
        },
        {
          label: 'Total sequestered',
          score: 1,
          totalTons: 500,
          tonsPerHectare: '1.02'
        },
        {
          label: 'Balance',
          score: 1,
          totalTons: 1161.9599999999998,
          tonsPerHectare: 1.98
        }
      ]
    },
    level2: {
      general: [
        {
          label: 'Total emissions',
          totalTons: 1661.9599999999998,
          tonsPerHectare: 3
        },
        {
          label: 'Total sequestered carbon stocks',
          totalTons: 23767,
          tonsPerHectare: '48.67'
        },
        {
          label: 'Total sequestered carbon flows',
          totalTons: 500,
          tonsPerHectare: '1.02'
        }
      ],
      carbonTotals: {
        topEmissionsSource: [
          {
            label: 'Inputs',
            tons: 518.68,
            percentage: 31
          },
          {
            label: 'Fuels',
            tons: 509.83,
            percentage: 31
          },
          {
            label: 'Materials',
            tons: 443.25,
            percentage: 27
          },
          {
            label: 'Crops',
            tons: 106.42,
            percentage: 6
          },
          {
            label: 'Inventory',
            tons: 83.78,
            percentage: 5
          }
        ],
        detailEmissionsSource: [
          {
            label: 'Inputs',
            scope1: 331.26,
            scope2: 0,
            scope3: 187.42,
            co2e: 187.42,
            ch4: 0,
            n2o: 331.26,
            totalTonsCO2e: 518.68,
            percentage: 31
          },
          {
            label: 'Fuels',
            scope1: 270.63,
            scope2: 69.93,
            scope3: 123.01,
            co2e: 509.83,
            ch4: 0,
            n2o: 0,
            totalTonsCO2e: 509.83,
            percentage: 31
          },
          {
            label: 'Materials',
            scope1: 0,
            scope2: 0,
            scope3: 443.25,
            co2e: 443.25,
            ch4: 0,
            n2o: 0,
            totalTonsCO2e: 443.25,
            percentage: 27
          },
          {
            label: 'Crops',
            scope1: 106.42,
            scope2: 0,
            scope3: 0,
            co2e: 0,
            ch4: 0,
            n2o: 106.42,
            totalTonsCO2e: 106.42,
            percentage: 6
          },
          {
            label: 'Inventory',
            scope1: 0,
            scope2: 0,
            scope3: 83.78,
            co2e: 83.78,
            ch4: 0,
            n2o: 0,
            totalTonsCO2e: 83.78,
            percentage: 5
          },
          {
            label: 'Total',
            scope1: '708.31',
            scope2: '69.93',
            scope3: '837.46',
            co2e: '1,224.28',
            ch4: '0.00',
            n2o: '437.68'
          }
        ],
        totalEmissionTonsScoped: {
          scope1: 708.31,
          scope2: 69.93,
          scope3: 837.46
        }
      },
      stocksFlows: {
        woodlands: {
          flow: {
            total: 500,
            areaHectare: 500,
            averagePerHectare: 1
          },
          stock: {
            total: 500,
            areaHectare: 500,
            averagePerHectare: 1
          }
        },
        nonWoodlands: {
          flow: {
            total: null,
            areaHectare: null,
            averagePerHectare: null
          },
          stock: {
            total: null,
            areaHectare: null,
            averagePerHectare: null
          }
        },
        soils: {
          flow: {},
          stock: {
            total: 23267,
            areaHectare: 450.07999999999964,
            averagePerHectare: 52
          }
        }
      },
      stocksFlowsOriginalSoil: {
        flow: {
          total: null,
          areaHectare: null,
          averagePerHectare: null
        },
        stock: {
          total: 200,
          areaHectare: 500,
          averagePerHectare: 0.4
        }
      },
      socByZone: [
        {
          zoneId: 4,
          parcelIds: [4160],
          tonsTotal: 3885.0754799999995,
          zoneAreaHectares: 78.07
        },
        {
          zoneId: 5,
          parcelIds: [3755],
          tonsTotal: 5085.069264000002,
          zoneAreaHectares: 96.26000000000003
        },
        {
          zoneId: 1,
          parcelIds: [6514],
          tonsTotal: 10528.958544000003,
          zoneAreaHectares: 169.88000000000005
        },
        {
          zoneId: 2,
          parcelIds: [3755],
          tonsTotal: 1612.1642880000002,
          zoneAreaHectares: 49.76000000000001
        },
        {
          zoneId: 3,
          parcelIds: [3761],
          tonsTotal: 2155.7013120000006,
          zoneAreaHectares: 56.110000000000014
        }
      ]
    },
    extra: {
      hidden: false,
      ready: true,
      missingAnswers: [],
      missingData: []
    }
  },
  biodiversity: {
    overall: 2.386111111111111,
    level1: {
      general: [
        {
          name: 'Hedgerows',
          score: 2.066666666666667
        },
        {
          name: 'Birds',
          score: 2
        },
        {
          name: 'Grassland / arable flora',
          score: 1.25
        },
        {
          name: 'Biodiversity practice scores: Livestock and crop diversity',
          score: 3
        },
        {
          name: 'Wildlife habitats and measures',
          score: 3
        },
        {
          name: 'Woodland connectivity',
          score: null
        },
        {
          name: 'Space for nature',
          score: 3
        }
      ]
    },
    level2: {
      landcover: {
        general: [
          {
            label: 'Space for nature',
            score: 3
          },
          {
            label: 'Woodland connectivity',
            score: null
          },
          {
            label: 'Hedgerows',
            score: 2.066666666666667
          },
          {
            label: 'Birds',
            score: 2
          },
          {
            label: 'Grassland/arable flora diversity',
            score: 1.25
          },
          {
            label: 'Practice scores: Livestock and crop diversity',
            score: 3
          },
          {
            label: 'Practice scores: Wildlife habitats and measures',
            score: 3
          }
        ],
        landcover: [
          {
            label: 'Freshwater',
            areaHectares: 1.85,
            type: 'Space for nature',
            parcelId: '2561b009-bf22-40c6-898d-4c24f1795957',
            landcoverCode: '14'
          },
          {
            label: 'Heather',
            areaHectares: 3.53,
            type: 'Space for nature',
            parcelId: '8002ee34-2a1b-4247-a036-bf560c0d2f67',
            landcoverCode: '9'
          },
          {
            label: 'Broadleaved, mixed & yew woodland',
            areaHectares: 21.89,
            type: 'Space for nature',
            parcelId: 'afe9c419-0ac2-4b34-880f-66f85b1da7e6',
            landcoverCode: '1'
          },
          {
            label: 'Improved grassland',
            areaHectares: 7.809999999999999,
            type: 'Farmed land',
            parcelId: 'cb833b90-5626-444b-8ac1-b52b9e79e8ca',
            landcoverCode: '4'
          },
          {
            label: 'Arable and horticulture',
            areaHectares: 450.17,
            type: 'Farmed land',
            parcelId: '663b5103-8e1e-4733-a887-5c4f22ba4476',
            landcoverCode: '3'
          },
          {
            label: 'Urban',
            areaHectares: 3.1399999999999997,
            type: 'Buildings and infastructure',
            parcelId: '81f7b554-4ff9-42d1-a28e-5afbc5c4db06',
            landcoverCode: '20'
          }
        ],
        summary: [
          {
            label: 'Farmed land',
            areaHectares: 457.98,
            percentage: 93.7734187841684
          },
          {
            label: 'Space for nature',
            areaHectares: 27.27,
            percentage: 5.583652408935482
          },
          {
            label: 'Buildings and infastructure',
            areaHectares: 3.1399999999999997,
            percentage: 0.6429288068961281
          }
        ]
      },
      hedgerows: [
        {
          zone: 4,
          field: '4160',
          hedgeStructure: null,
          averageHeightMeters: '0.00',
          averageWidthMeters: '0.00',
          averageBaseCanopyMeters: null,
          numTress: null,
          speciesOfTrees: [],
          totalPoints: 1,
          score: 1,
          lengthMeters: '446.20',
          sampleId: '2f38ca63-d797-4fed-8ba6-f078ff489289'
        },
        {
          zone: 4,
          field: '4160',
          hedgeStructure: 'H10 Tall or overgrown',
          averageHeightMeters: '10.00',
          averageWidthMeters: '3.00',
          averageBaseCanopyMeters: null,
          numTress: null,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '419.80',
          sampleId: '4bd412ff-7880-465e-9150-e73013930f20'
        },
        {
          zone: 4,
          field: '4160',
          hedgeStructure: 'H10 Tall or overgrown',
          averageHeightMeters: '9.00',
          averageWidthMeters: '3.00',
          averageBaseCanopyMeters: null,
          numTress: null,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '320.90',
          sampleId: 'a6f9328b-9de9-4566-a437-4aeae91a6393'
        },
        {
          zone: 5,
          field: '3755',
          hedgeStructure: null,
          averageHeightMeters: '0.00',
          averageWidthMeters: '0.00',
          averageBaseCanopyMeters: '0.00',
          numTress: 0,
          speciesOfTrees: [],
          totalPoints: 1,
          score: 1,
          lengthMeters: '591.60',
          sampleId: 'a2d2ea8c-5afb-42ae-a427-e10bb2ea3f01'
        },
        {
          zone: 5,
          field: '3755',
          hedgeStructure: 'H7 Dense and Managed',
          averageHeightMeters: '5.00',
          averageWidthMeters: '2.00',
          averageBaseCanopyMeters: '1.50',
          numTress: 4,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '237.20',
          sampleId: 'b4f5dc12-42e5-498b-91ea-85f158676c55'
        },
        {
          zone: 5,
          field: '3755',
          hedgeStructure: null,
          averageHeightMeters: '0.00',
          averageWidthMeters: '0.00',
          averageBaseCanopyMeters: '0.00',
          numTress: 0,
          speciesOfTrees: [],
          totalPoints: 1,
          score: 1,
          lengthMeters: '317.20',
          sampleId: '9571ae9b-90b9-4c7e-b8f0-829f08565dff'
        },
        {
          zone: 5,
          field: '3755',
          hedgeStructure: null,
          averageHeightMeters: '0.00',
          averageWidthMeters: '0.00',
          averageBaseCanopyMeters: '0.00',
          numTress: 0,
          speciesOfTrees: [],
          totalPoints: 1,
          score: 1,
          lengthMeters: '672.60',
          sampleId: 'c71f497d-b4a0-42ec-b30e-8fab2c883ea4'
        },
        {
          zone: 5,
          field: '3755',
          hedgeStructure: 'H10 Tall or overgrown',
          averageHeightMeters: '9.00',
          averageWidthMeters: '5.00',
          averageBaseCanopyMeters: null,
          numTress: null,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '571.00',
          sampleId: '5cea408f-e784-4150-bc90-b443b2215c92'
        },
        {
          zone: 5,
          field: '3755',
          hedgeStructure: null,
          averageHeightMeters: '9.00',
          averageWidthMeters: '4.00',
          averageBaseCanopyMeters: '0.50',
          numTress: null,
          speciesOfTrees: [],
          totalPoints: 2,
          score: 3,
          lengthMeters: '329.10',
          sampleId: '4b2a488a-520b-4078-99df-6093380b2210'
        },
        {
          zone: 2,
          field: '3755',
          hedgeStructure: 'H5 Dense and Managed',
          averageHeightMeters: '3.00',
          averageWidthMeters: '2.50',
          averageBaseCanopyMeters: '1.00',
          numTress: 4,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '230.10',
          sampleId: 'b5fb65b3-38f4-4964-9f5f-084f7e9927f5'
        },
        {
          zone: 1,
          field: '6514',
          hedgeStructure: 'H6 Dense and Managed',
          averageHeightMeters: '3.00',
          averageWidthMeters: '2.00',
          averageBaseCanopyMeters: '1.50',
          numTress: 12,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '394.90',
          sampleId: 'd0c2d68d-020c-4e73-8938-b5d954e4fff3'
        },
        {
          zone: 1,
          field: '6514',
          hedgeStructure: 'H10 Tall or overgrown',
          averageHeightMeters: '9.00',
          averageWidthMeters: '6.00',
          averageBaseCanopyMeters: '0.50',
          numTress: null,
          speciesOfTrees: [],
          totalPoints: 1,
          score: 1,
          lengthMeters: '203.50',
          sampleId: '652d02f8-e675-47e6-a2a0-08aa4f2f9ee0'
        },
        {
          zone: 1,
          field: '6514',
          hedgeStructure: 'H6 Dense and Managed',
          averageHeightMeters: '2.50',
          averageWidthMeters: '1.50',
          averageBaseCanopyMeters: '1.00',
          numTress: 4,
          speciesOfTrees: [],
          totalPoints: 3,
          score: 3,
          lengthMeters: '562.70',
          sampleId: 'e81dbfbe-66ee-45eb-bdd4-76383b694076'
        },
        {
          zone: 3,
          field: '3761',
          hedgeStructure: null,
          averageHeightMeters: '0.00',
          averageWidthMeters: '0.00',
          averageBaseCanopyMeters: '0.00',
          numTress: 0,
          speciesOfTrees: [],
          totalPoints: 1,
          score: 1,
          lengthMeters: '572.30',
          sampleId: '099ddab5-1d9f-4835-a4cd-64539e2b3e16'
        },
        {
          zone: 3,
          field: '3761',
          hedgeStructure: null,
          averageHeightMeters: '0.00',
          averageWidthMeters: '0.00',
          averageBaseCanopyMeters: '0.50',
          numTress: 7,
          speciesOfTrees: [],
          totalPoints: 0,
          score: 1,
          lengthMeters: '275.60',
          sampleId: 'ae485500-5dc7-4ed3-a09d-01f02f71b60e'
        }
      ],
      grassland: {
        summary: [
          {
            label: 'Total grassland/arable plants identified',
            value: 8
          },
          {
            label: 'Grassland/arable plants benchmark score',
            value: 1.25
          }
        ],
        items: [
          {
            zone: 2,
            field: '3755',
            species: ['Spring barley', 'Barley'],
            score: 1
          },
          {
            zone: 3,
            field: '3761',
            species: ['Potato (volunteer)', 'Potatoes'],
            score: 1
          },
          {
            zone: 4,
            field: '4160',
            species: ['Wheat'],
            score: 1
          },
          {
            zone: 5,
            field: '3755',
            species: ['Sugar Beet (volunteer)', 'Fat Hen', 'Common Fumitory', 'Sugar Beet (volunteer)'],
            score: 2
          }
        ]
      },
      birds: {
        summary: [
          {
            label: 'Total number of bird species',
            value: 8,
            score: 2
          },
          {
            label: 'Total number of bird species on IUCN list',
            value: 2
          }
        ],
        items: [
          {
            label: 'Great Tit',
            conservationList: null
          },
          {
            label: 'Skylark',
            conservationList: 'Red'
          },
          {
            label: 'Robin',
            conservationList: null
          },
          {
            label: 'Carrion Crow',
            conservationList: null
          },
          {
            label: 'Red-legged Partridge',
            conservationList: null
          },
          {
            label: 'Woodpigeon',
            conservationList: 'Amber'
          },
          {
            label: 'Pied Wagtail',
            conservationList: null
          },
          {
            label: 'Chiffchaff',
            conservationList: null
          }
        ]
      },
      practices: {
        livestockAndCropDiversity: {
          score: 3,
          pointsScored: 6,
          pointsAvailable: 22
        },
        wildlifeHabsAndMeasures: {
          score: 3,
          totalFarmedLandWildlifeFriendly: 37.42,
          wildlifeFriendlyMeasuresIndividualScores: [
            {
              id: 'CEREAL_FIELDS_ALL',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'Cereals',
              score: 0,
              maxScore: 5
            },
            {
              id: 'CEREAL_FIELDS_APPLY',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'Grasses',
              score: 3,
              maxScore: 5
            },
            {
              id: 'CHECK_WATERCOURSES_PONDS',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'Watercourses and ponds',
              score: 3,
              maxScore: 3
            },
            {
              id: 'HEDGEROWS_CARRY_OUT',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'hedgerows',
              score: 1,
              maxScore: 3
            },
            {
              id: 'NESTING_RESOURCES_FOR_WILDLIFE',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'Nesting resources for wildlife',
              score: 5,
              maxScore: 5
            },
            {
              id: 'PERENNIAL_GRASSY',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'Perennial grassy or flower rich areas',
              score: 1,
              maxScore: 4
            },
            {
              id: 'POOLS_AND_PONDS',
              category: 'WILDLIFE_FRIENDLY_MEASURES',
              label: 'Pools and ponds',
              score: 0,
              maxScore: 6
            }
          ]
        }
      }
    },
    extra: {
      hidden: false,
      ready: true,
      missingAnswers: ['MORE_THAN_ONE_BREED'],
      missingData: []
    }
  },
  'animal-welfare': {
    extra: {
      hidden: true,
      ready: false,
      missingData: []
    }
  },
  summary: {
    totalScore: 11.848104140329541,
    maxAvailableScore: 25
  }
}
