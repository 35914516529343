import { ExchangeApi } from '~/services/api/exchange'

export class RecommendationService extends ExchangeApi {
  async getAllRecommendations() {
    return await this.get(`/recommendation`)
  }

  async getQuickWinRecommendations() {
    return await this.get('/recommendation/quick-wins')
  }

  async getRecommendationById(id) {
    return await this.get(`/recommendation/${id}`)
  }

  async createRecommendation(model) {
    return await this.post(`/recommendation`, model)
  }

  async createQuickWin(model) {
    return await this.post(`/recommendation/quick-wins`, model)
  }

  async updateRecommendation(id, model) {
    return await this.put(`/recommendation/${id}`, model)
  }

  async deleteRecommendation(id) {
    return await this.delete(`/recommendation/${id}`)
  }

  async getAllActions() {
    return await this.get(`/recommendation/action`)
  }

  async createAction(model) {
    return await this.post(`/recommendation/action`, model)
  }

  async updateAction(id, model) {
    return await this.put(`/recommendation/action/${id}`, model)
  }

  async updateActionStatus(id, status) {
    return await this.put(`/recommendation/action/${id}/status`, { status })
  }

  async deleteAction(id) {
    return await this.delete(`/recommendation/action/${id}`)
  }
}
