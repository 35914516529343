import { render, staticRenderFns } from "./BannerOffline.vue?vue&type=template&id=f8d1d7f6&scoped=true"
import script from "./BannerOffline.vue?vue&type=script&lang=js"
export * from "./BannerOffline.vue?vue&type=script&lang=js"
import style0 from "./BannerOffline.vue?vue&type=style&index=0&id=f8d1d7f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8d1d7f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/button/Button.vue').default,Notification: require('/home/runner/work/exchange/exchange/packages/client/src/components/elements/base/notification/Notification.vue').default})
