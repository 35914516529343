import { merge } from 'lodash'

import generateRandomId from '~/lib/generateId'
import { ApiModel } from '~/plugins/api/model'

export default function (existingModel = {}) {
  return {
    model: merge(
      {
        internalId: generateRandomId(), // used to modify before file upload
        name: null, // original file name
        mimeType: null,
        sizeBytes: 0,
        sizeHuman: 0,
        originalSizeHuman: 0, // size before image compression, in bytes
        base64: null, // used when making images smaller before upload or native upload
        rawFile: null,
        isNative: false,
        errorMessage: null,
        isProcessingLocally: false,
        canDelete: true,
        signedURLApi: new ApiModel(),
        uploadApi: new ApiModel(),
        deleteApi: new ApiModel(),
        publicUrl: null, // Public access for file after uploaded
        fileId: null // ID returned from API
      },
      existingModel
    )
  }
}
