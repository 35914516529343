export const sampleTypeConfig = {
  PLANT_ARABLE: {
    type: 'plant-arable',
    labelSingular: 'Arable plant',
    labelPlural: 'Arable plants',
    isPlantFamily: true,
    isArable: true,
    isBiodiversity: true
  },
  PLANT_FIELD_MARGIN: {
    type: 'plant-field-margin',
    labelSingular: 'Field margin plant',
    labelPlural: 'Field margin plants',
    isPlantFamily: true,
    isArable: true,
    isBiodiversity: true
  },
  PLANT_GRASSLAND: {
    type: 'plant-grassland',
    labelSingular: 'Grassland plant',
    labelPlural: 'Grassland plants',
    isPlantFamily: true,
    isGrassland: true,
    isBiodiversity: true
  },
  PLANT_HEDGEROW: {
    type: 'plant-hedgerow',
    labelSingular: 'Hedgerow plant',
    labelPlural: 'Hedgerow plants',
    isPlantFamily: true,
    isBiodiversity: true
  },
  BIRD: {
    type: 'bird',
    labelSingular: 'Bird',
    labelPlural: 'Birds',
    isBiodiversity: true
  },
  HEDGEROW: {
    type: 'hedgerow',
    labelSingular: 'Hedgerow',
    labelPlural: 'Hedgerows',
    isBiodiversity: true
  },
  SOIL: {
    type: 'soil',
    labelSingular: 'Soil',
    labelPlural: 'Soils',
    isSoilFamily: true
  },
  PH_PROBE: {
    type: 'ph',
    labelSingular: 'pH',
    labelPlural: 'pH',
    isSoilFamily: true
  },
  EARTHWORM: {
    type: 'earthworm',
    labelSingular: 'Earthworm',
    labelPlural: 'Earthworms',
    isSoilFamily: true
  }
}

export const sampleType = Object.keys(sampleTypeConfig).reduce((acc, key) => {
  acc[key] = sampleTypeConfig[key].type
  return acc
}, {})

export const sampleTypeEnums = Object.values(sampleType)

export const soilSampleTypes = Object.keys(sampleTypeConfig)
  .filter(key => sampleTypeConfig[key].isSoilFamily)
  .map(key => sampleTypeConfig[key].type)

export const plantSampleTypes = Object.keys(sampleTypeConfig)
  .filter(key => sampleTypeConfig[key].isPlantFamily)
  .map(key => sampleTypeConfig[key].type)

export const biodiversitySampleTypes = Object.keys(sampleTypeConfig)
  .filter(key => sampleTypeConfig[key].isBiodiversity)
  .map(key => sampleTypeConfig[key].type)

export const arableSampleTypes = Object.keys(sampleTypeConfig)
  .filter(key => sampleTypeConfig[key].isArable)
  .map(key => sampleTypeConfig[key].type)

export const grasslandSampleTypes = Object.keys(sampleTypeConfig)
  .filter(key => sampleTypeConfig[key].isGrassland)
  .map(key => sampleTypeConfig[key].type)

export function sampleTypeIsPlantFamily(sampleType) {
  return Object.values(sampleTypeConfig).find(type => type.type === sampleType)?.isPlantFamily || false
}

export function getLabelForSampleType(sampleType) {
  return Object.values(sampleTypeConfig).find(({ type }) => type === sampleType)?.labelSingular
}
